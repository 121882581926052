.header {
  background-color: var(--primary-2);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  border-bottom: 1px solid var(--primary-3);
  margin: -24px -24px 24px -24px;
  padding: var(--size-lg) var(--size-lg) var(--size-md) var(--size-lg);

  & h2 {
    color: var(--black) !important;
    font-size: 2rem;
    line-height: 1.18;
  }
}

.header.paused {
  background-color: var(--secondary-1);
  border-color: var(--secondary-3);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
}

.bottomBar {
  gap: 0.75rem;
  align-items: center;

  & div:not(.select) {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  & > div,
  > .label {
    padding-right: 4px;
    height: 32px;
    border: var(--border-base);
    background-color: var(--white);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    line-height: 1;
    position: relative;
    margin-top: 12px;

    & > span:first-of-type {
      border-right: var(--border-base);
      background-color: var(--secondary-1);
      padding: 0 10px;
      height: 100%;
      color: var(--secondary-6);
      font-weight: 600;
      border-radius: 8px 0 0 8px;
    }
  }
}

.paused .bottomBar {
  & > div,
  > .label {
    & > span:first-of-type {
      color: var(--secondary-4);
    }

    & span {
      color: var(--secondary-5);
    }

    & svg path {
      fill: var(--secondary-4);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 3px !important;
}

.select {
  width: 400px !important;
}

.formItem {
  margin-bottom: 10px;
}

.label {
  color: var(--black);
}

.label,
.bidText {
  display: flex;
  align-items: center;
}

.bidText {
  margin-left: 0.75rem;
}

.bigIcon {
  width: 28px;
  height: 28px;

  & svg {
    width: 12px;
  }
}

.smallIcon {
  width: 22px;
  height: 22px;

  & svg {
    width: 10px;
  }
}

.iconContainer {
  background-color: var(--white);
  border: var(--border-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--size-radius);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  margin-left: 1rem;
  cursor: pointer;

  & svg path {
    fill: var(--primary-6);
  }
}

.iconContainer.uneditable {
  background-color: var(--secondary-1);
  pointer-events: none;

  & svg path {
    fill: var(--secondary-4);
  }
}

.row {
  padding: var(--size-s);
  gap: 10px;
  background-color: var(--secondary-2);
  border-radius: 10px;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

.row:not(:last-child) {
  margin-bottom: 1rem;
}

.budgetContainer,
.spendingContainer {
  width: 100%;
  max-width: 400px;
}

.budgetHelp {
  color: var(--secondary-4);
  line-height: 1.33;
  margin: 0.625rem 0 0.5rem;
  display: block;
}

.totalBudgetWrapper {
  border: var(--border-base);
  border-radius: 0.625rem;
}

.totalBudgetContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.875rem;
  border-radius: 0.625rem;
  border: var(--border-base);
  background-color: var(--secondary-1);
  transition: border-radius 0.3s ease-in-out;
}

.totalBudgetContainer.open {
  border-radius: 0.625rem 0.625rem 0 0;
  border: none;
}

.totalBudgetTitle,
.totalBudgetButton {
  line-height: 1;
}

.totalBudgetTitle {
  color: var(--secondary-6);
}

.totalBudgetButton {
  background-color: transparent;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-4);
  transition: color 0.25s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.totalBudgetButton.open {
  color: var(--red-5);
}

.totalBudgetInput,
.totalBudgetCalculationContainer {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-5vh);
  transition: translate 0.3s ease-in-out;
}

.totalBudgetSection {
  max-height: 0;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  border-radius: 0 0 0.625rem 0.625rem;
  padding: 0 0.875rem;
  transition: all 0.3s ease;
}

.totalBudgetSection.open {
  max-height: max-content;
  border-color: var(--secondary-2);
  padding: 0.875rem;

  & .totalBudgetInput,
  .totalBudgetCalculationContainer {
    pointer-events: all !important;
    transform: translateY(0);
    opacity: 1;
  }
}

.totalBudgetInput div > span,
.totalBudgetInput input {
  width: 100% !important;
}

.totalBudgetCalculationContainer {
  background-color: var(--secondary-1);
  padding: 0.875rem;
  gap: 0.875rem;
  border-radius: 0.75rem;
}

.progressText {
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.durationInfo {
  color: var(--secondary-7);
}

.day {
  font-size: 0.625rem;
  color: var(--secondary-5);
  text-transform: uppercase;
}

.red strong {
  color: var(--red-5);
}

.yellow strong {
  color: var(--yellow-6);
}

.blue strong {
  color: var(--blue-10);
}

.green strong {
  color: var(--green-9);
}

.durationText {
  color: var(--secondary-5);
}

.flexibleBudgetText {
  color: var(--secondary-5);
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.hidePrefix,
.showPrefix {
  & span:not(span[class="ant-input-prefix"]) {
    width: 25rem;
  }
}

.spendingContainer {
  display: flex;
  gap: 10px;
}

.spendingSelect {
  width: 100%;
  margin-top: 2px;
}

.spendingInput {
  width: 130px;
  margin-bottom: 0;

  & div > span {
    width: 130px !important;
  }
}

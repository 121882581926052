.inputContainer {
  position: relative;
  border-radius: var(--size-radius);
  padding: 0 8px;
  border: 1px solid var(--secondary-2);
  background-color: var(--primary-2);
  width: 9rem;
  transition: 0.3s ease;
  gap: 0;
  justify-content: flex-end;
  z-index: 1;
  
  &:focus-within,
  &:hover {
    box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
      0px 1px 2px rgba(52, 42, 114, 0.08);
  }

  & .prefix {
    margin-left: 6px;
  }
}

.inputContainer.error {
  border: 1px solid var(--red-5);
  background-color: white;
}

.iconContainer {
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
}

.okContainer {
  background-color: var(--primary-4);
  position: absolute;
  top: 8px;
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.input {
  width: 4rem;
  height: 1.75rem;
  border: none;
  background-color: var(--primary-2);
  border-radius: var(--size-radius);
  padding: 4px 6px;
  &:focus,
  &:hover {
    box-shadow: none;
  }
}

.input.error {
  background-color: var(--white);
}

.okContainer.error {
  pointer-events: none;
  background-color: var(--secondary-2);
  & svg path {
    fill: var(--secondary-4);
  }
}

.formItem {
  margin-bottom: 0;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: inherit;
  flex-direction: column;
  gap: 0.625rem !important;
}

.selectContainer {
  justify-content: space-between;
  flex-wrap: inherit;
  gap: 0.625rem;
  width: 100%;
}

.search {
  width: 50%;
  max-width: 400px;
}

.categories {
  width: 50%;
  max-width: 400px;
}

.select > div {
  padding: 0.3rem 1rem !important;
}

.select svg {
  width: 1rem;

  & path {
    fill: var(--primary-6);
  }
}

.select span {
  font-size: 0.75rem !important;
  background-color: transparent;
}

.productTabContainer {
  position: relative;
  width: 100%;

  & > div:first-of-type {
    padding: 0 !important;

    & > div:first-of-type > div:first-of-type > div {
      padding: 0.25rem 0.5rem;
      margin: 0.35rem !important;
    }
  }
}

.infoText {
  position: absolute;
  right: 10px;
  top: 14px;
  line-height: 1;
  color: var(--secondary-5);
}

.productTable {
  background-color: var(--white) !important;
  padding: 0.5rem;
  border-radius: 0.625rem;
  margin-top: 0.8rem;
}

.tableRow,
.tableRow td {
  background-color: transparent !important;
}

.tableRow {
  position: relative;
  z-index: 6;

  & td {
    position: relative;
    z-index: 6;
  }

  &::after {
    content: "";
    display: block;
    height: 85%;
    width: 100%;
    background-color: var(--white);
    pointer-events: none;
    border: var(--border-base);
    border-radius: 0.625rem;
    position: absolute;
    left: 0;
    top: 7.5%;
    box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03),
      0px 2px 15px 0px rgba(30, 19, 92, 0.03);
    z-index: 2;
  }

  & td:first-of-type {
    padding-left: 1rem !important;
  }
}

.tableRow[class*="ant-table-row-selected"]::after {
  background-color: var(--primary-3) !important;
  border: none !important;
}

.imgContainer {
  max-width: 300px;

  & img {
    width: 48px;
    height: 48px;
    border-radius: 0.5rem;
    object-fit: contain;
  }

  & span {
    line-height: 1.25;
  }
}

.productTable.error,
.productTable.error div {
  background-color: transparent !important;
}

.winner,
.loser {
  gap: 0.3rem;

  & svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.winner span {
  color: var(--green-10);
}

.loser span {
  color: var(--red-5);
}

.openBtn {
  min-width: 8rem;
  height: 2.6rem;
  justify-content: center;
  border: var(--border-base);
  border-radius: var(--size-radius);
  cursor: pointer;
  background-color: var(--white);
  & svg path {
    fill: var(--secondary-7);
  }
}

.title {
  line-height: 1 !important;
}

.text {
  color: var(--secondary-5);
  line-height: 1.4;
  display: inline-block;
  margin-bottom: 1rem;
}

.textarea {
  border: var(--border-base);
  border-radius: var(--size-radius);
  padding: 0.75rem 1rem;
  height: 85% !important;
  resize: none;

  &:focus,
  &:hover {
    box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
      0px 1px 2px rgba(52, 42, 114, 0.08);
    border: 1px solid var(--primary-4);
  }
}

.warn::after {
  background-color: var(--yellow-1);
}

.block::after {
  background-color: var(--red-2);
}

.select .clearSearchIcon {
  width: 1.625rem;
  height: 1rem;
  position: absolute;
  right: 2.5rem;
  top: -2px;
  border-right: var(--border-base);
  padding-right: 1rem;

  & path {
    fill: var(--secondary-4);
  }
}

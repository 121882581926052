.header {
  background-color: var(--primary-7);
  margin-left: -24px;
  margin-top: -24px;
  width: calc(100% + 48px);
  padding: 50px;
  position: relative;
}
.title {
  margin-bottom: 0 !important;
  color: var(--white) !important;
}
.headerText {
  color: var(--secondary-1);
  display: inline-block;
  max-width: 650px;
  line-height: 1.7;
  margin-top: 1rem;
}

/* .academy {
  color: var(--white);
  padding: 8px 10px;
  background-color: var(--blue-10);
  border-radius: 15px 0px;
  line-height: 1;
  position: relative;
  &::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background-size: cover;
    background-image: url("../../../assets/icons/teknosaAcademyIcon.svg");
    display: block;
    position: absolute;
    top: -1rem;
    right: -0.75rem;
  }
} */

.padding {
  padding-bottom: 70px;
}

.postContainer {
  position: absolute;
  background-color: white;
  background: linear-gradient(
    181.53deg,
    var(--primary-2) -12.87%,
    var(--white) 62.74%
  );
  border: var(--border-base);
  border-top: none;
  padding: 20px;
  right: 50px;
  border-radius: 0px 0px 10px 10px;
  max-width: 450px;
  min-height: 225px;
  top: 0;

  & h3,
  h4 {
    font-weight: 600;
    margin-bottom: 0;
  }

  & h3 {
    font-size: 12px;
    line-height: 2;
    color: var(--blue-10);
    text-transform: uppercase;
  }

  & h4 {
    font-size: 18px;
    line-height: 24px;
  }

  & .description {
    display: block;
    color: var(--secondary-4);
    line-height: 1.375rem;
    margin: 0.75rem 0;
  }

  & a {
    color: var(--primary-4);
    font-size: var(--size-xxxs);
    font-weight: 600;
    text-align: center;
    display: block;
    padding: 2px;
    border: 1.5px solid var(--primary-4);
    border-radius: var(--size-radius);
    width: 10.5rem;
  }
}

.container {
  width: 50%;
  border-radius: 16px;
  border: 1px solid var(--secondary-1);
  background: var(--white);
  box-shadow: 0px 82px 23px 0px rgba(0, 0, 0, 0),
    0px 53px 21px 0px rgba(0, 0, 0, 0), 0px 30px 18px 0px rgba(0, 0, 0, 0.01),
    0px 13px 13px 0px rgba(0, 0, 0, 0.02), 0px 3px 7px 0px rgba(0, 0, 0, 0.02);
}

.side {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 90%;
  max-width: 400px;
  margin: auto;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 12px !important;
  line-height: 1.33 !important;
}

.mainText {
  color: var(--secondary-4);
  line-height: 1.42;
  margin-bottom: 40px;
}

.form {
  margin-bottom: 34px;

  & div[role="alert"] {
    color: var(--secondary-4);
    font-size: 12px;
    margin-top: 2px;
  }
}

.input {
  width: 100% !important;
}

.innerWrapper {
  padding: 12px;
  background-color: var(--secondary-1);
  border: 1px solid var(--secondary-1);
  border-radius: 18px;
  margin-bottom: 14px;
}

.sampleImgContainer {
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 21px 6px 0px rgba(0, 0, 0, 0),
    0px 13px 5px 0px rgba(0, 0, 0, 0.01), 0px 8px 5px 0px rgba(0, 0, 0, 0.03),
    0px 3px 3px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--primary-1);
    border-color: var(--primary-3);
    box-shadow: 0px 21px 6px 0px rgba(0, 0, 0, 0),
      0px 13px 5px 0px rgba(0, 0, 0, 0.01), 0px 8px 5px 0px rgba(0, 0, 0, 0.03),
      0px 3px 3px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    & .sampleImg {
      border: 1px solid var(--secondary-1);
      background-color: var(--white);
    }
  }
}

.sampleTitle,
.sampleText {
  display: inline-block;
  line-height: 1;
}

.sampleTitle {
  color: var(--primary-6);
  margin-bottom: 6px;
}

.sampleText {
  color: var(--secondary-4);
}

.sampleImg {
  padding: 0 10px;
  height: 48px;
  background-color: var(--secondary-1);
  border: 1px solid var(--secondary-1);
  border-radius: 9px;
  margin-top: 10px;
  transition: background-color 0.3s ease;

  & img {
    width: 100%;
  }
}

.divider {
  width: calc(100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  color: var(--secondary-4);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin: 12px 0;

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--secondary-2);
    vertical-align: middle;
  }
}

.selected {
  border: 1.5px solid var(--primary-4) !important;
}

.returnBtn {
  width: 100%;
  font-size: 12px;
  min-height: 28px;
  margin-top: 12px;
}

.contentContainer {
  padding: 60px 0;
  margin-left: 40px;
  border-bottom: 1px solid var(--secondary-2);
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.active.contentContainer {
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.title,
.text {
  margin: 0;
}

.title {
  color: var(--secondary-6);
  font-size: 24px;
  line-height: 1;
}

.active .title {
  color: var(--primary-6);
}

.text {
  max-width: 862px;
  color: var(--secondary-4);
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
}

.filledText {
  max-width: 484px;
}

.btn {
  min-width: 200px;
  margin-top: 10px;
}

.textContainer div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.textContainer img {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.logoContainer {
  margin-top: 24px;
}

.drawerText {
  display: inline-block;
  color: var(--secondary-4);
  line-height: 1.5;
  margin: 8px 0 14px;
}

.radioLabel {
  width: 100%;
  margin-bottom: 10px;

  & .disabledRadioTitle,
  .radioText {
    color: var(--secondary-4) !important;
  }
}

.radioText,
.disabledRadioText {
  display: inline-block;
  margin-top: 6px;
}

.disabledRadioTitle {
  font-size: 14px !important;
  line-height: 1.42;
}

.disabledRadioText {
  color: var(--secondary-3);
}

.soon {
  max-width: max-content;
  display: inline-flex;
  padding: 4px 6px;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-1);
  border-radius: 6px;
  color: var(--secondary-6);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-left: 10px;
}

.linkContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.linkContainer a {
  color: var(--primary-4);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-decoration: underline;
}

.linkContainer svg path {
  fill: var(--primary-4);
}

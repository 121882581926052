.advertiserView {
  margin-top: 66px;
}

.page {
  background-color: var(--secondary-1);
  padding-top: 100px;
  padding-bottom: 36px;

  & h2 {
    font-size: 18px;
    color: var(--secondary-6);
    font-weight: 600;
    margin-bottom: 1rem;
  }

  & article,
  > section,
  footer {
    width: 90%;
    max-width: 920px;
    margin: 0 auto;
  }
  & article {
    margin-top: 2.875rem;
    padding: 2.25rem;
    background-color: var(--white);
    border: var(--border-base);
    border-radius: 14px;
  }
  & .description,
  p,
  li {
    color: var(--secondary-6);
    font-size: 14px;
    line-height: 34px;
  }

  & blockquote {
    font-weight: 600;
    line-height: 2;
    padding: 20px 30px;
    background-color: var(--secondary-1);
    position: relative;
    border-left: 2px solid var(--secondary-6);
  }

  & footer {
    justify-content: space-between;
    background-color: var(--primary-4);
    box-shadow: 0px 41px 31px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
    padding: 30px;
    margin-top: 36px;
    & h4,
    span {
      color: var(--white) !important;
    }

    & h4 {
      font-size: 18px !important;
    }

    & span {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      opacity: 0.8;
    }

    & a {
      background-color: var(--white);
      color: var(--primary-4);
      border-radius: var(--size-radius);
      padding: 14px 10px;
      width: 10rem;
      justify-content: center;
      font-weight: 600;
    }
  }
}

.info {
  margin: 30px 0;
  line-break: auto;
  white-space: break-spaces;
  font-size: 14px;
  & svg {
    width: 1.01rem;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 21px;
  line-height: 100%;
  font-weight: 600;
  color: black;
  margin-bottom: 0;
}

.btn {
  border: var(--border-base);
  border-radius: var(--size-radius);
  background-color: white;
  padding: 6px 10px;
}

.image {
  width: 100%;
  max-height: 50vh;
  object-fit: contain;
}

.imageContainer {
  width: 100%;
  max-width: 850px;
  padding: 1rem;
  margin: 30px auto;
  background-color: var(--secondary-1);
  border-radius: 14px;
}

.youtube {
  border-radius: 30px;
  margin: 30px auto;
}

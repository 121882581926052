.container {
  border: var(--border-base);
  border-radius: 8px;
  padding: var(--size-md) var(--size-xxs);
  margin-top: 1.5em;
  gap: 0.75em !important;
}
.space {
  width: 100%;
  margin-bottom: 1.5em;
  & div:nth-of-type(2) {
    width: 80%;
  }
}

.text {
  gap: 1em;
  padding: 0.5em 1em;
  margin-bottom: 0.1em;
  border-radius: 8px;
  cursor: pointer;
}
.text:not(.unarchive):last-of-type {
  color: var(--pink-3);
  font-weight: 500;
}
.text:hover {
  background: var(--gray-2);
}

.archivedSpace div:first-of-type,
.archivedSpace div div {
  width: 100%;
}

.archivedSpace div:nth-of-type(2) {
  width: auto;
}

.link {
  color: var(--primary-4);
  font-family: "Poppins", sans-serif !important;
  font-size: var(--size-xs);
  font-weight: 500;
  display: flex;
  align-items: center;
  &:hover {
    color: var(--primary-4) !important;
  }
}

.linkUp {
  margin-left: 6px;
}

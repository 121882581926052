.container {
  width: 50%;
  border-radius: 16px;
  border: 1px solid var(--secondary-1);
  background-color: var(--white);
  box-shadow: 0px 82px 23px 0px rgba(0, 0, 0, 0),
    0px 53px 21px 0px rgba(0, 0, 0, 0), 0px 30px 18px 0px rgba(0, 0, 0, 0.01),
    0px 13px 13px 0px rgba(0, 0, 0, 0.02), 0px 3px 7px 0px rgba(0, 0, 0, 0.02);
  max-height: calc(100vh - 28px);
  overflow-y: auto;
  scrollbar-color: var(--secondary-3) transparent;
  scrollbar-width: thin;
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  width: 90%;
  max-width: 465px;
  margin: auto;
}

.title {
  line-height: 0.83 !important;
  margin-top: 0 !important;
  margin-bottom: 18px !important;
}

.mainText {
  color: var(--secondary-4);
  line-height: 1.42;
}

.codeWrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 12px;
  gap: 10px;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--secondary-7);
  background: #011627;
  box-shadow: 0px 6px 2px 0px rgba(0, 0, 0, 0), 0px 4px 2px 0px rgba(0, 0, 0, 0),
    0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03),
    0px 0px 1px 0px rgba(0, 0, 0, 0.03);
  margin-top: 20px;
}

.codeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;

  & span {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    word-break: break-word;
  }
}

.copy {
  width: 14px;
  cursor: pointer;
}

.copyText {
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--secondary-2);
  background: var(--white);
  color: var(--secondary-7);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;

  & svg path {
    fill: var(--secondary-7);
  }
}

.activityWrapper {
  position: relative;
  top: -10px;
  padding: 20px 10px 10px 10px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 10px 10px;

  & span {
    color: var(--white);
    line-height: 1;
  }

  & .autoText {
    margin-left: auto;
    margin-right: 8px;
  }
}

.activityWrapperLive {
  background-color: var(--green-9);
  border-bottom: 2px solid var(--green-9);
  box-shadow: 0px 24px 7px 0px rgba(31, 205, 121, 0),
    0px 15px 6px 0px rgba(31, 205, 121, 0.01),
    0px 9px 5px 0px rgba(31, 205, 121, 0.03),
    0px 4px 4px 0px rgba(31, 205, 121, 0.04),
    0px 1px 2px 0px rgba(31, 205, 121, 0.05);
}

.activityWrapperPending {
  background-color: var(--yellow-6);
  border-bottom: 2px solid var(--yellow-6);
  box-shadow: 0px 24px 7px 0px rgba(251, 189, 0, 0),
    0px 15px 6px 0px rgba(251, 189, 0, 0.01),
    0px 9px 5px 0px rgba(251, 189, 0, 0.03),
    0px 4px 4px 0px rgba(251, 189, 0, 0.04),
    0px 1px 2px 0px rgba(251, 189, 0, 0.05);
}

.indent {
  padding-left: 6px;
}

.indentLarge {
  padding-left: 12px;
}

.indentXLarge {
  padding-left: 18px;
}

.purple {
  color: #ff008e !important;
}

.yellow {
  color: #af0 !important;
  padding-left: 4px;
}

.blue {
  color: #00b2ff !important;
}

.connectionContainer {
  margin: 6px 0 20px 0;
  justify-content: space-between;
}

.connectionText {
  color: var(--secondary-5);
  line-height: 1.28;
}

.connectionBtn {
  min-height: 36px;
  padding: 0 14px;

  & svg path {
    fill: var(--secondary-7);
  }
}

.tabsContainer {
  margin: auto;
  max-width: 618px;
  padding: 16px;
  background-color: var(--white);
  width: 90%;
  border-radius: 14px;
  min-height: 438px;
  box-shadow: 0px 68px 19px 0px rgba(0, 0, 0, 0),
    0px 43px 17px 0px rgba(0, 0, 0, 0), 0px 24px 15px 0px rgba(0, 0, 0, 0.01),
    0px 11px 11px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.02);

  & > div:first-of-type {
    border-radius: 12px;
    padding: 3px 3px 0 3px;
    margin-bottom: 13px;
  }

  & div[class="ant-tabs-nav-list"] {
    width: 100%;

    & > div:not(:last-child) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      border-radius: 10px;
      margin: 0 0 3px 0 !important;
    }
  }

  & div[class="ant-tabs-tab ant-tabs-tab-active"] {
    box-shadow: 0px 4px 8px 0px rgba(52, 42, 114, 0.08),
      0px 1px 2px 0px rgba(52, 42, 114, 0.08);
  }
}

.video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.tab {
  width: 100%;
}

.mobileWrapper {
  background-color: var(--white);
  padding: 32px 20px 20px;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 100;
  overflow: hidden;
}

.logo {
  justify-content: center;
  margin-bottom: 32px;
}

.container {
  background-color: var(--secondary-1);
  position: relative;
  width: 100%;
  max-width: 355px;
  min-height: calc(100% - 80px);
  padding: 40px;
  margin: 0 auto;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: -1;
    background-image: url("../../../../assets/icons/preCreativeBg.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 690px;
  }
}

.iconContainer {
  position: relative;
  background: var(--secondary-1);
  background: radial-gradient(
    ellipse at left bottom,
    var(--secondary-3) 0%,
    var(--secondary-1) 100%
  );
  width: 100px;
  height: 95px;
  justify-content: center;
  border-radius: 23px;
  margin: auto 0 34px;
  z-index: 1;
  box-shadow: 0px 27.3px 7.7px 0px rgba(45, 55, 73, 0),
    0px 17.5px 7px 0px rgba(45, 55, 73, 0.01),
    0px 9.8px 5.6px 0px rgba(45, 55, 73, 0.03),
    0px 4.2px 4.2px 0px rgba(45, 55, 73, 0.04),
    0px 1.4px 2.1px 0px rgba(45, 55, 73, 0.05);

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 94px;
    height: 89px;
    background-color: var(--white);
    border-radius: 23px;
    z-index: -1;
  }
}

.textContainer {
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

.title {
  color: var(--secondary-6);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.36;
  margin-bottom: 0;
}

.text {
  color: var(--secondary-4);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  max-width: 270px;
  margin-bottom: 0;

  & strong {
    color: var(--secondary-5);
  }
}

.link {
  justify-content: center;
  width: 275px;
  height: 52px;
  color: var(--secondary-7);
  font-size: 16px;
  font-weight: 500;
  border-radius: 14px;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  margin-top: auto;
  transition: box-shadow 0.3s ease-in-out, color 0.3s ease-in-out;

  & svg path {
    transition: fill 0.3s;
  }
}

.link,
.link:focus,
.link:active,
.link:hover {
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
}

.link:hover {
  color: var(--secondary-7) !important;
  box-shadow: 0px 17px 5px 0px rgba(0, 0, 0, 0),
    0px 11px 4px 0px rgba(0, 0, 0, 0), 0px 6px 4px 0px rgba(0, 0, 0, 0.02),
    0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.link:focus {
  color: var(--primary-4) !important;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03),
    0px 0px 0px 3px var(--white), 0px 0px 0px 4px var(--secondary-1);

  & svg path {
    fill: var(--primary-4);
  }
}

.link:active {
  color: var(--primary-6) !important;
  box-shadow: 0px 2px 2px 0px rgba(101, 79, 228, 0.04),
    0px 0px 0px 3px var(--white), 0px 0px 0px 4px var(--primary-1) !important;

  & svg path {
    fill: var(--primary-6);
  }
}

@media screen and (min-width: 768px) {
  .mobileWrapper {
    display: none;
  }
}

.header {
  background-color: var(--primary-2);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid var(--primary-3);
  margin: -24px -24px 24px -24px;
  padding: var(--size-lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  & h2 {
    color: var(--black) !important;
    margin-bottom: 0.25rem;
  }
}

.btnContainer {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}

.btn {
  min-width: 7.25rem;
}

.tabs {
  width: 100%;
}

.row {
  justify-content: space-between;
}

.col {
  border: var(--border-base);
  border-radius: 8px;
  margin-top: var(--size-xxxs);
  width: 49%;
  max-width: 49%;

  & section {
    padding: var(--size-xs);
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      width: 30%;
    }

    & div {
      width: 70%;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  & section:not(:last-of-type) {
    border-bottom: var(--border-base);
  }

  & h4,
  h5 {
    margin-bottom: 0;
  }
  & h5 {
    font-size: var(--size-xs) !important;
    color: var(--secondary-5) !important;
  }
}

.mainTitle {
  color: var(--primary-4) !important;
  font-size: 18px !important;
}

.secondaryText {
  color: var(--secondary-4);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.1rem;
}

.tagContainer {
  margin-top: var(--size-s);
  gap: 10px;
}

.wrapper {
  position: relative;
}

.iconContainer {
  cursor: pointer;
  position: absolute;
  bottom: 6px;
}

.text {
  color: var(--primary-4);
}

.loading {
  max-height: 620px;
}

.cardContainer {
  flex-wrap: wrap;
  gap: 6px;
  opacity: 0;
  height: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
  pointer-events: all;
  height: auto;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: var(--white);
  padding: 12px;
  min-width: 160px;
  border-radius: 12px;
  border: 1px solid var(--secondary-2);
  transition: background-color 0.25s ease, border-color 0.25s ease;

  &:hover {
    background-color: var(--secondary-1);
    border-color: var(--secondary-1);
  }
}

.cardTitle {
  color: var(--secondary-5);
  line-height: 1;
}

.cardText {
  line-height: 14px;
}

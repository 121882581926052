.col,
.row {
  display: flex;
}

.col {
  flex-direction: column;
  max-width: 335px;
}

.row {
  flex-direction: row;
  gap: 1rem;
}

.text {
  line-height: 2.25;
}

.notMaxed {
  max-width: none;
}

.title {
  margin-bottom: 15px !important;
}

.link {
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-4);
  text-decoration: underline;
  margin: 1rem 0 2.25rem;
}

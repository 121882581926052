.errorModal {
  width: 30rem !important;

  & > div:nth-child(2) {
    & > div:first-child {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      padding: 18px 18px 20px;
    }
  }
}

.errorWrapper {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px 0;
  gap: 30px;
}

.errorIcon {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  background-color: var(--red-1);
  justify-content: center;

  & svg {
    width: 2.125rem;
    height: 2.125rem;

    & path {
      fill: var(--red-5);
    }
  }
}

.errorTitle {
  color: var(--primary-6) !important;
}

.errorText {
  color: var(--secondary-4);
}

.ok {
  padding: 0.8125rem 0.625rem;
  width: 100%;
}

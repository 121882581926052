.pickerContainer {
  width: 100%;
  padding: 6px;
  border-radius: 16px;
  background-color: var(--secondary-1);
  overflow-x: auto;
}

.picker {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  padding: 8px 0 0 0;
  border-radius: 14px;
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 27px 8px 0px rgba(0, 0, 0, 0),
    0px 18px 7px 0px rgba(0, 0, 0, 0), 0px 10px 6px 0px rgba(0, 0, 0, 0.02),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  overflow-x: auto;
}

.header {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  background-color: var(--white);
  border-bottom: 1px solid var(--secondary-2);
  border-radius: 14px 14px 0 0;
  padding-right: 14px;
}

.dayHeader:first-of-type {
  min-width: 182px;
}

.hourHeader {
  width: 30px;
  height: 28px;
}

.hour {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: var(--secondary-3);
  position: relative;
  bottom: -4px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 4px;
    background-color: var(--secondary-3);
    top: 16.5px;
    z-index: 0;
  }
}

.hour0,
.hour3,
.hour6,
.hour9,
.hour12,
.hour15,
.hour18,
.hour21 {
  color: var(--secondary-5);
  bottom: 0;

  &::after {
    height: 8px;
    background-color: var(--secondary-5);
  }
}

.hour1 {
  margin-left: -2px;
}

.hour0,
.hour2,
.hour3,
.hour4,
.hour5,
.hour6,
.hour7,
.hour8,
.hour9 {
  margin-left: -3px;
}

.hour11 {
  margin-left: -4px;
}

.hour10,
.hour12,
.hour13,
.hour14,
.hour15,
.hour16,
.hour17,
.hour18,
.hour19,
.hour20,
.hour21,
.hour22,
.hour23 {
  margin-left: -6px;
}

.dayRowContainer {
  padding: 0 14px;
  display: flex;
  background-color: var(--secondary-1);
  position: relative;
  z-index: 1;
}

.dayRow + .dayRow {
  & .dayRowContainer,
  .dayName {
    padding-top: 8px;
  }
}

.day0 {
  & .dayRowContainer,
  .dayName {
    padding-top: 14px;
  }
}

.day6 {
  & .dayRowContainer,
  .dayName {
    padding-bottom: 14px;
  }
}

.dayRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.dayNameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: span 1;
  min-width: 96px;
  background-color: var(--white);
  border-right: 1px solid var(--secondary-2);
}

.dayName {
  text-align: center;
  padding: 0 8px 0 18px;
  font-size: 12px;
  line-height: 1;
  color: var(--secondary-6);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.allDayBtn {
  width: 66px;
  min-height: 28px;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0),
    0px 2px 1px 0px rgba(0, 0, 0, 0.01), 0px 1px 1px 0px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  font-size: 12px;
  margin-right: 6px;
  color: var(--secondary-6);
}

.hourBox {
  width: 30px;
  height: 28px;
  background-color: var(--white);
  cursor: pointer;
  border: 1px solid var(--secondary-2);
  border-left: none;
}

.hourBox:first-of-type {
  border-left: 1px solid var(--secondary-2);
  border-radius: 10px 0 0 10px;
}

.hourBox:last-of-type {
  border-radius: 0 10px 10px 0;
}

.hourBox.selected {
  background-color: var(--primary-2);
  border-color: var(--primary-3);
}

.clearContainer {
  padding: 12px 16px;
  width: 100%;
  max-width: 821px;
  margin-left: auto;
  border-left: 1px solid var(--secondary-2);
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & div {
    gap: 6px;
    cursor: pointer;
  }
}

.dayPartingCol {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 50px;
  margin-bottom: 24px;
  border-top: 1px solid var(--secondary-2);
}

.dayPartingCol.details {
  flex-direction: column;
  padding-top: 0;
  margin-bottom: 0;
  border-top: none;

  & .dayPartingRadio {
    width: 100% !important;
    margin-bottom: 0;
  }
}

.detailsRadio {
  width: 100%;
  margin-bottom: 12px;

  & > div > div > div > div {
    flex-direction: row !important;
    justify-content: space-between;
    gap: 12px;
  }
}

.title {
  color: var(--secondary-6) !important;
  font-size: 1.125rem !important;
  font-weight: 600;
  line-height: 1 !important;
  margin-bottom: 0.625rem !important;
}

.text {
  color: var(--secondary-4);
  line-height: 2.2;
}

.dayPartingRadio {
  margin-bottom: 10px;
  padding: 12px;

  & h5 {
    margin-bottom: 0 !important;
  }
}

.dayPartingRadio .dayPartingText {
  color: var(--secondary-4);
  display: inline-block;
  line-height: 1.5;
  font-size: 0.75rem !important;
}

.tableText {
  display: inline-block;
  color: var(--secondary-6);
  line-height: 1.66;
  padding: 0.75rem;
}

.allDayBtn.selected {
  border: 1px solid var(--primary-3);
  background: var(--primary-2);
  color: var(--primary-5);
}

.clearText {
  color: var(--red-5);
  line-height: 1;
}

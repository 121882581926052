:root {
  //For svg icons
  --primary-color: #654fe4;

  // New Palette
  --white: #fff;
  --black: #000;

  --primary-1: #fafaff;
  --primary-2: #f5f1ff;
  --primary-3: #ddd7ff;
  --primary-4: #654fe4;
  --primary-5: #6857c6;
  --primary-6: #1e135c;
  --primary-7: #9786ff;

  --secondary-1: #f5f7f9;
  --secondary-2: #eceff2;
  --secondary-3: #d3d7e3;
  --secondary-4: #8b98ae;
  --secondary-5: #616e84;
  --secondary-6: #445167;
  --secondary-7: #252a31;
  --secondary-8: #fdfdff;
  //--gray-1 equals to --secondary-3
  --gray-2: #f9f9ff;
  --gray-3: #838c99;
  --gray-4: #818194;
  --gray-5: #9ea2b9;
  --gray-7: #a4a9bc;
  --gray-9: #676a79;
  --gray-10: #f7f8fc;
  --gray-11: #e3e5f1;

  --red-1: #fff3f7;
  --red-2: #ffeff9;
  --red-3: #ffdff3;
  --red-4: #fd6d98;
  --red-5: #df3c6d;
  --red-7: #cf1322;

  --pink-1: #eb2f96;
  --pink-2: #e4456c;
  --pink-3: #e4638a;

  --green-1: #e9fff4;
  --green-2: #c4edda;
  --green-7: #389e0d;
  --green-9: #1fcd79;
  --green-10: #3ab078;

  --blue-1: #ecf3ff;
  --blue-2: #deeaff;
  --blue-6: #689dff;
  --blue-10: #1263ff;

  --yellow-1: #fffbef;
  --yellow-2: #ffebad;
  --yellow-6: #fbbc00;
  --yellow-7: #c99806;
  --yellow-9: #a8924e;
  --yellow-10: #645320;

  --border-base: 1px solid #d3d7e3;

  // Admin Bar
  --admin-bar-bg: #1e135c;
  --admin-bar-btn: #1263ff;

  // Navbar
  --header-bg: #fff;
  --header-text: #000;
  --header-border: 1px solid #eceff2;

  --size-radius: 6px;
  --size-xxxs: 12px;
  --size-xxs: 13px;
  --size-xs: 14px;
  --size-s: 16px;
  --size-md: 20px;
  --size-lg: 24px;
  --size-xl: 28px;
  --size-xxl: 32px;
}

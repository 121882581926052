.header {
  justify-content: space-between;
  position: sticky;
  top: 60px;
  left: 0;
  margin: 0 -36px 0 -36px;
  padding: 2.25rem;
  background-color: var(--secondary-1);
  z-index: 11;
}

.textContainer {
  max-width: 820px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.settingsTab {
  top: 112px;
  position: fixed;
  width: calc(100% - 230px);
  margin: 0 auto;
  right: 0;
  left: 230px;
}

.bidTab .textContainer {
  max-width: 1100px;
}

.mainTitle {
  margin-bottom: 0.75rem !important;
  color: var(--primary-6) !important;
  line-height: 1 !important;
}

.mainText {
  display: block;
  color: var(--secondary-5);
  line-height: 1;
}

.btn {
  width: 5.625rem;
}

.caseHeader {
  background-color: #fff1e9;
  background-image: url("../../../../../assets/images/landing_page/caseStudy.png");
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 6rem;
  min-height: 495px;
}

.caseHeader h1,
.caseHeader p {
  width: 90%;
  margin: auto;
  max-width: 700px;
}

.caseHeader h1 {
  font-family: "Satoshi", sans-serif !important;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 2.75rem;
  background: linear-gradient(89deg, #0074de 1.21%, #3824aa 67.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.caseHeader p {
  font-size: 16px;
  line-height: 2;
  color: #4f4f4f;
}

@media screen and (min-width: 768px) {
  .caseHeader h1 {
    font-size: 3.625rem;
  }
}

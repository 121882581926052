.wrapper {
  background-color: var(--secondary-1);
  width: calc(100% + 48px);
  margin: -24px 0 -24px -24px;
  padding-top: 20px;
  min-height: calc(100vh - 66px);
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.barContainer,
.emptyContainer {
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
}

.emptyContainer {
  background-color: white;
  border: 1px solid var(--secondary-2);
  height: 575px;
  padding: var(--size-lg);
  border-radius: 12px;
  margin-top: 40px;
}

.grayText {
  color: var(--secondary-5);
}

.barContainer {
  margin-top: 24px;
  padding-bottom: 24px;
}

.bar {
  width: 100%;

  & span {
    line-height: 1;
  }
}

.bar,
.barBtn {
  background-color: white;
  justify-content: center;
  text-align: center;
  height: 42px;
  border: 1px solid var(--secondary-2);
  border-radius: 14px;
}

.barBtn {
  width: 42px;
  padding: 10px;
  flex-shrink: 0;
  cursor: pointer;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03),
    0px 2px 10px 0px rgba(0, 0, 0, 0.03);

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: var(--secondary-7);
    }
  }
}

.textarea {
  min-height: 130px !important;
}

.thumbnail {
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.inspectThumbnail,
.formThumbnail {
  background-color: var(--secondary-2);

  & svg path {
    transition: fill 0.3s ease-in-out;
  }

  &:hover {
    background-color: var(--secondary-4);

    & .inspectText {
      color: var(--secondary-2);
    }

    & .inner > svg path {
      fill: var(--secondary-2);
    }

    & > div:last-of-type {
      border-color: rgba(255, 255, 255, 0.15);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.25) 0%,
          rgba(255, 255, 255, 0.25) 100%
        ),
        linear-gradient(
          324deg,
          rgba(255, 255, 255, 0.3) 9.92%,
          rgba(255, 255, 255, 0) 95.32%
        );
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8px);

      & span {
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
          0px 1px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.inspectText {
  color: var(--secondary-5);
  font-size: 18px;
  line-height: 0.77;
  transition: color 0.3s ease-in-out;
}

.detailsThumbnail {
  display: flex;
  flex-direction: row-reverse;
  gap: 14px;
  justify-content: flex-end;
  align-items: center;

  & .detailsInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 160px;
    height: 60px;
    border: 1px solid var(--secondary-2);
    background: var(--secondary-1);

    & > svg {
      width: 20px;
      height: 20px;

      & path {
        fill: var(--secondary-4);
      }
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.formThumbnail {
  width: 204px;
  height: 114px;
}

.detailsText {
  color: var(--secondary-4);
}

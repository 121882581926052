.label,
.labelUp {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

.label label,
.labelUp label {
  font-family: "Poppins", sans-serif !important;
}
.label label,
.labelUp label {
  font-weight: 500;
  color: var(--secondary-4);
  font-size: var(--size-xs);
}
.label > div:first-of-type,
.labelUp > div:first-of-type {
  position: absolute;
  z-index: 10;
  transition: 0.2s ease-in top;
  left: var(--size-xs);
}
.label > div:first-of-type {
  top: 11px;
  margin-bottom: 10px;
  pointer-events: none;
}

.labelUp > div:first-of-type {
  top: 0;
}
.labelUp > div:first-of-type label {
  color: var(--primary-5) !important;
  font-size: var(--size-xxs) !important;
}
.normalText {
  color: var(--gray-4);
}

.errorText {
  color: var(--red-5) !important;
}

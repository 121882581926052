.footer {
  padding-top: 4rem;
  position: relative;

  & a:hover {
    color: #1a1a1a !important;
  }
}

.footer p {
  color: #1a1a1a;
  text-align: center;
  font-size: 1.75rem;
  font-family: "Satoshi", sans-serif !important;
  font-weight: 700;
  line-height: 1.35;
  width: 280px;
  margin: 0 auto;
}

.footer > a {
  border-radius: 2.625rem;
  background: #f58220;
  padding: var(--size-xs) 2.25rem;
  color: var(--white);
  display: block;
  width: max-content;
  margin: 2rem auto 4rem;
}

.footer ul,
.divider {
  max-width: 1228px;
  margin: 0 auto;
}

.divider {
  width: 100%;
  height: 20px;
  background-image: url("../../../../../assets/images/landing_page/footer-mobile.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.footer ul {
  list-style: none;
  padding: 1.25rem 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
}

.links {
  width: 100%;
  order: -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links a,
.year {
  color: #a8a8a8;
  font-size: 12px;
  font-family: "Satoshi", sans-serif !important;
  font-weight: 500;
  line-height: 1.35;
}

.logo {
  width: 100px;
  margin-bottom: 3rem;
}

.footerLogo {
  margin-left: auto;
}

.year {
  position: absolute;
  bottom: 0;
  padding: 1rem 0;
  border-top: 1px solid #f1f1f1;
  width: calc(100% - 2rem);
  text-align: center;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1.5px solid #a8a8a8;
  margin-bottom: -2px;
}

@media screen and (min-width: 768px) {
  .footer {
    padding-top: 7.5rem;

    & p {
      width: 516px;
      font-size: 3rem;
    }

    & > a {
      font-size: 1.125rem;
      line-height: 1.25;
      padding: 1.5rem 4rem;
      margin: 3.125rem auto 4.625rem;
    }

    & ul {
      flex-wrap: nowrap;
      padding: 4rem 1rem;
      gap: 2.5rem;
      align-items: center;
      justify-content: space-between;

      & li {
        width: fit-content;
      }
    }
  }

  .year {
    position: relative;
    border-top: unset;
    padding: 0;
    width: 100%;
    margin-left: auto;
  }

  .divider {
    background-image: url("../../../../../assets/images/landing_page/footer.png");
  }

  .logo {
    order: -1;
    margin-bottom: 0;
  }

  .links {
    order: 0;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    margin-left: auto;
  }

  .footerLogo {
    margin-left: unset;
    order: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media screen and (min-width: 1280px) {
  .footer {
    padding-top: 10rem;
  }

  .footer p {
    width: fit-content;
  }

  .footer > a {
    margin: 5.25rem auto 10rem;
  }

  .footer ul {
    padding: 2rem 0 10rem;
  }
}
.container,
.card {
  display: flex;
}

.container {
  justify-content: space-between;
  background-color: var(--secondary-1);
  border-radius: 0.875rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
}

.card {
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  & span {
    color: var(--secondary-6);
    line-height: 1;
  }
}

.card:last-of-type {
  padding-left: 1.25rem;
  border-left: var(--border-base);
}

.container {
  background: var(--yellow-1);
  border-radius: 10px;
  padding: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
  width: 100%;

  & article {
    width: 90%;
  }

  & span {
    color: var(--yellow-7);
  }
}

.block {
  width: 18px;
}

.block circle {
  fill: var(--yellow-7);
}

.copy {
  width: 14px;
  cursor: pointer;
}

.copyText {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  gap: 12px;
  background-color: var(--yellow-6);
  color: var(--white) !important;
  border-radius: var(--size-radius);
  & svg path {
    fill: var(--white);
  }
}

.modal > div:nth-child(2) > div:first-of-type {
  padding: 30px;
}

.modal > div:nth-child(2) > div:last-of-type {
  padding: 3px 30px 30px 30px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cancel,
.cancel:hover {
  color: var(--primary-4);
  font-size: var(--size-xs);
  font-weight: 600;
  border: 1px solid var(--primary-4);
}

.ok {
  padding: 13px 10px;
  width: 100%;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--green-1);
  width: 87px;
  height: 87px;
  & svg {
    width: 34px;
  }
}

.title {
  max-width: 298px;
  text-align: center;
  margin: 1em auto;
  line-height: 30px;
  color: var(--primary-6) !important;
}

.content {
  display: flex;
  text-align: center;
}

.popupText {
  color: var(--secondary-4);
  line-height: 29px;
  max-width: 416px;
}

.list {
  padding: unset;
  list-style-position: inside;
  margin-top: var(--size-lg);
  background-color: var(--secondary-8);
  padding: 24px 36px;
  border: 1px solid var(--secondary-2);
  border-radius: 12px;
  & li,
  li > span {
    color: var(--secondary-4) !important;
  }
}

.formList {
  max-width: calc(1000px - 5rem);
  margin: auto;
}

.openList {
  max-width: 1000px;
  padding: 1.5rem 5rem;
  margin: -10px auto 0 auto;
}

.warningRecommended {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--primary-4) !important;
  }
}

.table,
.tableBody {
  width: 100%;
}

.table th {
  text-align: start;
}

.title {
  padding: 14px;
}

.title,
.modalTitle {
  color: var(--primary-6) !important;
}

.tableBody {
  & td {
    padding: 0;
  }

  & .data {
    padding: 14px;
    margin: 0;
    display: block;
    flex-wrap: wrap;
    max-height: 50px;
    overflow-y: auto;
  }

  & tr:nth-child(odd) {
    background-color: var(--secondary-1);
    width: 100%;
    display: block;
  }
}

.recommendedSizes tr:nth-child(odd) {
  border-radius: 10px 0 0 10px;
}

.sizes .data {
  display: flex;
  gap: 10px;
}

.sizes tr:nth-child(odd) {
  border-radius: 0 10px 10px 0;
}

.modal {
  width: 42rem !important;
  & img {
    width: 100%;
    max-width: max-content;
    display: flex;
    margin: 0 auto;
  }
}

.modal div[class="ant-modal-header"] {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 36px 36px 0 36px;
  border: none;
  position: relative;
}

.modal div[class="ant-modal-body"] {
  padding: 36px;
}

.modal div[class="ant-modal-footer"] {
  padding: 0 36px 36px 36px;
  justify-content: flex-end;
}

.modalBtn {
  width: 167px;
  height: 42px;
  justify-content: center;
  padding: 0;
  gap: 0;
}

.modalText {
  color: var(--secondary-4);
  display: block;
  max-width: 500px;
  font-weight: 400 !important;
}

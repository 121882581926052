.navbar {
  display: flex;
  align-items: center;
  background-color: var(--header-bg);
  color: var(--header-text);
  padding: 0 0 0 var(--size-lg) !important;
  gap: 1.5rem;
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  overflow-x: auto;
  overflow-y: hidden;
  line-height: 1;

  .logo {
    flex-shrink: 0;
    position: relative;
    margin-right: 1.5rem;
  }

  .logo::after {
    content: "";
    width: 1px;
    height: 50%;
    position: absolute;
    top: 50%;
    margin-left: 1.5rem;
    transform: translateY(-50%);
  }

  & span,
  h5 {
    color: var(--header-text) !important;
  }
}

.navbar.light {
  border-bottom: 1px solid var(--secondary-2);

  & .logo::after {
    background-color: var(--secondary-2);
  }

  & svg path {
    fill: var(--secondary-7);
  }
}

.navbar.dark {
  border-bottom: 1px solid var(--header-bg);

  & .logo::after {
    background-color: var(--secondary-6);
  }

  & svg path {
    fill: var(--secondary-2);
  }
}

.title {
  color: var(--black) !important;
  margin: 0 !important;
  font-size: 14px !important;
}

.logoImg {
  cursor: pointer;
  width: 104px;
}

.breadcrumb a {
  color: var(--secondary-4) !important;
  font-weight: 500;
  text-decoration: underline;
}

.titleCrumb {
  font-weight: 600;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.breadcrumb,
.titleCrumb {
  font-size: 14px;
  line-height: 24px;
}

.lightSeparator path {
  fill: var(--secondary-4) !important;
}

.darkSeparator path {
  fill: var(--secondary-3) !important;
}

.logout {
  padding: 8px 10px;
  border: var(--header-border);
  border-radius: 8px;
  cursor: pointer;
}

.logout.beymen {
  border: 1px solid var(--secondary-5);
}

.sync,
.link,
.noSellerSite {
  margin-left: auto;
}

.link {
  border-radius: var(--size-radius);
  flex-shrink: 0;

  & a {
    font-size: 14px;
    font-weight: 500;
  }

  & svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.link.light a {
  color: var(--secondary-7);

  &:hover {
    color: var(--secondary-7) !important;
  }
}

.link.dark a {
  color: var(--secondary-2);

  &:hover {
    color: var(--secondary-2) !important;
  }
}

.validError {
  border: 2px solid var(--red-5);
  background-color: var(--red-2);
  position: relative;
}

.creativeContainer {
  width: 28.5rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: var(--border-base);
  gap: 0;

  & > div {
    width: 50%;
    padding: var(--size-xxxs);
  }

  & > div:first-of-type {
    border-radius: 12px;
    background-color: var(--secondary-1);
    justify-content: center;
  }

  & img {
    max-width: 208px;
    height: 140px;
    object-fit: contain;
  }

  & input {
    width: 12.75rem !important;
  }
}

.creativeContainerText {
  margin-top: 10px;
}

.size {
  color: var(--secondary-5);
}

.languageCheckboxWrapper {
  gap: 4px;
  padding: 6px;
  border-radius: 12px;
  border: 1px solid var(--secondary-1);
  margin-top: 10px;
}

.creativeLanguages {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.creativeLanguageItem {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 !important;
  padding: 10px;
  line-height: 1;
  background-color: var(--white);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--secondary-1);
    border-radius: 8px;
  }

  & span:first-of-type {
    top: 0.5px;
  }
}

.header {
  background-color: var(--primary-2);
  margin: -24px -24px 24px -24px;
  padding: 24px;
  border-bottom: 1px solid var(--primary-3);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);

  & h2 {
    color: var(--black) !important;
    line-height: 1.2;
    margin-bottom: 15px;
  }
}

.headerFieldWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.headerField {
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 8px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);

  & svg {
    flex-shrink: 0;

    & path {
      fill: var(--secondary-6);
    }
  }

  & span {
    line-height: 1;
  }
}

.headerFieldLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  background-color: var(--secondary-1);
  border-right: 1px solid var(--secondary-3);
  border-radius: 8px 0 0 8px;
}

.headerFieldValue {
  display: flex;
  align-items: center;
  padding: 10px;
  word-break: break-all;
  border-radius: 0 8px 8px 0;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.green span {
  color: var(--green-10);
}

.green svg circle {
  fill: var(--green-10);
}

.red span {
  color: var(--red-5);
}

.red svg circle {
  fill: var(--red-5);
}

.yellow span {
  color: var(--yellow-6);
}

.yellow svg path {
  fill: var(--yellow-6);
}

.blue span {
  color: var(--blue-10);
}

.blue svg circle {
  fill: var(--blue-10);
}

.cols {
  width: 100%;
  width: max-content;
  gap: 6px;
  cursor: pointer;
}

.logsText {
  color: var(--primary-4);
}

.form,
.success {
  width: 50%;
  height: 100%;
  flex-direction: column;
  position: relative;
  margin: 0 auto;

  & .title,
  form {
    width: 90%;
    max-width: 435px;
  }

  & h1 {
    color: var(--primary-6);
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 0;
  }

  & p,
  .formItem {
    line-height: 2.14;
    margin-bottom: 2.25rem;
  }

  & p,
  input::placeholder,
  a {
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-4);
  }

  & input::placeholder {
    font-family: "Poppins", sans-serif !important;
  }

  & form {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }
}

.title {
  gap: 30px;
  margin-bottom: 40px;
}

.formItem input:not([id="password"]),
.formItem > div > div > div > span {
  border: var(--border-base);
  border-radius: var(--size-radius);
  padding: 8px 14px;
  height: 48px;
  &:hover {
    border: 1px solid var(--primary-4) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03),
      0px 2px 10px rgba(0, 0, 0, 0.03);
  }
}

.icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--secondary-1);
  align-self: start;
}

.form,
.success,
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  width: 100%;
  height: 42px;
}

.return {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-xs);
  & svg path {
    fill: var(--secondary-4);
  }

  & a {
    text-decoration: underline;
  }
}

.check,
.check .icon,
.success {
  justify-content: center;
}

.check .icon {
  width: 58px;
  height: 58px;
  background-color: var(--green-1);
  border-radius: 50%;
  & svg {
    width: 22px;
  }
}

.success {
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 435px;

  & h2 {
    color: var(--primary-6) !important;
    font-size: 28px !important;
    margin-bottom: 28px;

    & span {
      font-weight: 700;
    }
  }

  & p {
    margin-bottom: 0;
  }

  & article {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
  }
}

.contentContainer {
  padding: 60px 0;
  margin-left: 40px;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.active.contentContainer {
  position: relative;
  opacity: 1;
}

.titleContainer {
  position: sticky;
  top: 68px;
  z-index: 9;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding 0.3s;
}

.sticky {
  padding: 18px;
  border: 1px solid var(--secondary-1);
  box-shadow: 0px 37px 10px 0px rgba(0, 0, 0, 0),
    0px 23px 9px 0px rgba(0, 0, 0, 0), 0px 13px 8px 0px rgba(0, 0, 0, 0.01),
    0px 6px 6px 0px rgba(0, 0, 0, 0.02), 0px 1px 3px 0px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(7px);
}

.title,
.text {
  margin: 0;
}

.title {
  color: var(--secondary-6);
  font-size: 24px;
  line-height: 1;
}

.active .title {
  color: var(--primary-6);
}

.text {
  max-width: 890px;
  color: var(--secondary-4);
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  margin-top: 12px;
}

.mappingContainer {
  background-color: var(--secondary-1);
  border: 1px solid var(--secondary-2);
  border-radius: 14px;
  box-shadow: 0px 63px 18px 0px rgba(0, 0, 0, 0),
    0px 40px 16px 0px rgba(0, 0, 0, 0), 0px 23px 14px 0px rgba(0, 0, 0, 0.01),
    0px 10px 10px 0px rgba(0, 0, 0, 0.02), 0px 3px 6px 0px rgba(0, 0, 0, 0.02);
  margin-top: 30px;
}

.mappingHeader {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) minmax(200px, 2fr) 1fr;
}

.mappingTitle {
  padding: 16px;
  color: var(--secondary-6) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 0.85;
  min-width: 200px;
}

.mappingTitle:first-of-type {
  border-right: 1px solid var(--secondary-2);
}

.rowContainer {
  padding: 0 8px;
}

.mappingRow {
  display: grid;
  grid-template-columns: minmax(200px, 2fr) minmax(200px, 2fr) 1fr;
}

.fieldRow,
.matchedRow {
  padding: 10px;
  background-color: white;
}

.mappingRow:first-of-type .fieldRow {
  border-radius: 12px 0 0 0;
}

.mappingRow:last-of-type .fieldRow {
  border-radius: 0 0 0 12px;
}

.mappingRow:first-of-type .matchedRow {
  border-radius: 0 12px 0 0;
}

.mappingRow:last-of-type .matchedRow {
  border-radius: 0 0 12px 0;
}

.dataRow {
  padding: 10px;
  margin-left: 6px;
  border-right: 1px solid var(--secondary-2);
  border-left: 1px solid var(--secondary-2);
  width: 200px;

  & span {
    display: inline-block;
    color: var(--secondary-6);
    width: 100%;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mappingRow:first-of-type .dataRow {
  border-radius: 12px 12px 0 0;
  border-top: 1px solid var(--secondary-2);
}

.mappingRow:last-of-type .dataRow {
  border-radius: 0 0 12px 12px;
  border-bottom: 1px solid var(--secondary-2);
}

.fieldContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.field {
  color: var(--secondary-6);
  line-height: 1;
}

.type {
  color: var(--secondary-4);
}

.required {
  color: var(--red-5);
}

.showHeader {
  width: calc(100% - 216px);
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin: 4px 0;
  cursor: pointer;

  & svg {
    width: 12px;
    transition: transform 0.3s ease;

    & path {
      fill: var(--secondary-4);
    }
  }
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  pointer-events: none;
  transition: all 0.5s ease;
}

.open {
  padding: 0 8px 8px;
  opacity: 1;
  height: auto;
  overflow: visible;
  pointer-events: auto;
  transition: all 0.5s ease;
}

.iconUp {
  transform: rotate(180deg);
}

.showText {
  color: var(--secondary-4);
  text-align: center;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;
}

.arrow {
  margin-left: auto;
  flex-shrink: 0;
}

@import "~antd/dist/antd.css";
@import "./variables.scss";

@font-face {
  font-family: "Eudoxus";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/EudoxusSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Eudoxus";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/EudoxusSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Eudoxus";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/EudoxusSans-Bold.woff") format("woff");
}
@font-face {
  font-family: "Eudoxus";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/EudoxusSans-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/Poppins-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/Poppins-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url("../assets/fonts/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-weight: 500;
  font-style: normal;
  src: url("../assets/fonts/Satoshi-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Satoshi";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/Satoshi-Bold.otf") format("opentype");
}
html * {
  font-family: "Eudoxus", sans-serif !important;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

a:hover {
  color: var(--primary-4) !important;
}

button {
  border: none;
  cursor: pointer;
}

pre {
  margin-bottom: 0;
}

.hljs {
  padding: 0 !important;
}

.hljs::-webkit-scrollbar {
  display: none;
}

.hljs {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.flex {
  display: flex;
  align-items: center;
  gap: 0.75em;
}

.ant-layout {
  background-color: var(--white) !important;
}

// Menu Styles To Override
.ant-menu-item-selected a,
.ant-menu-submenu-title,
.ant-menu-item-selected a:hover,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-arrow {
  color: var(--primary-6) !important;
}

.ant-menu-submenu-selected > div::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: var(--primary-6);
}

.ant-menu-submenu-selected {
  position: relative;
}

.ant-menu-submenu-selected::before,
.ant-menu-submenu-selected::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background-color: var(--secondary-2);
}

.ant-menu-submenu-selected::before {
  top: -12px;
}
.ant-menu-submenu-selected::after {
  bottom: -12px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: var(--secondary-1);
}
//General Text Type Secondary
.ant-typography.ant-typography-secondary {
  color: var(--gray-3) !important;
  display: inline-block;
}
//General Text Type Success
.ant-typography.ant-typography-success {
  color: var(--green-10) !important;
}
//General Title
.title {
  color: var(--primary-6) !important;
}

// General Bordered Container
.bordered-container {
  border: var(--border-base);
  border-radius: 8px;
  padding: var(--size-s);
  margin-top: var(--size-lg);
  gap: 1rem !important;
}

.bordered-container.no-margin {
  margin-top: 0;
  & .ant-row {
    width: 100%;
  }
}
.bordered-container.full-width > div {
  width: 100%;
}

//Form Radio and Checkbox Styles
.ant-checkbox + span {
  padding: 0;
}

.ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after,
.ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-checked::after {
  border-radius: 5px;
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 1px 2px 0px #14151a0d;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active,
.ant-radio-input:focus + .ant-radio-inner,
.ant-checkbox-checked::after,
.ant-checkbox-wrapper.ant-checkbox-wrapper-checked:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-inner:hover,
.ant-select-tree-checkbox-checked::after {
  border-color: var(--primary-4) !important;
}

.ant-radio-checked::after {
  border-color: var(--primary-7) !important;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: var(--primary-4) !important;
  border-color: var(--primary-4) !important;
  position: relative;

  &::after {
    position: absolute;
    display: block;
    width: 62.5%;
    height: 2px;
    background-color: #fff !important;
    opacity: 1;
    transition: all 0.2s ease-in-out 0.1s;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
  }
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-radio-wrapper .ant-radio .ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary-7) !important;
}

.ant-checkbox.ant-checkbox-checked
  .ant-checkbox-input:focus
  + .ant-checkbox-inner,
.ant-radio.ant-radio-checked .ant-radio-input:focus + .ant-radio-inner {
  background-color: var(--primary-7) !important;
}

.ant-checkbox-wrapper:hover
  .ant-checkbox.ant-checkbox-indeterminate
  .ant-checkbox-inner,
.ant-checkbox-wrapper:hover
  .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--primary-5) !important;
  border-color: var(--primary-5) !important;
}

.ant-checkbox::after {
  transition: border-color 0.3s !important;
}

.ant-radio:hover .ant-radio-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--secondary-3) !important;
}

.ant-checkbox-wrapper-disabled:hover .ant-checkbox .ant-checkbox-inner {
  border-color: var(--secondary-2) !important;
}

.ant-radio.ant-radio-checked:hover .ant-radio-inner {
  border-color: var(--primary-5) !important;
  background-color: var(--primary-5) !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner::after {
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
}

.ant-checkbox-wrapper > span {
  color: var(--secondary-7);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):active,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper:focus {
  color: var(--primary-4) !important;
  box-shadow: none !important;
}

.ant-radio-inner {
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 1px 2px 0px #14151a0d;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before,
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--primary-4) !important;
}

.ant-radio-inner::after {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
  background-color: var(--white) !important;
  box-shadow: 0px 2px 2px 0px #00000040;
}

.form-radio .ant-checkbox,
.form-radio .ant-radio {
  order: 1;
}

.ant-checkbox-wrapper.form-radio span {
  font-family: "Poppins", sans-serif !important;
  font-size: var(--size-xxxs);
  font-weight: 600;
  color: var(--gray-4);
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.form-radio
  span:not(.checkbox-gray-text) {
  color: var(--primary-4);
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}
.ant-radio-checked .ant-radio-inner {
  background-color: var(--primary-4) !important;
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  border-color: var(--primary-4) !important;
  background-color: var(--primary-4);
}

.radio-container,
.radio-col {
  display: flex;
  align-items: center;
  gap: 1em;
}

.radio-container {
  border: var(--border-base);
  border-radius: 8px;
  padding: var(--size-xs) var(--size-lg);
  & h4 {
    margin-bottom: 0;
  }
}

.search .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px var(--secondary-1) !important;
}

.ant-popover.ant-popover-placement-bottom .ant-popover-inner-content {
  padding: 0 var(--size-lg) var(--size-lg) var(--size-lg);
  max-height: 40vh;
  overflow-y: auto;
}

.ant-popover.ant-popover-placement-left .ant-popover-inner-content,
.ant-popover.ant-popover-placement-leftTop .ant-popover-inner-content {
  padding: 0.25rem;
  min-width: 160px;
  width: max-content;
}
.ant-popover-title {
  padding: var(--size-lg);
  border-bottom: none;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.ant-popover-inner,
.ant-popover-content {
  border-radius: 8px;
}
.ant-popover.ant-popover-placement-bottom .ant-popover-content {
  border: 1px solid var(--primary-4);
}
.ant-popover.ant-popover-placement-left .ant-popover-content,
.ant-popover.ant-popover-placement-leftTop .ant-popover-content {
  border: none;
  box-shadow: 0 2px 2px 0 rgba(31, 24, 69, 0.1);
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: var(--secondary-1) !important;
  border-color: var(--secondary-2) !important;
  box-shadow: 0px 3px 3px 0px #0000000d inset;
}

.ant-radio.ant-radio-disabled .ant-radio-inner,
.ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner {
  background-color: var(--secondary-1) !important;
  border-color: var(--secondary-2) !important;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05) inset;
}

.ant-radio.ant-radio-checked.ant-radio-disabled .ant-radio-inner::after {
  background-color: var(--secondary-3) !important;
  box-shadow: none;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--secondary-3) !important;
}

// General Modal Styles
.ant-modal-content {
  border-radius: 1.25rem !important;
  box-shadow: 0px 2px 15px rgba(31, 24, 69, 0.1),
    0px 2px 2px rgba(31, 24, 69, 0.1) !important;
}

.ant-modal-footer {
  border-top: none;
  display: flex;
  justify-content: center;
  padding: 0 18px 18px;
}

.ant-popover.ant-popover-placement-bottom .ant-popover-arrow,
.ant-modal-close-x,
.ant-picker-suffix {
  display: none;
}
//Campaign details modal has close button.
.campaign-modal {
  & .ant-modal-close {
    top: 20px;
    right: 18px;
  }

  & .ant-modal-close-x {
    display: contents;

    & svg path {
      fill: var(--secondary-4);
    }
  }
}
.ant-modal-footer {
  border-top: none;
}
//Table Styles
.ant-table-wrapper {
  width: 100%;
}
thead .ant-table-cell {
  background: transparent !important;
}
.ant-table-thead > tr > th,
.ant-table-column-title {
  font-weight: 500;
}
.ant-table-tbody tr:nth-child(odd) {
  background: var(--secondary-1);
}
.ant-table-tbody {
  border-radius: 8px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table-column-title {
  font-family: "Poppins", sans-serif !important;
  border-bottom: none;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: var(--secondary-1);
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: inherit;
}
.ant-table-tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}
.ant-table-tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

td.ant-table-column-sort {
  background: transparent;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--primary-5);
}

//Pagination
.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
  display: none;
}

.ant-table-pagination.ant-pagination {
  margin-bottom: 0;
}

.ant-pagination-item,
.ant-pagination-item-active,
.ant-pagination-item-link {
  border-radius: 10px !important;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border: 1px solid var(--secondary-2) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03),
    0px 2px 10px 0px rgba(0, 0, 0, 0.03);

  & span,
  a {
    color: var(--secondary-7) !important;
    transition: color 0.2s ease;
  }

  & svg {
    width: 14px;
    height: 14px;
  }

  & svg path {
    fill: var(--secondary-7) !important;
    transition: fill 0.2s ease;
  }
}

.ant-pagination-item-link:not(.ant-pagination-item-active) {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.03),
    0px 2px 10px 0px rgba(0, 0, 0, 0.03);
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  background: var(--secondary-1);
  border: none !important;
  box-shadow: none;
}

.ant-pagination-item:hover,
button.ant-pagination-item-link:hover {
  background-color: var(--secondary-1);
  border-color: transparent !important;
}

.ant-pagination-item:hover,
.ant-pagination-item:hover a {
  color: var(--secondary-7) !important;

  & svg path {
    fill: var(--secondary-7) !important;
  }
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  & span {
    color: var(--secondary-3) !important;
  }
  & svg path {
    fill: var(--secondary-3) !important;
  }
}

.ant-pagination-item-active {
  border: 1.5px solid var(--primary-4) !important;

  & a {
    color: var(--primary-4) !important;
    font-weight: 500;
  }
}

.ant-pagination-total-text {
  order: -2;
  margin-right: 0;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: var(--size-xxxs);
  color: var(--secondary-5);
  display: flex;
  align-items: center;
}

.ant-pagination-options {
  order: -1;
  margin-right: 36px;
  position: relative;

  & .ant-select-selector {
    padding: 0 var(--size-xxxs) !important;
    font-size: var(--size-xxxs);
  }

  & .ant-select-selection-item {
    padding-right: var(--size-lg) !important;
  }

  & .ant-select-dropdown.ant-select-dropdown-placement-topLeft {
    border-radius: var(--size-radius);
    padding: var(--size-xxxs);
  }

  &::after {
    content: "";
    position: absolute;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    background-color: var(--secondary-3);
    right: -18px;
  }
}

//Switch
.ant-switch {
  background: var(--secondary-3);
}

.ant-switch-checked {
  background: var(--primary-4);
}

.ant-click-animating-node {
  box-shadow: 0 0 0 0 var(--primary-6) !important;
}

// Steps
.nav-steps {
  padding-top: 0;
  height: 82px;

  & .ant-steps-item-container {
    display: inline-flex;
    align-items: center;
  }

  & .ant-steps-item:first-of-type,
  .ant-steps-item:last-of-type {
    border-left: var(--border-base);
    border-right: var(--border-base);
  }

  & .ant-steps-item::before {
    background-color: var(--primary-4);
  }

  & .ant-steps-item-title {
    display: flex;
    flex-direction: column-reverse;
    line-height: var(--size-xl);
    font-size: var(--size-xs);
    cursor: pointer;

    & .ant-steps-item-subtitle {
      margin-left: 0;
      padding-top: 0.5em;
      margin-bottom: -5px;
    }
  }

  & .ant-steps-item-icon,
  .ant-steps-item::after {
    display: none;
  }

  & .ant-steps-item-process.ant-steps-item-active {
    & .ant-steps-item-title {
      color: var(--primary-6);
      font-weight: 600;
    }
  }
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0;
}

//Input

//Error State for input

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error
  .ant-picker,
.ant-form-item-has-error {
  border-color: var(--red-4) !important;
}

.ant-form-item-explain-error {
  color: var(--red-4) !important;
  font-family: "Poppins", sans-serif !important;
  font-size: var(--size-xxs);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.floating.ant-input,
.floating.ant-picker,
.floating.number-input {
  width: 400px;
  padding: 22px var(--size-xs) 4px var(--size-xs);
  border-radius: 10px;
  border: var(--border-base);
  outline: none;
  background: var(--white);
  color: var(--primary-6);
  font-family: "Poppins", sans-serif !important;
  /*  font-size: 12px; */
  margin-top: 2px;
}

.floating.not-labeled {
  padding: 0 var(--size-xs);
  min-height: 3rem;
  width: 100%;
}

.floating.ant-input:focus,
.floating.ant-input:hover:not([type="number"]),
.ant-picker-focused,
.ant-picker:hover,
.number-input:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border: 1px solid var(--primary-4) !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
}

.modal-floating .ant-picker {
  width: 100% !important;
}

.flex .ant-radio-group.ant-radio-group-outline {
  display: flex;
  flex-direction: column;
  & label {
    width: 400px;
  }
}
.flex.radio {
  & label:first-of-type {
    margin-bottom: 10px;
  }
}

.floating.ant-form-item:hover > div:first-child,
.floating.ant-form-item:focus-within > div:first-child {
  & label {
    color: var(--primary-5) !important;
  }
}

.floating.ant-form-item:focus-within > div:first-child {
  top: 0;
  & label {
    font-size: var(--size-xxs) !important;
  }
}
.form-radio {
  padding: var(--size-s);
  border: var(--border-base);
  border-radius: 8px;
  background-color: var(--white);
  margin-right: 0;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
}

.form-radio span.ant-radio + * {
  padding: 0;
}

.ant-radio-wrapper-checked.form-radio {
  border-color: var(--primary-4);
}

.ant-radio-wrapper-checked.form-radio.expanded {
  align-items: flex-start;
  & .ant-radio {
    top: 0.25em;
  }
}

.ant-radio-wrapper-checked.form-radio.expanded-campaign-form {
  align-items: flex-start;
}

.form-radio .ant-radio + span,
.ant-checkbox + span {
  & h5:not(.videoRadioInnerTitle, .disabledTitle) {
    font-family: "Poppins" !important;
    color: var(--secondary-5) !important;
  }

  & h5 {
    font-weight: 600;
    font-size: var(--size-xs);
    align-items: flex-start;
    line-height: 1.44;
    margin-bottom: 6px;
  }

  & .videoRadioInnerTitle {
    color: var(--secondary-6) !important;
  }
}

.form-radio .ant-radio.ant-radio-checked + span {
  & h5,
  .videoRadioInnerTitle {
    color: var(--primary-4) !important;
  }
}

.floating.inside input {
  width: 22.5rem;
}
// Select
.text-select .ant-select-item.ant-select-item-group {
  padding: 0 8px 8px 10px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  color: var(--secondary-5);
  margin-top: -60px;
  margin-bottom: 1rem;
}
.text-select .ant-select-item-option-content {
  padding-bottom: 60px;
}
.text-select
  .ant-select-item.ant-select-item-option.ant-select-item-option-active:not(
    .ant-select-item-option-selected
  ) {
  background-color: transparent;
  & div {
    color: var(--primary-4) !important;
  }
}
.ant-select-selection-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: var(--secondary-4) !important;
  font-weight: 500;
  font-size: var(--size-xs);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: var(--border-base);
  border-radius: 10px;
  padding: 9px 15px;
  height: auto;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--primary-4);
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--primary-2);
  border-radius: 8px;
  & div {
    transition: 0.3s color ease;
  }

  & div:first-child {
    color: var(--primary-6);
  }
}

.ant-select-item.ant-select-item-option:not(:last-child) {
  margin-bottom: 10px;
}

.ant-select-selector,
.ant-select-selection-item,
.ant-select-item-option-content {
  color: var(--secondary-7);
  font-family: "Poppins", sans-serif !important;
  transition: 0.3s color ease;
}
.ant-select-item {
  padding: 0;
}
.ant-select-item-option-content {
  padding: 10px;
}

.ant-select:not(.language) .ant-select-arrow {
  right: 14px;
}

.ant-select-tree-treenode {
  padding: 10px !important;
  background-color: var(--primary-2);
  margin-bottom: 10px;
  border-radius: 8px;
  justify-content: space-between;
}

.checked {
  border-color: var(--primary-4);
  border-left: 1px solid var(--primary-4) !important;
  color: var(--primary-4) !important;
  z-index: 1;
}
.not-checked,
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.not-checked {
  border: var(--border-base) !important;
  color: rgba(0, 0, 0, 0.85) !important;
  & span {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}

.ant-checkbox-wrapper-checked.form-radio {
  border: 1px solid var(--primary-4);
  & h5 {
    color: var(--primary-4) !important;
  }
}
.ant-select-multiple .ant-select-selection-item {
  background-color: var(--secondary-1);
  border: 1px solid var(--secondary-1);
  border-radius: 12px;
}
.ant-select-multiple {
  & .ant-select-selection-item-remove {
    color: var(--black);
  }
  & .ant-select-selection-item {
    padding: 3px 6px;
    height: auto;
  }
  & .ant-select-selection-item-content {
    color: var(--primary-6) !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--secondary-7);
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  display: flex;
  align-items: center;
}

//Form Item Help Text
.ant-form-item-explain.ant-form-item-explain-connected {
  color: var(--gray-4);
  font-size: var(--size-xxs);
  margin-top: 6px;

  & > div {
    font-family: "Poppins", sans-serif !important;
  }
}
//Edit Button

.ant-typography-edit:not(.title) {
  background-color: var(--primary-4) !important;
  width: var(--size-lg);
  height: var(--size-lg);
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  border-radius: var(--size-radius);
}

.ant-typography.ant-typography-edit-content {
  left: 0;
  padding: 0;
  margin: 0;
  & textarea {
    background: var(--white);
    border: var(--border-base);
    padding: 14px;
    width: 140px;
    border-radius: 8px;
    &:focus {
      box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
        0px 1px 2px rgba(52, 42, 114, 0.08);
    }
  }
}

.ant-typography.ant-typography-edit-content.title {
  margin-bottom: 0.5em;
  & textarea {
    width: 40%;
    padding: 21px 16px;
    font-size: 14px;
  }
}

// Tabs
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: var(--primary-6);
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  border: 1px solid var(--secondary-1);
  background: var(--secondary-1);
  border-radius: 8px;
  padding: 6.5px 8px 0 8px;
  align-items: center;
  margin: 0;
  &::before {
    display: none;
  }
}
.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab {
  padding: 12px 14px;
  margin-bottom: 6.5px !important;
  font-size: var(--size-xxs);
  transition: 0.3s;
  font-weight: 600;
  color: var(--gray-4);

  & svg path {
    fill: var(--secondary-4);
    transition: 0.3s 0.15s;
  }

  &:hover {
    color: var(--primary-4);

    & svg path {
      fill: var(--primary-4);
    }
  }
}
.ant-tabs-tab.ant-tabs-tab-active {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(47, 36, 107, 0.07);
  border-radius: 8px;

  & .ant-tabs-tab-btn {
    color: var(--primary-4);
  }

  & svg path {
    fill: var(--primary-4);
  }
}
.campaign-details-tab > div > div > div > div.ant-tabs-tab:nth-child(4) {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: 0;
    border-radius: 2px;
    left: 0;
    background-color: var(--primary-4);
    transition: width 0.3s linear;
  }
}

/* .campaign-details-tab > div > div > div > div.ant-tabs-tab-active:nth-child(4) {
  height: 100%;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 6px rgba(47, 36, 107, 0.07),
    inset 0px -1px 0px var(--primary-4);
  &::after {
    width: 100%;
    transition: width 0.25s linear;
  }
} */

.two-step-tabs .ant-tabs-nav {
  border-radius: 8px 8px 0 0;
}

.secondary-tab {
  margin-top: -1rem;
  & .ant-tabs-nav {
    margin-bottom: 15px;
  }
  & .ant-tabs-tab {
    font-weight: 400;
  }
  & .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--secondary-1);
  }
  & .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    background: var(--white);
    border: var(--border-base);
    border-radius: 0px 0px 8px 8px;
  }
}
//Select active form row
.form-row {
  transition: 0.3s background;
  & > div:first-child > div > div:first-child {
    transition: 0.3s background;
    & span {
      transition: 0.3s color;
    }
  }
}
.form-row:hover {
  background-color: var(--secondary-1);
  & > div:first-child > div > div:first-child {
    background: var(--primary-6);
    & span {
      color: var(--white);
    }
  }
}

// Cards

.settings .ant-card-meta {
  margin: -24px -24px 24px -24px;
  background-color: var(--gray-10);
  padding: var(--size-lg);
  border-bottom: var(--border-base);
  border-radius: 8px 8px 0 0;
}

//DatePicker

.date-picker-small {
  width: 10.6875rem !important;
  padding: 0 !important;
  & .ant-picker-input input {
    height: 50px;
    padding: 10px var(--size-xs) 0 var(--size-xs);
  }
}

.campaign-date-form .ant-form-item-explain.ant-form-item-explain-connected {
  display: none;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 8px;
  background-color: var(--primary-4);
  padding: 2px 8px;
  height: auto;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-4);
  border-radius: 8px;
}
.ant-picker-today-btn {
  margin: 0.5em 1em;
  width: 30%;
}
.ant-picker-footer-extra .ant-btn {
  margin: 0.5em 0.75em;
}
.ant-picker-today-btn,
.ant-picker-footer-extra .ant-btn {
  font-family: "Poppins", sans-serif !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-1);
  border: 1px solid var(--primary-4);
  color: var(--primary-4);
  font-weight: 600;
  border-radius: 8px;
  height: 30px;
  float: right;
}
.ant-picker-header {
  border-bottom: none;
}
.ant-picker-panel .ant-picker-footer {
  border-top: none;
}
.ant-picker-body {
  border-top: var(--border-base);
  border-bottom: var(--border-base);
}
.ant-picker-panel-container {
  border-radius: 8px;
  border: var(--border-base);
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}
.ant-picker-header-prev-btn {
  justify-content: flex-end;
}
.ant-picker-header-prev-btn,
.ant-picker-header-next-btn {
  display: flex;
  align-items: center;
}

.ant-picker-header-next-btn {
  justify-content: flex-start;
}

// Error Notification

.ant-notification-notice {
  display: flex;
  justify-content: space-between;
}

.ant-notification-notice.ant-notification-notice-closable {
  box-shadow: 0px 8px 20px rgba(223, 60, 109, 0.05);
  border-radius: 10px;
}

.ant-notification-notice.ant-notification-notice-error.ant-notification-notice-closable {
  border: 1px solid var(--red-3);
  background-color: var(--red-2);
}

.ant-notification-notice.ant-notification-notice-success.ant-notification-notice-closable {
  /* Green / 1 */
  background: var(--green-1);
  /* Green / 2 */
  border: var(--green-2);
}

.no-title-notification .ant-notification-notice-message {
  display: none;
}

.ant-notification-notice-message {
  font-weight: 700;
  font-size: 14px;
  margin-left: 14px !important;
  flex-shrink: 0;
  line-height: 1;
}

.ant-notification-notice-content {
  display: flex;
  align-items: center;
}

.ant-notification-notice-error .ant-notification-notice-message {
  color: var(--red-5);
}

.ant-notification-notice-description {
  font-weight: 500;
  font-size: 12px;
}

.ant-notification-notice-close {
  position: relative;
  top: unset;
  right: unset;
}

.ant-notification-notice-with-icon {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.no-title-notification .ant-notification-notice-with-icon {
  flex-wrap: nowrap;
}

.ant-notification-notice-icon {
  position: relative;
  top: 2px;
  margin-left: 0;
  font-size: unset;
  line-height: unset;
}

.no-title-notification .ant-notification-notice-icon {
  top: 2.75px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 30px;
}

.no-title-notification .ant-notification-notice-description {
  margin-left: 14px;
}
// Error States

.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus,
.ant-picker-status-error {
  border: 1px solid var(--red-4) !important;
}

//Drawer

.ant-drawer-header {
  border-bottom: var(--border-base);
}

.ant-drawer-header-title button {
  order: 1;
  margin-right: 0;
}

.ant-drawer-footer {
  border-top: none;
}

//Dragger

.ant-upload.ant-upload-drag.dragger {
  background-color: var(--white);
  border: 1px dashed var(--secondary-3);
  border-radius: 8px;
  padding: 0;
  &:hover {
    border-color: var(--primary-4);
  }
  & .ant-upload-drag-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 160px;
  }
}

// Breadcrumb
.ant-breadcrumb,
.ant-breadcrumb > span:last-of-type {
  display: flex;
}

// reCaptcha
.grecaptcha-badge {
  display: none !important;
}

// Intro

/* .introjs-helperLayer {
  position: fixed !important;
}
 */
.introjs-tooltip {
  border-radius: 12px !important;
}

.introjs-bullets {
  display: flex;
  position: relative;
  padding: 0 !important;

  & ul {
    padding: var(--size-xxxs) var(--size-s) !important;
    background-color: var(--white);
    z-index: 100;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--secondary-2);
    border-radius: 100px;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--secondary-2);
    position: relative;
    top: 15px;
  }

  &::before {
    left: 20px;
  }

  &::after {
    right: 20px;
  }
}

.introjs-tooltipbuttons {
  padding: 18px 20px !important;
  border-top: unset !important;
}

.introjs-button:not(.introjs-hidden) {
  border-radius: var(--size-radius) !important;
  padding: 0.43rem 1rem !important;
  line-height: 1.45;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.introjs-button.introjs-prevbutton,
.introjs-button.introjs-prevbutton:focus,
.introjs-button.introjs-prevbutton:hover {
  background-color: var(--white);
  color: var(--primary-6) !important;
}

.introjs-button.introjs-prevbutton {
  width: 2rem;
  border: var(--border-base) !important;
}

.introjs-button.introjs-nextbutton,
.introjs-button.introjs-nextbutton:hover,
.introjs-button.introjs-nextbutton:focus {
  background-color: var(--primary-4) !important;
  color: var(--white) !important;
  box-shadow: unset;
}

.introjs-button.introjs-nextbutton {
  border: 1px solid var(--primary-4) !important;
  width: 5.5rem;
  text-decoration: unset;
  text-shadow: unset;
  font-size: var(--size-xs);
  font-weight: 600;
}

.introjs-tooltip-header {
  padding-top: 20px !important;
}

.introjs-tooltiptext {
  padding-top: 10px !important;
  color: var(--secondary-5) !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 1.6;
  font-size: 13px;
}

.introjs-tooltip-title {
  color: var(--primary-6) !important;
  line-height: 1 !important;
  font-size: var(--size-s) !important;
  min-height: unset !important;
}

.introjs-arrow {
  border: 10px solid transparent !important;
}

.introjs-arrow.left {
  left: -19px !important;
  top: 15px !important;
  border-right-color: var(--white) !important;
}

.introjs-arrow.top {
  border-bottom-color: var(--white) !important;
  top: -19px !important;
  left: 18px !important;
}

.introjs-arrow.bottom {
  border-top-color: var(--white) !important;
  bottom: -20px !important;
  left: 18px !important;
}

.introjs-skipbutton {
  font-size: 32px !important;
  font-weight: 400 !important;
  color: var(--secondary-7) !important;
  background-color: var(--secondary-1);
  border-radius: 8px;
  top: 20px !important;
  right: 20px !important;
  height: 34px !important;
  width: 34px !important;
  line-height: 0 !important;
  padding-top: 11.5px;
}

.introjs-bullets ul li a {
  background-color: var(--secondary-3) !important;
  width: 8px !important;
  height: 8px !important;
}

.introjs-bullets ul li a.active {
  background-color: var(--secondary-5) !important;
}

.retail-tooltip .ant-tooltip-inner {
  font-family: "Poppins", sans-serif !important;
}

.ant-modal-mask,
.ant-drawer-mask {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
}

.ant-breadcrumb > span {
  display: inline-flex;
  align-items: center;
}

.ant-breadcrumb-separator {
  display: flex;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid var(--secondary-2);
}

.ant-tree-show-line .ant-tree-indent-unit::before {
  border-right: var(--border-base);
}

.ant-notification-notice-message {
  color: var(--green-10);
}

.landingNav {
  width: 100%;
  background-color: #f58220;
  padding: 0 25px;
  position: relative;
  transition: all 0.5s linear;
  z-index: 2;
  & div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.5rem;
    max-width: 1228px;
    margin: 0 auto;
  }
  & img {
    width: 135px;
  }

  & svg {
    fill: var(--white);
    font-size: 22px;
    cursor: pointer;
  }

  & ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    background-color: var(--white);
    width: 100%;
    left: 0;
    top: 5.5rem;
    padding: 1rem;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-100vw);
    transition: all 0.5s linear;
    margin-bottom: 0;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border-top: 1px solid #f1f1f1;
    justify-content: space-between;
  }

  & ul.activeNav {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
  }
}

.landingNav.visible {
  background-color: var(--white);

  svg {
    fill: var(--black);
  }
}

.login,
.contact {
  padding: 14px 24px;
  display: inline-block;
  text-align: center;
  border-radius: 73px;
  font-size: 18px;
  line-height: 1;
  font-family: "Satoshi", sans-serif !important;
  position: relative;
  z-index: 10;
  transition: all 0.3s ease-in;
}

.login {
  background-color: #0074de;
  color: var(--white);
  font-weight: 700;
  width: 9rem;

  &:hover {
    background-color: #0058a9;
    color: var(--white) !important;
  }
}

.contact {
  background-color: #f2f2f2;
  color: var(--black);
  font-weight: 500;

  &:hover {
    color: #0058a9 !important;
  }
}

@media screen and (min-width: 1024px) {
  .landingNav {
    & svg {
      display: none;
    }

    & ul {
      position: relative;
      top: 0;
      opacity: 1;
      pointer-events: all;
      width: 100%;
      transform: translateX(0);
      box-shadow: unset;
      justify-content: flex-end;
      gap: 2rem;
      padding: unset;
      border: unset;
    }
  }

  .landingNav.visible,
  .landingNav ul {
    background-color: #f58220;
  }

  .contact {
    background-color: transparent;
    color: var(--white);
    padding: unset;
  }
}

.select {
  width: 100%;
  & input[type="search"] {
    height: 100% !important;
  }

  & svg path {
    fill: var(--secondary-4) !important;
  }
}

.dropdown {
  border: var(--border-base);
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
  border-radius: 8px;
  padding: 16px;
}

.container {
  position: relative;
}

.input {
  padding-right: 0;
  height: 40px;
}

.input {
  border-radius: var(--size-radius);
  &:focus,
  &:hover {
    box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
      0px 1px 2px rgba(52, 42, 114, 0.08);
    border: 1px solid var(--primary-4);
  }
}

.selectBox {
  background: var(--white);
  padding: 16px;
  border: var(--border-base);
  margin-top: 5px;
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
  border-radius: 8px;
  width: 100%;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 11;

  & span {
    cursor: pointer;
    padding: 10px;
    display: block;
    border-radius: 8px;
    transition: 0.3s background-color;
    &:hover {
      background-color: var(--primary-2);
    }
  }
}

.financial {
  height: 50px;
}

.fullWidth {
  width: 100%;
}

.categoryContainer {
  background-color: white;
  position: absolute;
  z-index: 10;
  border: var(--border-base);
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 360px;
  overflow-y: scroll;
  & span {
    cursor: pointer;
    padding: 10px;
    display: block;
    border-radius: 8px;
    transition: 0.3s background-color;
    &:hover {
      background-color: var(--primary-2);
    }
  }
}

.disabled {
  pointer-events: none;
  text-decoration: line-through;
  color: var(--secondary-5);
}

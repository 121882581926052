.contentContainer {
  padding: 60px 0;
  margin-left: 40px;
  border-bottom: 1px solid var(--secondary-2);
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.active.contentContainer {
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.title,
.text {
  margin: 0;
}

.title {
  color: var(--secondary-6);
  font-size: 24px;
  line-height: 1;
}

.active .title {
  color: var(--primary-6);
}

.text {
  max-width: 862px;
  color: var(--secondary-4);
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
}

.form {
  padding: 14px;
  width: 100%;
  max-width: 360px;
  background-color: var(--white);
  border-radius: 16px;
  border: 1px solid var(--secondary-1);

  & input,
  & select {
    width: 100% !important;
  }
}

.dragger {
  display: flex;
  max-height: 190px;
  margin: 8px 0 4px;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.uploadTitle {
  display: inline-block;
  color: var(--primary-6);
  line-height: 1;
  margin-top: 8px;
}

.uploadText {
  color: var(--secondary-4);
  line-height: 1;
}

.btn {
  width: 100%;
  min-height: 36px;
  margin-top: 10px;
}

.directXmlInput div[class="ant-col ant-form-item-label"] {
  width: calc(100% - 2px);
  margin-left: -13px;
}

.directXmlInput label {
  width: 100%;
  background-color: var(--white) !important;
  top: -1px !important;
  margin-top: 4px;
  text-align: center;
  padding: 0 6px;
  border-radius: 9px;

  &::after {
    display: none;
  }
}

.textarea {
  min-height: 200px !important;
  resize: none;
  /* -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  } */
}

.textareaFilled {
  padding-top: 36px !important;
}

.apiMethod {
  border-bottom: none;
}

@media screen and (min-width: 1280px) {
  .contentContainer {
    display: flex;
    gap: 60px;
  }
}

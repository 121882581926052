.updateBox,
.advertiserBox {
  display: flex;
  align-items: center;
  gap: 0.75em;
  background-color: var(--secondary-1);
  border-radius: 10px;
}

.updateBox {
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 25px;
}

.updateText {
  color: var(--secondary-4);
}

.advertiserBox {
  padding: var(--size-lg) var(--size-md);
  border: var(--border-base);

  & span {
    display: block;
    line-height: 1;
  }

  & svg {
    width: 30px;
    height: 30px;

    & path {
      fill: var(--primary-4);
    }
  }
}

.title {
  color: var(--secondary-6);
  margin-bottom: 8px;
}

.text {
  color: var(--primary-6);
}

.form {
  margin-top: 0.75rem;
}

.form input {
  width: 100% !important;
}

.agencyContainer {
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 46px;
  width: 22px;
  border-right: var(--border-base);
  padding-right: var(--size-xxxs);
  cursor: pointer;

  & path {
    fill: var(--secondary-4);
  }
}

.drawerInfo {
  padding: 0.75rem;
  border-radius: 0.625rem;
  background-color: var(--secondary-1);
  margin-bottom: 1rem;

  & span {
    display: block;
  }

  & span:first-of-type {
    color: var(--secondary-6);
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  & span:last-of-type {
    color: var(--secondary-5);
  }
}

.agencyText {
  display: inline-block;
  color: var(--gray-4);
  margin: 6px 0;
}

.tagContainer {
  border: var(--border-base);
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  max-height: 180px;
  overflow-x: scroll;

  & div:first-of-type {
    padding: 0.625rem;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: var(--white);
  }

  & div:last-of-type {
    padding: 0 0.625rem 0.625rem 0.625rem;
  }
}

.filterBtn,
.clearBtn {
  margin-top: 0.5rem;
  min-height: 2.25rem;
}

.clearBtn svg {
  width: 0.675rem;
  height: 0.675rem;
}

.filterError {
  width: 100%;
  padding-top: 0.625rem;
  color: var(--red-5);
  font-size: var(--size-xxxs);
  font-weight: 500;
  line-height: 1;
}

.noFilterError {
  display: none;
}

.type label:not(:last-of-type) {
  margin-bottom: 10px;
}

.typeTitle {
  margin: 24px 0 4px 0 !important;
}

.subtext {
  color: var(--secondary-5);
  margin-bottom: 1rem;
  display: inline-block;
  line-height: 1.5;
}

.radioTitle {
  margin-bottom: 0 !important;
}

.radioText {
  color: var(--secondary-4);
  margin-top: 6px;
  display: inline-block;
  line-height: 1.5;
}

.generalTitle {
  line-height: 1 !important;
  margin-bottom: 4px !important;
}

.advertiserText {
  color: var(--secondary-5);
}

.advertiserText,
.successText {
  margin-top: 0.25rem;
}

.successText {
  color: var(--secondary-4);
}

.filterContainerTitle {
  font-size: 0.75rem;
  color: var(--primary-4);
}

.filterBtn {
  width: 100%;
}

.addAdvertiserFilterDropdown {
  background-color: var(--white);
  width: 100%;
  min-width: 25rem;
  min-height: 420px;
  max-width: 400px;
  padding: 1.125rem;
  border-radius: 1.25rem;
  /* Popup */
  box-shadow: 0px 2px 2px 0px rgba(31, 24, 69, 0.1),
    0px 2px 15px 0px rgba(31, 24, 69, 0.1);
  position: relative;
  top: 8px;
  right: -4px;

  & .input {
    width: 100%;
    margin-bottom: 0;
  }
}

.filterForm {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  min-height: 328px;
}

.filterTitle {
  width: 100%;
  font-size: 1.125rem !important;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: var(--primary-4);
  margin-bottom: 0.25rem !important;

  & svg {
    width: 0.75rem;
    height: 0.75rem;
    cursor: pointer;

    & path {
      fill: var(--secondary-4);
    }
  }
}

.filterText {
  color: var(--secondary-4);
  display: inline-block;
  line-height: 1.5;
}

.filterFormItem {
  margin-bottom: 0.75rem;
}

.filterFormBtnContainer {
  margin-top: auto;
  display: flex;
  gap: 0.625rem;
}

.cancelFilterBtn,
.addFilterBtn {
  padding: 0.7rem 1rem;
  width: 100%;
  text-align: center;
  line-height: 100%;
  border-radius: 0.625rem;
}

.cancelFilterBtn {
  border: var(--border-base);
  background-color: var(--white);
  max-width: 82px;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03),
    0px 2px 15px 0px rgba(30, 19, 92, 0.03);
  color: var(--secondary-7);
  font-weight: 500;

  &:hover {
    color: var(--primary-4);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  pointer-events: none;
  z-index: 1000;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
}

.overlayVisible {
  opacity: 1;
}

.relative {
  position: relative;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--gray-11);
  border-radius: 50%;
  width: 117px;
  height: 117px;
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  & label {
    & span:last-of-type {
      padding-right: 0;
      padding-left: 0;
    }
    & > span:first-of-type {
      margin-right: 11px;
    }
  }
  & h5 {
    margin-bottom: 0;
  }
}

.radioGroup label {
  width: 13.0625rem;
  align-items: baseline;
  padding: 1.25rem 1.5rem;
}

.amount {
  margin-bottom: 0 !important;
  line-height: 1 !important;
}

.checkbox > span:first-of-type {
  margin-right: 10px;
}

.underlineContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.3em;
}

.underline {
  color: var(--primary-4);
  text-decoration: underline;
  font-size: 15.5px;
  line-height: initial;
}

.radioTitle {
  color: var(--secondary-7) !important;
  font-size: 15px !important;
  margin-bottom: 15px !important;
}

.custom {
  margin-top: 20px;
}

.title {
  color: var(--secondary-7) !important;
}

.advertiserContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  & > div {
    width: 55%;

    & input {
      width: 100% !important;
    }
  }
  & input {
    &::placeholder {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: var(--secondary-4);
    }
  }
}

.subtext {
  width: 100%;
  max-width: 318px;
  margin-bottom: 30px;
  margin-left: auto;
  display: inline-flex;
  gap: 9px;
  & svg,
  & span {
    color: var(--secondary-4);
    max-width: 346.5px;
    line-height: 18px;
    display: inline-block;
  }
}

.subtext.invoiceError {
  & svg,
  span {
    color: var(--red-4);
  }
}

.boxContainer {
  display: flex;

  & div {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: var(--border-base);
    gap: 8px;
  }

  & > div:first-of-type {
    border-radius: 8px 0 0 8px;
    padding: 1.5rem 2.125rem 1.5rem 1.5rem;
  }

  & > div:last-of-type {
    border-radius: 0px 8px 8px 0;
    border-left: none;
    padding: 1.5rem 1.5rem 1.5rem 2.125rem;
  }

  & svg {
    margin-bottom: 10px;
  }

  & .title {
    color: var(--secondary-6) !important;
  }

  & .text {
    font-size: 18px !important;
    line-height: 1;
    color: var(--primary-6) !important;
  }
}

.gray {
  color: var(--secondary-7);
  font-size: var(--size-xs);
  line-height: 1.28;
  gap: 0.625rem;

  & > span:first-of-type {
    top: 0.5rem;
  }
}

.wrapper {
  border: var(--border-base);
  border-radius: 10px;
  margin-bottom: 1.5rem;
}

.main {
  height: 50px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.main:not(:last-of-type) {
  border-bottom: var(--border-base);
}

.main:first-of-type .upperTitle {
  border-radius: 10px 10px 0 0;
}

.active {
  height: 236px;
}

.active .upperTitle {
  background-color: var(--secondary-1);
}

.upperTitle {
  width: 100%;
  padding: 0.75rem 1rem;
  align-items: baseline;
  cursor: pointer;
  & svg {
    width: 14px;
  }
}

.body {
  padding: 1rem 2.75rem;

  & .text,
  .iban {
    color: var(--secondary-4);
  }
}

.iban strong {
  color: var(--secondary-5);
}

.amountBox {
  background-color: var(--secondary-1);
  border-radius: 10px;
  gap: 10px;
  margin-top: -1rem;
  margin-bottom: 1.5rem;
}

.paymentTitle {
  letter-spacing: 0.01em;
  margin-bottom: 1rem !important;
  line-height: 1 !important;
  color: var(--secondary-7) !important;
}

.selectBox {
  gap: 2rem;
}

.selectBox > div > div > div > div {
  color: var(--secondary-4);

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  & svg {
    width: 1.25rem;
  }
}

.copy {
  width: 14px;
  cursor: pointer;
}

.successModal {
  width: 31.75rem !important;

  & > div:nth-child(2) {
    & > div:first-child {
      height: 18.5rem;
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
  }
}

.successWrapper {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.successIcon {
  width: 87px;
  height: 87px;
  border-radius: 50%;
  background-color: var(--green-1);
  justify-content: center;
  margin-bottom: 24px;
  & svg {
    width: 34px;
  }
}

.successTitle {
  color: var(--primary-6) !important;
  margin-bottom: 1rem !important;
}

.successText {
  color: var(--secondary-4);
}

.ok {
  padding: 13px 10px;
  width: 100%;
}

.singleAmountBox {
  width: 100%;
  padding: 1rem;
}

.singleAmountBox:last-of-type {
  border-left: var(--border-base);
}

.amountText {
  color: var(--secondary-5);
  display: block;

  & strong {
    margin-left: 10px;
  }
}

.verifyText {
  color: var(--secondary-6);
  display: block;
}

.iconBox {
  background-color: var(--white);
  border: var(--border-base);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;

  & svg {
    width: 1rem;
    & path {
      fill: var(--secondary-6);
    }
  }
}

.paymentModal > div:nth-child(2) > div:nth-child(3) {
  min-height: 500px;
}

.paymentProductSelect > div > span:last-child {
  max-height: 30px;
}

.productImg {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.productText {
  color: var(--secondary-7);
}

.noteWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 0.4rem;
}

.noteContainer {
  display: flex;
  flex-direction: column;
  max-width: 318px;
  gap: 0.4rem;

  & input {
    width: 100% !important;
  }
}

.invoiceIcon {
  position: absolute;
  top: 20px;
  right: 12px;
}

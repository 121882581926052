.container {
  padding: 60px 25px 100px;

  & > img {
    width: 100px;
  }

  & > h2 {
    font-family: "Satoshi", sans-serif !important;
  }
}

.container,
.container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
  width: 100%;
  flex-wrap: wrap;
}

.container h2 {
  color: #1a1a1a;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.15;

  & span {
    background: -webkit-radial-gradient(#0074de, #3824aa);
    font-family: "Satoshi", sans-serif !important;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.card {
  padding: 1.875rem;
  border-radius: 1.7rem;
  border: 0.75px solid #e1e1e1;
  background: linear-gradient(180deg, #f8f8f8 0%, #fff 100%);
  box-shadow: 0px 12.75px 22.5px 0px rgba(0, 0, 0, 0.05),
    0px 1.5px 0px 0px #fff inset;
  max-width: 550px;

  & h3 {
    color: #1a1a1a;
    font-size: 1.25rem;
    font-family: "Satoshi", sans-serif !important;
    font-weight: 700;
    line-height: 1.15;
    margin-bottom: 1rem;
  }

  & p {
    color: #888;
    font-size: var(--size-xxs);
    font-family: "Inter", sans-serif !important;
    line-height: 1.65;
    margin-bottom: 1.25rem;
  }
}

.cardIcon {
  width: 40px;
  margin-bottom: 1.7rem;
}

.mainImg {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .container {
    gap: 0;
    padding: 60px 25px 122px;
  }

  .container > img {
    margin: -90px 0 3.75rem;
    z-index: 1;
  }

  .container h2 {
    font-size: 3rem;
    margin-bottom: 6.75rem;
    width: 556px;
    padding: 0 4.2rem;
    position: relative;
  }

  .container > div {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 3.125rem;
    max-width: 1326px;
  }

  .cardIcon {
    width: 70px;
    margin-bottom: 2rem;
  }

  .card {
    padding: 3.75rem;
    z-index: 1;
    max-width: 668px;
    margin: 0 auto;

    & h3 {
      font-size: 2.125rem;
      margin-bottom: 1.25rem;
    }

    & p {
      font-size: 1.125rem;
      margin-bottom: 2.75rem;
    }
  }

  .container h2::before {
    content: "";
    position: absolute;
    width: 100%;
    top: -94px;
    left: 0;
    height: 450px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6D6D6FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    z-index: -1;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    padding: 3.75rem 0 10rem;
    background-image: url("../../../../../assets/images/landing_page/mid.png");
    background-size: 100%;
    background-position: center 976px;
    background-repeat: no-repeat;
    margin: 0 auto;
    max-width: 1228px;
  }

  .container h2 {
    width: 700px;
    padding: 1.85rem 4.5rem 0;
  }

  .container h2:after {
    content: "";
    position: absolute;
    background: var(--white);
    top: 275px;
    height: 100px;
    left: 0;
    width: 100%;
  }

  .container > div {
    gap: 5rem;
  }

  .card {
    width: 570px;
  }
}

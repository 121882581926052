.link {
  font-weight: 500;
  color: var(--primary-4);
  font-size: var(--size-xs);
}

.tableLink {
  font-family: "Poppins", sans-serif !important;
  display: block;
  min-width: 175px;
  &:hover {
    color: var(--primary-4) !important;
  }
}

.formLink {
  text-decoration: underline;
  margin: 1rem 0 2.25rem;
}

.linkUp {
  margin-left: 6px;
}

.iconContainer {
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
}

.editContainer {
  background-color: var(--primary-2);
  width: 20px;
  height: 20px;
  margin-left: -0.25rem;
}

.tooltipContainer,
.noteContainer {
  display: flex;
  flex-direction: column;

  & span {
    display: block;
    width: 100%;
  }
}

.noteContainer {
  gap: 2px;
}

.noteTitle {
  font-weight: 600;
}

.reverse {
  flex-direction: row-reverse;
}
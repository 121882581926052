.container {
  width: 100%;
  min-height: 44px;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.btnContainer {
  display: flex;
  gap: 1rem;
}

.stopBtn {
  background-color: var(--red-5);
  margin-left: auto;
}

.activeBtn {
  background-color: var(--green-10);
}

.activeBtn,
.stopBtn {
  color: white;
}

.archiveBtn {
  color: var(--red-5);
  border: 1.5px solid var(--red-5);
}

.listBtn {
  color: var(--green-10);
  border: 1.5px solid var(--green-10);
}

.archiveBtn,
.listBtn {
  background-color: transparent;
}

.btn {
  height: 2.625rem;
  width: 12.1875rem;
  padding: 0;
  font-weight: 600;
  border-radius: var(--size-radius);
  transition: 0.3s opacity ease;
  &:hover {
    opacity: 0.75;
  }
}

.editBtn,
.directUpdateBtn {
  background-color: var(--white);
  color: var(--secondary-7);
  border: var(--border-base);
  min-width: min-content;
  font-weight: 500;

  & svg {
    width: 1rem;
    height: 1rem;
  }

  & svg path {
    fill: var(--secondary-7);
  }
}

.editBtn {
  width: max-content;
  padding: 0.75rem 1.125rem;
}

.bidCol {
  padding: 0.375rem;
  width: 100%;
  border-radius: 0.875rem;
  border: 1px solid var(--secondary-2);
  line-height: 1;

  & p {
    color: var(--secondary-4);
    font-size: var(--size-xxxs);
    font-weight: 500;
    margin-bottom: 0;
  }
}

.directUpdateBtn {
  border-radius: 0.625rem;
  padding: 0.4rem 0.625rem;
}

.text {
  font-size: var(--size-xxs);
  color: var(--secondary-4);
  line-height: 1;
  margin: -1.5rem 0 1.5rem;
  gap: 0.25rem;
}

.underlined {
  text-decoration: underline;
  color: var(--primary-4);
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.error,
.error:hover {
  border: 1px solid var(--red-4) !important;
}

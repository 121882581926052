.iconBtn {
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  position: relative;
}

.delete {
  color: var(--red-5);
}

.justDelete {
  border-radius: var(--size-radius);
  padding: 5px;
  margin-top: 20px;
  justify-content: center;
  cursor: pointer;
}

.justDelete,
.invalidTag {
  background-color: var(--red-5);
  & span {
    color: var(--white);
  }
}

.invalidTag {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0px;
  padding: 2px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}

.languageActive {
  border: 1.5px solid var(--primary-3);
  background: var(--primary-1);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);

  & svg path:first-of-type {
    fill: var(--primary-4);
  }

  & svg path:last-of-type {
    fill: var(--primary-7);
  }
}

.languageContainer {
  position: absolute;
  top: -8px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  background: var(--primary-6);
  border-radius: 13px;
  min-width: 20px;
  width: max-content;

  & span {
    font-size: 11px;
    color: var(--white);
    line-height: 1;
  }
}

.sider {
  border-right: var(--border-base);
  background-color: var(--white);
  border: none;
  margin-top: 60px;

  & > div {
    position: fixed;
    top: 0;
    padding-top: 60px;
    overflow-y: auto;
  }
}

.userSider > div {
  width: 80px;
}

.adminSider > div {
  width: 230px;
}

.menu {
  height: 100%;
  & span > a > span > svg {
    font-size: var(--size-lg);
  }
  & li:nth-of-type(3) {
    display: none;
  }
}

.listItem,
.subMenu {
  margin-bottom: 26px !important;
  line-height: 56px !important;
  padding-left: 0 !important;
}

.subMenu div {
  padding-left: var(--size-md) !important;
}

.listItem,
.subMenu {
  & span,
  a {
    display: flex;
    align-items: center;
  }

  & a {
    margin-left: var(--size-md);
    justify-content: center;
    border-radius: 8px;
    transition: 0.5s background-color;
  }

  & svg path {
    transition: 0.5s fill;
    fill: var(--primary-6);
  }

  &:hover {
    & svg path {
      fill: var(--primary-4);
    }
    & span:first-of-type {
      color: var(--primary-4);
    }
  }
}

.listItem:not(.selected),
.subMenu:not(.selected) {
  & a {
    color: var(--primary-6);
  }
}

.admin a {
  gap: 10px;
}

.user a {
  width: 2.25rem;
  height: 2.25rem;
}

.listItem:hover a {
  color: var(--primary-4) !important;
}

.listItem:not(.subMenu.admin .listItem):first-of-type {
  margin-top: 20px !important;
}

.listItem::after {
  border-left: 2px solid var(--primary-6);
  border-right: 0 !important;
}

.listItem.admin::after {
  height: 24px;
}

.listItem:not(.subMenu.admin .listItem)::after {
  left: 0;
}

.subMenu.admin .listItem::after {
  top: 8px !important;
  height: 24px;
}

.listItem.selected::after {
  opacity: 1;
  transform: unset;
}

.listItem.selected {
  font-weight: 600;
}

.listItem.admin,
.subMenu.admin > div {
  height: 26px !important;

  & svg {
    width: 22px;
  }
}

.listItem.admin {
  line-height: 24px !important;
  margin-bottom: 34px !important;
  & svg {
    width: 22px;
  }
}

.listItem.admin:active {
  background: transparent;
}

.subMenu.admin .listItem {
  margin-bottom: 6px !important;
}

.subMenu .listItem:last-of-type {
  margin-bottom: -10px !important;
}

.subMenu.admin .listItem.selected {
  background-color: var(--primary-2);
}

.advertiserMenu {
  position: relative;
  top: 66px;
  height: calc(100% - 66px);
}

.advertiserMenuWithDocs {
  position: relative;
  top: 104px;
  height: calc(100% - 104px);
}

.justDocs {
  position: relative;
  top: 38px;
  height: calc(100% - 38px);
}

.contact {
  width: 44px !important;
  height: 44px !important;
  border-radius: 50%;
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
  padding: 0 !important;
  position: absolute !important;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;

  & span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border: 1px solid var(--primary-3);
    box-shadow: 0px 1px 2px 0px rgba(52, 42, 114, 0.08),
      0px 4px 8px 0px rgba(52, 42, 114, 0.08);

    & svg path {
      fill: var(--primary-4);
    }
  }
}

.console::before {
  content: "BETA";
  position: absolute;
  top: 0;
  right: 16px;
  width: 28px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--secondary-2);
  color: var(--secondary-4);
  font-size: 8px;
  font-weight: 600;
  line-height: 1;
}

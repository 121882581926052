.container {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 1000;
}

.container.light {
  background-color: var(--secondary-1);

  & svg path {
    fill: var(--secondary-5);
  }
}

.container.dark {
  background-color: var(--secondary-7);

  & svg path {
    fill: var(--white);
  }
}

.link {
  font-size: 13px;
  font-weight: 500;
  line-height: 0.92;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.link.light {
  color: var(--secondary-5);

  &:hover {
    color: var(--secondary-5) !important;
  }
}

.link.dark {
  color: var(--white);

  &:hover {
    color: var(--white) !important;
  }
}

.closeIcon {
  position: absolute;
  right: 24px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

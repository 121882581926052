.container {
  max-width: 1228px;
  margin: 8.75rem auto;

  & h2,
  h3,
  h4 {
    font-family: "Satoshi", sans-serif !important;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.textContainer,
.cardContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.textContainer {
  margin-bottom: 5rem;

  & h2,
  h3,
  p {
    line-height: 1.35;
    margin-bottom: 0;
  }

  & h2 {
    color: #3ab078;
    font-size: 1.125rem;
  }

  & h3 {
    font-size: 3rem;
    line-height: 1;
    margin-top: 1rem;
  }

  & p {
    color: #a8a8a8;
    font-size: 0.875rem;
    max-width: 404px;
  }
}

.card {
  max-width: 580px;
  width: 100%;
}

.cardHeader {
  position: relative;
  background-color: black;
  border-radius: 2rem;
}

.cardHeader img {
  width: 100%;
  height: 290px;
  object-fit: cover;
  aspect-ratio: 16/9;
  border-radius: 2rem;
  opacity: 0.7;
}

.cardHeaderText {
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;

  & h4 {
    font-size: 1.5rem;
    line-height: 1.2;
    color: white;
    max-width: 400px;
  }
}

.cardHeaderTag {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  color: white;
  padding: 0.625rem;
  width: fit-content;
  background: linear-gradient(
    142deg,
    rgba(255, 255, 255, 0.2) 1.24%,
    rgba(255, 255, 255, 0.12) 96.91%
  );
  backdrop-filter: blur(8px);
  border: 0.8px solid white;
  border-radius: 1rem;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}

.cardInfo {
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.cardInfoBrand,
.cardInfoText,
.cardInfoLink {
  font-family: "Satoshi", sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  margin-bottom: 0;
}

.cardInfoBrand,
.cardInfoText {
  border: 1px solid #e9e9e9;
}

.cardInfoBrand {
  color: #838383;
}

.cardInfoText {
  color: #c4c4c4;
}

.cardInfoLink {
  min-width: 8rem;
  background-color: #0074de;
  color: white;
  margin-left: auto;
  text-align: center;
  transition: background-color 0.3s ease-in;

  &:hover {
    background-color: #0058a9;
    color: white !important;
  }
}

@media screen and (min-width: 768px) {
  .textContainer,
  .cardContainer {
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1280px) {
  .textContainer,
  .cardContainer {
    width: 100%;
  }

  .cardContainer {
    gap: 3.75rem;
  }
}

.wrapper {
  width: 100%;
  background-color: var(--secondary-1);
  min-height: 100vh;
  padding: 14px 0 14px 14px;
  display: none;
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: flex;
  }
}

.container {
  width: 100%;
  min-height: 2.75rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  gap: 1rem;
}

.tag {
  color: var(--primary-4);
  padding: 0.375rem;
  border-radius: var(--size-radius);
  background-color: var(--primary-2);

  & span {
    color: var(--primary-4);
  }
}

.btn {
  width: 12.1875rem;
  height: 2.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.archive {
  background-color: var(--white);
  border: var(--border-base);
  border-radius: var(--size-radius);
  margin-left: auto;
}

.reinvite {
  width: 15.3125rem;
  gap: 0.5rem;
  color: var(--secondary-7);

  & svg {
    width: 1rem;

    & path {
      fill: var(--secondary-7);
    }
  }
}

.container {
  width: 50%;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--secondary-1);
  background: var(--white);
  box-shadow: 0px 82px 23px 0px rgba(0, 0, 0, 0),
    0px 53px 21px 0px rgba(0, 0, 0, 0), 0px 30px 18px 0px rgba(0, 0, 0, 0.01),
    0px 13px 13px 0px rgba(0, 0, 0, 0.02), 0px 3px 7px 0px rgba(0, 0, 0, 0.02);
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 480px;
  margin: auto;
}

.firstLine {
  display: flex;
  gap: 4px;

  & span {
    color: var(--primary-6);
    font-size: 24px;
    line-height: 1.9;
  }

  & .greenText {
    color: var(--green-10);
    position: relative;
  }
}

.wavyUnderline {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 10px;
}

.secondLine {
  color: var(--primary-6);
  font-size: 24px;
  line-height: 1.9;
  margin-bottom: 40px;
}

.mainText {
  color: var(--secondary-4);
  line-height: 1.42;
  margin-bottom: 40px;
}

.linkedinBtn {
  min-height: 52px;
  justify-content: space-between;
  gap: 10px;

  & span {
    margin-right: auto;
  }
}

.loginTextContainer {
  margin-top: 26px;
  text-align: center;
}

.loginText,
.termsText {
  color: var(--secondary-4);
  line-height: 1;
}

.loginLink {
  color: var(--primary-4);
  font-weight: 500;
  text-decoration: underline;
  margin-left: 8px;
  font-size: 13px;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &:hover {
    color: var(--primary-6) !important;
  }

  &:active,
  &:focus {
    color: var(--primary-5) !important;
  }
}

.termsText {
  margin-bottom: 30px;

  & a,
  a:visited,
  a:hover {
    color: var(--secondary-4) !important;
    text-decoration: underline;
  }
}

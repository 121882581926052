.justifyContent {
  justify-content: space-between;
}

.title {
  margin-bottom: 0.5rem !important;
  color: var(--primary-4) !important;
  line-height: 16px !important;
}

.text {
  color: var(--secondary-5);
  display: block;
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 12px;
}

.container + .container {
  margin-top: 1rem;
}

.container:last-of-type {
  margin-bottom: 36px;
}

.flex {
  display: flex;
  gap: 4rem;
  align-items: flex-start;
}

.text {
  max-width: 400px;
  line-height: 24px;
}

.couponContainer {
  align-items: flex-start;
}

.select {
  width: 300px !important;
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.hidePrefix,
.showPrefix {
  & span:not(span[class="ant-input-prefix"]) {
    width: 300px;
  }
}

.baseline {
  align-items: baseline;
}

.couponText span {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
}

.btn {
  height: auto;
  min-height: 2.625rem;
  padding: 0 1.25rem;
  border-radius: 0.625rem;
  font-size: var(--size-xs);
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  & svg path {
    transition: fill 0.3s;
  }
}

.btn:disabled svg path,
.btn:disabled svg path:hover {
  fill: var(--secondary-3);
}

.btn:disabled,
.btn:disabled:hover {
  background-color: var(--secondary-1) !important;
  color: var(--secondary-3);
}

.hasBackground,
.hasBackground:focus {
  background-color: var(--primary-4);
  border: none;
  outline: none;
  color: var(--white);
  font-weight: 600;
}

.hasBackground:focus {
  box-shadow: 0px 0px 0px 3px var(--white), 0px 0px 0px 4.5px var(--primary-2);
}

.hasBackground:hover,
.hasBackground:active {
  background-color: var(--primary-5) !important;
  color: var(--white);

  & a {
    color: inherit !important;
  }
}

.hasBackground:active {
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.15),
    0px 0px 0px 3px var(--white), 0px 0px 0px 4.5px var(--primary-2) !important;
}

.noBackground {
  color: var(--secondary-7);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  font-weight: 500;
}

.noBackground,
.noBackground:focus,
.noBackground:active,
.noBackground:hover {
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
}

.noBackground:hover {
  color: var(--secondary-7);
  box-shadow: 0px 17px 5px 0px rgba(0, 0, 0, 0),
    0px 11px 4px 0px rgba(0, 0, 0, 0), 0px 6px 4px 0px rgba(0, 0, 0, 0.02),
    0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}

.noBackground:focus {
  color: var(--primary-4);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03),
    0px 0px 0px 3px var(--white), 0px 0px 0px 4px var(--secondary-1);

  & svg path {
    fill: var(--primary-4);
  }
}

.noBackground:active {
  color: var(--primary-6);
  box-shadow: 0px 2px 2px 0px rgba(101, 79, 228, 0.04),
    0px 0px 0px 3px var(--white), 0px 0px 0px 4px var(--primary-1) !important;

  & svg path {
    fill: var(--primary-6);
  }
}

.primaryBorder {
  font-weight: 600;
  color: var(--primary-4);
  border: 1.5px solid var(--primary-4);

  & svg path {
    fill: var(--primary-4);
  }
}

.primaryBorder:hover {
  border-color: var(--primary-3);
  background-color: var(--primary-2);
  box-shadow: 0px 2px 2px 0px rgba(101, 79, 228, 0.05);
}

.primaryBorder:hover,
.primaryBorder:focus {
  color: var(--primary-5);

  & svg path {
    fill: var(--primary-5);
  }
}

.primaryBorder:focus,
.primaryBorder:active {
  border-color: var(--primary-7);
  box-shadow: 0px 2px 2px 0px rgba(75, 56, 186, 0.05),
    0px 0px 0px 3px var(--white), 0px 0px 0px 4px var(--primary-2);
}

.primaryBorder:active {
  color: var(--primary-7);

  & svg path {
    fill: var(--primary-7);
  }
}

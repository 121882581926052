.font-xxxs {
  font-size: var(--size-xxxs);
}
.font-xxs {
  font-size: var(--size-xxs);
}
.font-xs {
  font-size: var(--size-xs);
}
.font-s {
  font-size: var(--size-s);
}
.font-md {
  font-size: var(--size-md);
}
.font-lg {
  font-size: var(--size-lg);
}
.font-xl {
  font-size: var(--size-xl);
}
.font-xxl {
  font-size: var(--size-xxl);
}
.font-light {
  font-weight: 300 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-bold {
  font-weight: 600 !important;
}
.font-eudoxus {
  font-family: "Eudoxus", sans-serif !important;
}
.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

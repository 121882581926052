.contentContainer {
  padding-bottom: 60px;
  margin-left: 40px;
  border-bottom: 1px solid var(--secondary-2);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.title,
.text {
  margin: 0;
}

.title {
  color: var(--primary-6);
  font-size: 24px;
  line-height: 1;
}

.text {
  max-width: 862px;
  color: var(--secondary-4);
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
}

.btn {
  min-width: 200px;
  margin-top: 10px;
}

.how {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--primary-4);
  margin-bottom: 6px;
  cursor: pointer;

  & svg path {
    fill: var(--primary-4);
  }
}

.fieldContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-width: 360px;
  max-height: 286px;
  background-color: var(--white);
  border: 1px solid var(--secondary-1);
  border-radius: 10px;
  overflow-y: hidden;
}

.openFields {
  max-height: 100%;
}

.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 12px 12px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-1);
  background-color: var(--secondary-1);
  cursor: pointer;
  margin-top: -20px;

  & svg {
    width: 12px;
    transition: transform 0.3s ease;

    & path {
      fill: var(--secondary-6);
    }
  }
}

.showText {
  color: var(--secondary-6);
  line-height: 1.16;
  letter-spacing: -0.12px;
}

.iconUp {
  transform: rotate(180deg);
}

@media screen and (min-width: 1280px) {
  .contentContainer {
    display: flex;
    gap: 60px;
  }
}

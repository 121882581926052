.innerVideo {
  padding: 10px;
  border-radius: 12px;
  border: 1px solid var(--secondary-2);
  background: var(--white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  & section {
    position: relative;
    z-index: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 335px;
    border-radius: 8px;
    background: radial-gradient(
        ellipse at center,
        rgba(139, 152, 174, 0) 0%,
        rgba(139, 152, 174, 0.07) 100%
      ),
      var(--secondary-1);

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/icons/preCreativeBg.svg");
      background-position: center;
      border-radius: 8px;
      inset: 0;
      opacity: 0.6;
      z-index: -1;
    }

    & h4 {
      color: var(--secondary-6) !important;
      font-size: 22px;
      line-height: 0.64 !important;
    }

    & span {
      color: var(--secondary-4);
      margin-top: 6px;
    }
  }
}

.innerVideoIcon {
  width: 84px;
  height: 84px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 22px;
  margin-bottom: 22px;

  &::before {
    content: "";
    position: absolute;
    width: 98px;
    height: 98px;
    background: linear-gradient(
      to top left,
      #f5f7f9 0%,
      #d3d7e3 47%,
      #f5f7f9 100%
    );
    border-radius: 26px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    box-shadow: 0px 39px 11px 0px rgba(45, 55, 73, 0),
      0px 25px 10px 0px rgba(45, 55, 73, 0.01),
      0px 14px 8px 0px rgba(45, 55, 73, 0.03),
      0px 6px 6px 0px rgba(45, 55, 73, 0.04),
      0px 2px 3px 0px rgba(45, 55, 73, 0.05);
  }
}

.export > div > div:first-of-type {
  display: none;
}

.export > div > div:last-child {
  border: var(--border-base);
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 0.65rem;
}

.exportFormats span {
  padding: 10px;
  display: block;
  color: var(--secondary-7);
  line-height: 1;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--primary-4);
  }
}

.exportFormats span:not(:last-of-type) {
  margin-bottom: 10px;
}

.iconContainer {
  position: absolute;
  bottom: 6px;
  cursor: pointer;
}

.text {
  color: var(--primary-4);
}

.warningModal {
  width: 90% !important;
  max-width: 400px;

  & span[class="ant-modal-close-x"] svg path {
    fill: var(--secondary-3);
  }

  & div[class="ant-modal-body"] {
    padding: 18px;
    margin-bottom: 2px;
  }
}

.warningContainer {
  flex-direction: column;
  gap: 24px;
  text-align: center;
  padding: 20px 0;
}

.warningIcon {
  width: 86px;
  height: 86px;

  & circle {
    fill: var(--yellow-2);
  }

  & path {
    fill: var(--yellow-6);
    transform: scale(0.75);
    transform-origin: center;
  }
}

.btnContainer {
  width: 100%;
  justify-content: space-between;

  & button {
    width: 100%;
  }
}

.cancelBtn {
  max-width: 120px;
}

.okBtn {
  max-width: 234px;
}

.warningTitle {
  font-size: 24px !important;
  line-height: 1.25 !important;
  max-width: 340px;
}

.warningText {
  color: var(--secondary-4);
  line-height: 1;
}

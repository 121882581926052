.font-eudoxus {
  font-family: "Eudoxus", sans-serif !important;
}

.font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.noMargin {
  margin: 0 !important;
  line-height: 1 !important;
}
.upload {
  margin-bottom: 1rem;
}

.creativeText {
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.empty,
.creativeSub {
  margin: 1.5rem auto;
}

.creatives {
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.creativeContainer {
  width: 28.5rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: var(--border-base);
  gap: 0;

  & > div {
    width: 50%;
    padding: var(--size-xxxs);
  }

  & > div:first-of-type {
    border-radius: 12px;
    background-color: var(--secondary-1);
    justify-content: center;
  }

  & img {
    max-width: 208px;
    height: 140px;
    object-fit: contain;
  }

  & input {
    width: 12.75rem !important;
  }
}

.size {
  color: var(--secondary-5);
}

.iconBtn {
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--white);
  border: var(--border-base);
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

.creativeContainerText {
  margin-top: 10px;
}

.delete {
  color: var(--red-5);
}

.info {
  border: 1px solid var(--secondary-1);
  background: var(--secondary-1);
  margin-top: -1.5rem;
  padding: 1rem 14px;
  margin-bottom: 1rem;
  border-radius: 0px 0px 8px 8px;
  flex-direction: column;
}

.infoTitle {
  color: var(--secondary-6) !important;
  letter-spacing: 0.44px;
  line-height: 24px;
}

.infoIcon {
  color: black !important;
  margin-left: 6px;
}

.infoText {
  color: var(--secondary-5);
  line-height: 20px;
}

.validError {
  border: 2px solid var(--red-5);
  background-color: #ffeff9;
  position: relative;
}

.justDelete {
  border-radius: var(--size-radius);
  padding: 5px;
  margin-top: 20px;
  justify-content: center;
  cursor: pointer;
}

.justDelete,
.invalidTag {
  background-color: var(--red-5);
  & span {
    color: var(--white);
  }
}

.invalidTag {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px 0px;
  padding: 2px 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}

.sizeContainer {
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.sizeTag {
  background-color: var(--gold-1);
  color: var(--main-purple) !important;
  border-radius: var(--size-radius);
  padding: 4px 6px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline;
}

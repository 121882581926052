.btn {
  width: 28px;
  height: 28px;
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  flex-shrink: 0;

  & svg {
    width: 14px;
    & path {
      fill: var(--secondary-7);
    }
  }
}

.container {
  position: absolute;
  right: 6px;
  bottom: 6px;
  padding: 6px 8px;
  justify-content: center;
  background-color: var(--secondary-4);
  border: 1px solid var(--secondary-4);
  cursor: pointer;
  transition: all 0.2s ease;

  & span {
    color: white;
    line-height: 1;
    text-transform: uppercase;
  }
}

.videoPlayContainer {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.25) 100%
    ),
    linear-gradient(
      324deg,
      rgba(255, 255, 255, 0.3) 9.92%,
      rgba(255, 255, 255, 0.15) 95.32%
    );
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);

  & span {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
}

.inspectIcon {
  min-width: 76px;
  min-height: 32px;
  border-radius: 8px;
  gap: 10px;
  right: 10px;
  bottom: 10px;

  & svg {
    width: 16px;
    height: 16px;
  }
}

.formIcon {
  padding: 6px;
  height: 24px;
  border-radius: 6px;
  gap: 4px;

  & svg {
    width: 10px;
    height: 10px;
  }
}

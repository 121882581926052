.cardContainer {
  border: var(--border-base);
  border-radius: 8px;
  width: 100%;
  & > div:first-child {
    padding: 1rem;
  }
}

.cardContainer ul {
  background-color: var(--secondary-1);
  transition: 0.3s ease-in background-color;
  border-radius: 0 0 8px 8px;
  & li {
    margin: 5px 0;
  }
}

.selected ul {
  background-color: var(--primary-4);
  & .btn {
    color: var(--secondary-8);
  }
}
.btn {
  color: var(--gray-5);
  font-weight: 700;
  width: 100%;
  display: inline-block;
}

.up,
.down,
.percentage {
  font-size: var(--size-xxs);
  color: var(--primary-6);
}

.title {
  margin-right: 5px;
  color: var(--secondary-5);
  text-transform: capitalize;
}

.meta {
  display: flex;
  align-items: center;
  & span:last-child {
    font-family: "Poppins", sans-serif !important;
  }
}

.percentContainer {
  gap: 3px;
  padding: 2px 6px;
  border-radius: 5px;
  background-color: var(--primary-2);
}

.secondTitle {
  color: black !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.relative {
  position: relative;
}

.advertiser {
  margin-top: calc(4rem + 66px) !important;
}

.currentSubtitle {
  color: var(--primary-4);
  font-size: var(--size-xxs);
}

.finishedSubtitle,
.success {
  color: var(--green-10);
}

.error {
  color: var(--red-4) !important;
}

.errorBorder {
  background-color: var(--red-1);
  border: 1px solid var(--red-5) !important;
  position: relative;
}

.errorIcon {
  right: -86%;
}

.errorIcon,
.errorIconTotal {
  position: relative;
  top: -10px;
  pointer-events: none;
}

.errorIconTotal {
  right: -78%;
}
/* .errorBorder:not(.startDate)::after {
  content: "";
  display: block;
  background: url("../../../assets/images/inputWarning.svg") no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  right: 32px;
  z-index: 10;
  pointer-events: none;
} */

.radioText {
  color: var(--secondary-4);
  max-width: 349px;
  display: inline-block;
  line-height: 1.5;
}

.formInput {
  position: absolute;
  bottom: 2.5em;
  left: 2.5em;
}
.column {
  flex-direction: column;
  flex-flow: column;
}
.radioGroup {
  flex-direction: row;
  margin-top: 1em;
}

.dynamicBidRadio {
  width: 11.25rem !important;
  padding: 9px 15px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  & span {
    font-family: "Poppins", sans-serif !important;
  }
}
.standard {
  border-radius: 6px 0px 0px 6px !important;
}
.advanced {
  border-radius: 0 6px 6px 0 !important;
}

.dynamicText {
  color: var(--gray-4);
  max-width: 320px;
  display: inline-block;
}
.autoHeight {
  height: auto;
}

.fixedHeight {
  height: 15.5rem;
}
.fixedHeightKeyword {
  height: 13rem;
}
.fixedHeightKeywordEn {
  height: 11.5rem;
}
.bid,
.bidEn {
  position: absolute;
  right: 3.4rem;
}
.bidEn {
  bottom: 10.5rem;
}
.bid {
  bottom: 12rem;
}

.marginTop {
  margin-top: 10px;
}

.acos,
.fixedBid {
  position: relative;
  margin-top: 20px;
  transition: color 0.3s ease;
}
.suggestedText {
  position: absolute;
  top: 158px;
  left: 20px;
}

.acos div[role="role"] {
  height: 10px;
  min-height: unset;
}

.selectedTitle {
  color: var(--primary-6) !important;
}

.selectedText {
  color: var(--gray-3);
  margin: var(--size-xs) 0 var(--size-lg) 0;
  display: inline-block;
}

.keywords {
  position: relative;
}
.unroundedKeywords:hover {
  border-radius: 12px 12px 0 0;
}

.keywords:hover + div {
  border-radius: 0 0 12px 12px;
}

.notAutoKeyword {
  background: var(--secondary-8);
  z-index: 100;
  border: 1px solid var(--secondary-2);
  border-radius: 12px;
  flex-direction: column;
  margin-top: 0;
  transition: border-radius 0.15s ease-in-out;
}

.negativeTitle {
  margin: 36px 0;
}

.placement,
.placementCheckbox,
.keywordsRadio {
  width: 400px;
}

.placementCheckbox {
  margin-bottom: 10px;
  & span {
    font-size: var(--size-xxs) !important;
    font-weight: 400 !important;
  }
}

.suggestedTag span {
  font-weight: 600 !important;
}

.placementRow {
  justify-content: space-between;
}
.placementRow,
.dateRow {
  flex-flow: row;
}
.keywordsRadio {
  margin-left: 0 !important;
  & span {
    font-size: var(--size-xxs) !important;
    font-weight: 400 !important;
  }
}
.total {
  height: 9rem;
}

.marginBottom {
  margin-bottom: 10px;
}

.audienceRow {
  align-items: flex-start;
  &:hover + div {
    border-radius: 0;
  }
}

.targetingRow {
  background: var(--secondary-8);
  border: 1px solid var(--secondary-2);
  position: relative;
  border-radius: 0;
  margin-top: -1.875rem;
  & div:not([class="ant-col"]) {
    margin-bottom: 0;
  }
  & h4 {
    font-size: var(--size-s);
    color: var(--secondary-6) !important;
  }
}

.firstBorder {
  border-radius: 12px 12px 0 0;
  margin-top: -2.5rem;
}
.lastBorder {
  border-radius: 0 0 12px 12px;
}

.platformContainer {
  width: 400px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ageContainer,
.targetingCities,
.targetingGender {
  width: 25rem;
}

.ageContainer {
  flex-wrap: wrap;
  justify-content: space-between;
}
.targetingPlatform,
.targetingAge,
.targetingGender,
.placementFormItemCheckbox {
  margin-left: 0 !important;
  align-items: baseline;
  min-height: 0;
}

.targetingPlatform {
  width: 11.875rem;
}

.targetingAge {
  width: 126px;
  padding: var(--size-s);
}

.targetingCities h5 {
  margin-bottom: 0 !important;
}

.citiesCol .targetingCities > span:first-of-type {
  left: -36px;
}

.selectedCities {
  border: none;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  min-height: 2.5rem;
}

.citySelect div[class="ant-select-selection-overflow"] {
  left: -8px;
}

.citySelect div[class="ant-select-selector"] {
  padding: 6px 12px !important;
}
.citySelect span[class="ant-select-selection-item"] {
  padding: 6px 12px;
  border-radius: 50px;
}
.citySelect span[class="ant-select-selection-item-remove"] {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.citiesCol {
  border: 1px solid var(--primary-4);
  border-radius: 8px;
  padding: var(--size-md);
  max-width: 25rem;
  background-color: var(--white);
}

.footerDivider,
.footer {
  max-width: 1000px;
}

.footerDivider {
  min-width: 0;
  margin: 36px auto;
}

.footer {
  justify-content: space-between;
  margin: 0 auto 36px auto;
}

.cancelBtn {
  width: 5.625rem;
}

.prevBtn {
  width: 7.625rem;
  margin-left: auto;
  justify-content: center;
}

.nextBtn {
  width: 11.375rem;
}

.fixedBtnGroup {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.selectContainer {
  margin-left: -16px;
  margin-top: 0.75rem;
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.hidePrefix,
.showPrefix {
  & span:not(span[class="ant-input-prefix"]) {
    width: 23rem;
  }
}

.fixedBid.hidePrefix,
.fixedBid.showPrefix {
  & span:not(span[class="ant-input-prefix"]) {
    width: 22rem;
  }
}

.dateText {
  display: block;
  color: var(--secondary-5);
}

.dateText,
.dateRow {
  margin-top: 1rem;
}

.dateRow {
  width: 22rem;
  justify-content: space-between;
}

.smallDate div[class="ant-picker floating"] {
  width: 10.6875rem;
  margin-right: 10px;
}

.tooltip {
  pointer-events: all;
  position: relative;
  cursor: initial !important;
  border-radius: 8px;
  top: -1px;
}

.dropdown {
  border: var(--border-base);
  box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
    0px 1px 2px rgba(52, 42, 114, 0.08);
  border-radius: 8px;
  padding: 1rem;
  height: 300px;
  flex-direction: column;
  flex-flow: column;
  overflow: auto;
  & label {
    display: flex;
    gap: 1rem;
    margin-bottom: 10px;
    margin-left: 0 !important;
    padding: 10px;
    & span {
      font-family: "Poppins", sans-serif !important;
    }
  }
  &
    label[class="ant-checkbox-wrapper ant-checkbox-wrapper-checked ant-checkbox-group-item"] {
    border-radius: 8px;
    background-color: var(--primary-2);
  }
}

.selected span:nth-child(3) > span {
  background-color: aqua !important;
}

.tagContainer {
  max-height: 88px;
  overflow-y: auto;
  margin-bottom: 10px !important;
  gap: 10px;
}

.tag {
  background-color: var(--secondary-3);
  border-radius: 8px;
  color: var(--primary-6);
  margin-right: 0;
  margin-bottom: 10px;
  border: none;
  font-family: "Poppins", sans-serif !important;
}

.input span span {
  border: var(--border-base);
  border-radius: var(--size-radius);
  background-color: var(--secondary-1);
  padding: 0.5rem 1rem;
}

.input input {
  border: var(--border-base);
  border-radius: var(--size-radius);
  padding: 0.5rem 1rem;

  &:hover,
  &:focus {
    border-color: var(--primary-4) !important;
    box-shadow: none;
  }
}

.creativeFormRow,
.videoFromRow {
  max-width: calc(1000px + 5rem);
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2.25rem;
  margin-top: -4rem;

  & > div:first-of-type > span:first-of-type {
    width: 25rem;
  }

  &:hover > div:first-of-type {
    background-color: var(--secondary-1);
  }

  &:hover .videoCardWrapper {
    border-radius: 0 0 12px 12px;
  }
}

.upload {
  margin-bottom: 1rem;
}

.creativeText {
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.empty {
  max-width: calc(1000px - 5rem);
  margin: 1rem auto;
  background-color: var(--primary-1);

  & > div > div:last-of-type {
    max-width: 360px;
  }
}

.creativeSub {
  max-width: 1000px;
  padding: 2.25rem;
  margin: -10px auto 0 auto;
  border: 1px solid var(--secondary-2);
  background-color: white;
  position: relative;
  z-index: 10;
}

.creatives {
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.delete {
  color: var(--red-5);
}

.warningRecommended {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--primary-4) !important;
  }
}

.sizeTag {
  background-color: var(--primary-2);
  color: var(--primary-4) !important;
  border-radius: var(--size-radius);
  padding: 4px 6px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.wrapper {
  max-width: 1000px;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2.25rem;

  &:focus-within > div:first-of-type,
  &:hover > div:first-of-type {
    background-color: var(--secondary-1);
    border-radius: 12px 12px 0 0;
  }
  &:focus-within > div:last-of-type,
  &:hover > div:last-of-type {
    border-radius: 0 0 12px 12px;
  }
}

.fullHeight {
  min-height: 56vh;
}

//Navbar

.nav {
  justify-content: space-between;
  padding: 0 0 0 var(--size-lg);
  border-bottom: var(--border-base);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
  gap: 0;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 100;
}

.close {
  width: 35px;
  height: 35px;
  background-color: var(--primary-2);
  border-radius: var(--size-radius);
  justify-content: center;
}

.close,
.close svg,
.backBtn,
.pointer {
  cursor: pointer;
}

.nav,
.backBtn,
.btn {
  height: 82px;
}

.name,
.title {
  margin-bottom: 0 !important;
}

.name {
  font-size: 19px !important;
}

.text {
  color: var(--secondary-4);
  gap: 8px;
}

.container {
  margin-left: auto;
  width: 100%;
}
.small {
  max-width: 15rem;
}

.wide {
  max-width: 35rem;
}

.btn {
  width: 250px;
  border-radius: 0;
  text-align: left;
}

.span {
  display: block;
}

.span:last-of-type:not(:first-of-type) {
  font-weight: 400;
  color: var(--secondary-2);
}

.advertiserNav {
  top: 66px;
}

.placementContainer {
  max-width: 1004px;
  margin: 0.875rem auto;
}

.placementContainerText {
  line-height: 1;
  cursor: pointer;
}

.placementBorder {
  padding: 2.25rem 2.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--secondary-1) var(--secondary-3) var(--secondary-3);
  border-radius: 0 0 12px 12px;
  min-height: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in;
}

.placementBorderActive {
  min-height: 300px;
  height: fit-content;
  opacity: 1;
  pointer-events: all;
}

.placementContainer > .placementContainerText {
  font-size: 1.125rem;
  color: var(--secondary-5);
  border-radius: 12px;
  border: 2px solid var(--secondary-1);
  padding: 2.25rem;
  transition: all 0.3s ease-in;
}

.placementContainer > .placementTextActive {
  background: var(--secondary-1);
  border-bottom: 1px solid var(--secondary-1);
  border-radius: 12px 12px 0 0;
}

.containerHidden {
  
  & div, section {
    opacity: 0;
    pointer-events: none;
    height: 0;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease-in;
  }
}

.placementBorder .placementContainerText:first-of-type {
  color: var(--secondary-6);
  margin-bottom: 0.5rem;
}

.placementContainerText:nth-child(2) {
  color: var(--secondary-4);
  margin-bottom: 1.25rem;
}

.placementOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.875rem;
  margin-bottom: 26px;
}

.placementLine {
  margin: 0 !important;
  width: 28.5rem;
  padding: var(--size-xxxs);

  & h5 {
    font-size: var(--size-xxs) !important;
    margin-bottom: 0.25rem !important;
  }

  & p {
    color: var(--secondary-4);
    font-size: var(--size-xxxs);
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.iconUp,
.iconDown {
  width: 1rem;
  height: 1rem;
}

.iconUp path {
  stroke: var(--secondary-5);
}

.iconDown path {
  fill: var(--secondary-5);
}

.placementError .placementOptions label {
  border: 1.5px solid var(--red-5);
  background: var(--red-1);
  box-shadow: 0px 2px 2px 0px rgba(223, 60, 109, 0.08);
}

.placementErrorText {
  padding: 0.75rem;
  color: var(--red-5);
  background-color: var(--red-2);
  border-radius: 0.625rem;
  margin-bottom: 1rem;
}

.maxAcos > span:first-of-type {
  margin-left: -16px;
}

.summaryContainer {
  border: 1px solid var(--secondary-2);
  border-radius: 0.75rem;

  & h6:first-of-type {
    border-radius: 0.625rem 0.625rem 0 0;
  }

  & h6 {
    padding: 0.625rem 0.75rem;
    background: var(--secondary-1);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    color: var(--secondary-4);
    margin-bottom: 0;
  }
  //Rows:
  & > article {
    gap: 0;
    align-items: stretch;
    // cols in rows:
    & > div {
      padding: 0.875rem;
      width: 100%;
    }

    & > div:nth-child(2) {
      border-left: 1px solid var(--secondary-2);
    }
  }

  & .summaryColTitle,
  .summaryColText {
    font-size: 0.8125rem;
    font-weight: 500;
  }

  & .summaryColTitle {
    line-height: 1.05;
    color: var(--secondary-5);
    margin-bottom: 0.625rem;
  }

  & .summaryColText {
    line-height: 1.25;
    color: var(--secondary-7);
    margin-bottom: 0;
  }

  & .summaryColItem {
    margin-right: 0.5rem;
  }

  & > article:not(:last-of-type) {
    border-bottom: 1px solid var(--secondary-2);
  }
}

.productError {
  background-color: var(--red-2);
  border: 1px solid var(--red-5);
}

.redirectFormItem {
  margin-bottom: 0;
  flex-direction: column;
}

.textarea {
  min-height: 140px !important;
  resize: none;
}

.budgetContainer {
  border-radius: 0.75rem;
  border: var(--border-base);
  background-color: var(--white);
  padding: 1rem;
  max-width: 400px;
}

.budgetHelp {
  color: var(--secondary-4);
  line-height: 1.33;
  margin: 0.625rem 0 0.5rem;
  display: block;
}

.totalBudgetContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.875rem;
  border-radius: 0.625rem;
  border: 1px solid var(--secondary-2);
  background: var(--secondary-1);
  transition: border-radius 0.3s ease-in-out;
}

.totalBudgetContainer.open {
  border-radius: 0.625rem 0.625rem 0 0;
}

.totalBudgetTitle,
.totalBudgetButton {
  line-height: 1;
}

.totalBudgetTitle {
  color: var(--secondary-6);
}

.totalBudgetButton {
  background-color: transparent;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-4);
  transition: color 0.25s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.totalBudgetButton.open {
  color: var(--red-5);
}

.totalBudgetInput,
.totalBudgetCalculationContainer {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-5vh);
  transition: translate 0.3s ease-in-out;
}

.totalBudgetSection {
  max-height: 0;
  background-color: var(--white);
  border: 1px solid transparent;
  border-radius: 0 0 0.625rem 0.625rem;
  padding: 0 0.875rem;
  transition: all 0.3s ease;
}

.totalBudgetSection.open {
  max-height: max-content;
  border-color: var(--secondary-2);
  padding: 0.875rem;

  & .totalBudgetInput,
  .totalBudgetCalculationContainer {
    pointer-events: all !important;
    transform: translateY(0);
    opacity: 1;
  }
}

.totalBudgetInput div > span,
.totalBudgetInput input {
  width: 100% !important;
}

.totalBudgetCalculationContainer {
  background-color: var(--secondary-1);
  padding: 0.875rem;
  gap: 0.875rem;
  border-radius: 0.75rem;
}

.progressText {
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.durationInfo {
  color: var(--secondary-7);
}

.day {
  font-size: 0.625rem;
  color: var(--secondary-5);
  text-transform: uppercase;
}

.red strong {
  color: var(--red-5);
}

.yellow strong {
  color: var(--yellow-6);
}

.blue strong {
  color: var(--blue-10);
}

.green strong {
  color: var(--green-9);
}

.durationText {
  color: var(--secondary-5);
}

.creativeSideContainer {
  position: relative;
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 400px;
  border-radius: 16px;
  border: 1px solid var(--primary-2);
  background: radial-gradient(
    45.34% 67.16% at 50% 0%,
    var(--primary-2) 0%,
    #fff 100%
  );
  box-shadow: 0px 1px 2px 0px rgba(52, 42, 114, 0.08);
}

.videoBackgroundIcon {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.creativeIcon {
  position: relative;
  z-index: 1;
}

.creativeSideButton {
  min-height: 33px;
  padding: 8px 16px;
  margin: 20px 0 16px 0;
}

.creativeSideTitle {
  font-size: 18px;
  line-height: 1;
  margin: 46px 0 14px 0;
}

.creativeSideText {
  color: var(--secondary-4);
  line-height: 0.92;
}

.drawerText {
  display: inline-block;
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.videoRadio {
  width: 100% !important;
  margin-top: 12px;
  padding: 20px;
  border-radius: 12px;
  min-height: 80px;

  & > span:first-of-type {
    top: 9.5px;
  }

  & span:last-of-type {
    margin: auto 0;
  }
}

.videoRadioInner {
  display: flex;
  gap: 18px;
  align-items: center;

  & article {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & svg path {
    transition: fill 0.3s ease;
  }
}

.drawerRadioText {
  color: var(--secondary-4);
  line-height: 1;
}

.regularIcon path:first-of-type {
  fill: var(--secondary-1);
}

.regularIcon path:nth-child(2) {
  fill: var(--secondary-3);
}

.regularIcon path:last-of-type {
  fill: var(--secondary-7);
}

.selectedIcon path:first-of-type {
  fill: var(--primary-3);
}

.selectedIcon path:nth-child(2),
.selectedIcon path:last-of-type {
  fill: var(--primary-4);
}

.creativeDrawerForm {
  margin: 14px 0 24px 0;
}

.creativeDrawerInput {
  width: 100% !important;
}

.videoUploadDragger {
  margin-top: 14px;
  max-height: 450px;
  border-radius: 12px !important;
}

.uploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.uploadInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.infoList {
  width: calc(100% - 32px);
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  border-radius: 12px;
  background: var(--secondary-1);
  margin: 0 auto;

  & > div {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
}

.listItem {
  color: var(--secondary-5);
  line-height: 1.5;
}

.outerVideo {
  width: 100%;
  border-radius: 16px;
  background: var(--secondary-1);
  padding: 10px;
  margin-top: 14px;
}

.footerVideo {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    width: 172px;
  }
}

.videoTitle {
  font-size: 20px;
  line-height: 0.7;
  color: var(--secondary-7);
}

.videoDraggerTitle {
  color: var(--primary-6);
  margin-top: 18px;
  line-height: 1;
}

.uploadIcon {
  width: 48px;
  height: 36px;
}

.videoCard {
  display: flex;
  flex-direction: column;
  width: 13.75rem;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid var(--secondary-2);
  background: white;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);

  & video {
    width: 100%;
    border-radius: 6px;
    max-height: 114px;
    object-fit: cover;
  }
}

.videoCardMeta {
  width: 100%;
  padding: 12px 6px 6px 6px;
  background-color: var(--secondary-1);
  border-radius: 6px;
  margin-top: -6px;
  justify-content: center;
}

.videoCardMetaText {
  font-size: 11px;
  color: var(--secondary-4);
  line-height: 1.27;
  text-transform: uppercase;
}

.videoNameContainer {
  padding: 12px 4px;
  display: flex;
  gap: 2px;
}

.creativeName {
  color: var(--secondary-7);
  line-height: 1.33;

  & strong {
    color: var(--secondary-6);
    font-weight: inherit;
  }
}

.videoCardFooter {
  display: flex;
  gap: 8px;
  padding: 10px 2px 2px 2px;
  position: relative;

  &::before {
    content: "";
    width: calc(100% + 20px);
    height: 1px;
    background-color: var(--secondary-2);
    position: absolute;
    top: 0;
    left: -10px;
  }
}

.videoCardIcon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  background: white;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;
}

.editIcon path {
  fill: var(--secondary-7);
}

.videoCardWrapper {
  max-width: 1000px;
  padding: 2.25rem;
  margin: -10px auto 0 auto;
  border: 1px solid var(--secondary-2);
  background-color: var(--secondary-1);
  position: relative;
  z-index: 10;
  border-radius: 12px;
}

.videoCardContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.vastTextArea {
  width: 100% !important;
  height: 100% !important;
  max-height: 450px;
  padding: 10px !important;
  margin-top: 14px !important;
}

.vastErrorContainer {
  padding: 12px;
  border-radius: 10px;
  background: var(--red-2);
  margin: 14px auto -6px auto;

  & span {
    color: var(--red-5);
    line-height: 1.16;
  }
}

.vastErrorInput {
  border: 1px solid var(--red-4) !important;
}

.connectedInfo {
  background: var(--secondary-1);
  width: calc(100% + 16px);
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  padding: 14px;
  margin-top: 20px;
}

.connectedTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  & strong {
    color: var(--secondary-6);
  }
}

.connectedText {
  display: inline-block;
  color: var(--secondary-4);
  line-height: 1.5;
  max-width: 306px;
}

.connectedWarning {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--yellow-1);
  padding: 12px;
  border-radius: 10px;
  margin-top: 14px;
}

.warningIcon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  & circle {
    fill: var(--yellow-6);
  }

  & path {
    fill: var(--white);
  }
}

.connectedWarningText {
  color: var(--yellow-7);
  line-height: 1.25;

  & strong {
    color: var(--yellow-10);
  }
}

.nav {
  justify-content: space-between;
  padding: 0 0 0 var(--size-lg);
  border-bottom: var(--border-base);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
  gap: 0;
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 100;
}

.close {
  width: 35px;
  height: 35px;
  background-color: var(--primary-2);
  border-radius: var(--size-radius);
  justify-content: center;
}

.close,
.close svg,
.backBtn,
.pointer {
  cursor: pointer;
}

.nav,
.backBtn,
.btn {
  height: 82px;
}

.name,
.title {
  margin-bottom: 0 !important;
}

.name {
  font-size: 19px !important;
}

.text {
  color: var(--secondary-4);
  gap: 8px;
}

.container {
  margin-left: auto;
  width: 100%;
}
.small {
  max-width: 15rem;
}

.wide {
  max-width: 35rem;
}

.btn {
  width: 250px;
  border-radius: 0;
  text-align: left;
}

.span {
  display: block;
}

.span:last-of-type:not(:first-of-type) {
  font-weight: 400;
  color: var(--secondary-2);
}

.advertiserNav {
  top: 66px;
}

.bg {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 60px;
  justify-content: space-between;

  & article {
    text-transform: capitalize;
  }

  & img {
    width: 104px;
  }

  & p,
  a {
    color: var(--secondary-4);
    font-weight: 500;
    font-size: 14px;
    line-height: 1;

    & span {
      text-decoration: underline;
    }

    & svg {
      width: 1rem;
    }
  }
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 1.1rem 0;
}

.light {
  background-color: var(--secondary-1);

  & h3 {
    color: var(--secondary-6);
  }

  & svg path {
    fill: var(--secondary-4);
  }
}

.dark {
  background-color: var(--primary-4);

  & h3 {
    color: var(--white);
  }

  & p,
  a {
    color: var(--primary-3);

    &:hover {
      color: var(--white) !important;
    }
  }

  & svg path {
    fill: var(--primary-3);
  }
}

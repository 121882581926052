.container {
  padding: 1rem var(--size-lg);
  z-index: 99;
  justify-content: space-between;
}

.blueContainer {
  position: sticky;
  background-color: var(--blue-2);
  top: 82px;
}


.yellowContainer {
  position: fixed;
  background-color: var(--yellow-1);
  width: calc(100% + var(--size-lg) * 2);
  margin-left: -24px;
  top: 60px;
  border-bottom: 1px solid var(--yellow-2);
}

.blueContainer.advertiserView {
  top: 148px;
}

.yellowContainer.advertiserView {
  top: 126px;
}

.yellowContainer.advertiserViewWithDocs {
  top: 164px;
}

.textContainer {
  margin-right: auto;
}

.text {
  color: var(--secondary-6);
}

.iconContainer {
  gap: 20px;
}

.icon {
  cursor: pointer;
}

.infoTextCreate {
  width: 60px;
}

.infoTextCampaign {
  width: 35px;
}

.create {
  & span {
    color: var(--blue-10);
  }
  & path,
  circle {
    fill: var(--blue-10);
  }
}

.campaign {
  & span {
    color: var(--yellow-6);
  }
  & path,
  circle {
    color: var(--yellow-6);
  }
}

.link {
  color: var(--yellow-6) !important;
  text-decoration: underline !important;
  margin: 0 3px;
  font-weight: 600;
}

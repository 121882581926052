.justifyContent {
  justify-content: space-between;
}

.title {
  margin-bottom: 0.5rem !important;
}

.text {
  color: var(--secondary-5);
  display: block;
  max-width: 400px;
  line-height: 24px;
}

.title,
.download {
  color: var(--primary-4) !important;
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 12px;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.container:last-of-type {
  margin-bottom: 0;
}
.secondaryText {
  color: var(--secondary-7);
}

.input,
.select {
  width: 18.625rem !important;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.backBtn,
.nextBtn {
  width: 100%;
}

.backBtn {
  max-width: 96px;
  padding: 0 18px;
}

.backIcon {
  transform: rotate(180deg);

  & path {
    fill: var(--secondary-7);
  }
}

.search {
  gap: 0;
  padding: 0 14px;
  height: 42px;
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: var(--primary-4) !important;
  }

  & input,
  & input::placeholder {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  & input {
    color: var(--secondary-7);
  }

  & input::placeholder {
    color: var(--secondary-4);
  }

  &:focus-within {
    border: 2px solid white !important;

    /* Input-Focused */
    box-shadow: 0px 0px 0px 1.5px var(--primary-4) inset,
      0px 0px 0px 2px rgba(52, 42, 114, 0.1),
      0px 4px 8px 0px rgba(52, 42, 114, 0.08),
      0px 1px 2px 0px rgba(52, 42, 114, 0.08) !important;
  }

  & svg {
    width: 1rem;
    height: 1rem;
    margin-right: 8px;
  }
}

.search.product {
  & input {
    font-size: 12px;
  }

  & input::placeholder {
    color: var(--secondary-4);
  }
}

.container {
  position: relative;
}

.fullWidth {
  width: 100%;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--size-s);
  min-height: 1.875rem;
  width: 3.125rem;
}

.shadow {
  box-shadow: 0px 2px 2px rgba(37, 41, 68, 0.03);
  padding: var(--size-xs) var(--size-s);
}

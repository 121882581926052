.pageWrapper {
  background-color: var(--secondary-1);
  padding: 16px;
  min-height: 100vh;
}

.pageContainer {
  background-color: var(--white);
  border-radius: 16px;
}

.nav {
  padding: 16px;
  border-bottom: 1px solid var(--secondary-2);
  border-radius: 16px 16px 0 0;
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 11;
  transition: box-shadow 0.3s ease;
}

.scrolledNav {
  box-shadow: 0px 44px 12px 0px rgba(0, 0, 0, 0),
    0px 28px 11px 0px rgba(0, 0, 0, 0), 0px 16px 9px 0px rgba(0, 0, 0, 0.01),
    0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
}

.close {
  width: 36px;
  height: 36px;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;

  & svg path {
    transition: fill 0.3s ease-in-out;
    fill: var(--secondary-7);
  }

  &:hover {
    box-shadow: 0px 17px 5px 0px rgba(0, 0, 0, 0),
      0px 11px 4px 0px rgba(0, 0, 0, 0), 0px 6px 4px 0px rgba(0, 0, 0, 0.02),
      0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
  }

  &:focus {
    box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03), 0px 0px 0px 3px #fff,
      0px 0px 0px 4px var(--secondary-1);

    & svg path {
      fill: var(--primary-4);
    }
  }

  &:active {
    border-color: var(--primary-2);
    box-shadow: 0px 2px 2px 0px rgba(101, 79, 228, 0.04), 0px 0px 0px 3px #fff,
      0px 0px 0px 4px var(--primary-1);

    & svg path {
      fill: var(--primary-6);
    }
  }
}

.title {
  color: var(--secondary-7) !important;
}

.footer {
  padding: 16px;
  justify-content: space-between;
  border-top: 1px solid var(--secondary-2);
}

.saveBtn {
  min-width: 116px;
}

.container {
  border: var(--border-base);
  border-radius: 8px;
  padding: var(--size-md) var(--size-xxs);
  margin-top: 1.5em;
  gap: 0.75em !important;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1rem;
}

.space {
  width: 100%;
  & div:nth-of-type(2) {
    width: 80%;
  }
}

.text {
  gap: 1em;
  padding: 0.5em 1em;
  margin-bottom: 0.1em;
  border-radius: 8px;
  cursor: pointer;
}

.text:last-of-type {
  color: var(--pink-3);
  font-weight: 500;
}
.text:hover {
  background: var(--gray-2);
}

.tab {
  border-radius: var(--size-radius);
  border: var(--border-base);
  margin-top: 20px;
}
.line {
  padding: 1rem;
}

.line:not(:last-of-type) {
  border-bottom: var(--border-base);
}

.spaceBetween {
  justify-content: space-between;
}

.item {
  color: var(--secondary-4);
}

.icon {
  cursor: pointer;
}

.amount {
  display: flex;
}

.red {
  color: var(--red-7);
}

.green {
  color: var(--green-7);
}

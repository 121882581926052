.card {
  position: relative;
  width: 90%;
  max-width: 750px;
  margin: 0 auto;
  background-color: white;
  border: 1px solid var(--secondary-2);
  height: 575px;
  margin-top: 40px;
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  border-radius: 12px;
  display: none;
  overflow-y: auto;

  & > div {
    padding: 1rem;
  }

  & > div:not(:first-of-type) {
    border-top: 1px solid var(--secondary-2);
  }

  & img {
    max-width: 100%;
    max-height: 150px;
    object-fit: cover;
    margin: 0 auto;
    display: flex;
  }
}

.visible {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 0rem !important;
  border-radius: 12px 12px 0 0;
  color: var(--secondary-6) !important;
  padding: 20px 20px 0 20px;
  position: sticky;
  top: 0;
  line-height: 1 !important;
}

.imgContainer {
  width: 100%;
  height: 100%;
  min-height: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vastTag {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: var(--secondary-1);

  & .vastTagText {
    color: var(--secondary-5);
    min-width: fit-content;
  }

  & .vastTagLink {
    color: var(--secondary-6);
  }
}

.text {
  display: flex;
  justify-content: space-between;

  & div {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  & div:last-of-type {
    padding-left: 12px;
  }

  & div:first-of-type::after {
    content: "";
    position: absolute;
    height: calc(100% + 30px);
    width: 1px;
    background-color: var(--secondary-3);
    top: -15px;
    right: 0;
  }
}

.grayText {
  color: var(--secondary-5);
}

.urlContainer {
  align-items: flex-start;
  line-height: 1.3;

  & svg {
    margin-left: auto;
    width: 1rem;
    cursor: pointer;
  }
}

.link {
  color: var(--secondary-7);
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  max-width: max-content;
  word-break: break-word;
  line-height: 1.3;
}

.link:not(.vastTagLink) {
  font-family: "Poppins", sans-serif !important;
}

.accepted {
  color: var(--green-10);
}

.pending {
  color: var(--yellow-6);
}

.rejected {
  color: var(--red-5);
}

.sizeContainer {
  gap: 1rem;

  & svg {
    width: 6px;
    & circle {
      fill: var(--secondary-4);
    }
  }
}

.infoContainer {
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
}

.btnContainer {
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.btn {
  justify-content: center;
  padding: 0;
  gap: 0;
}

.okBtn {
  width: 120px;
  height: 36px;
}

.rejectBtn {
  width: 75px;
  height: 36px;
  background: white;
}

.languageWrapper {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.languageContainer {
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  background: var(--secondary-1);
  color: var(--secondary-7);
}

.languageContainer + .languageContainer {
  margin-left: 4px;
}

.regionText {
  line-height: 2;
}

.btn {
  padding: 4px 12px;
  color: var(--primary-4);
  border: 1px solid var(--primary-4);
  float: right;
  &:hover {
    border-color: var(--primary-6);
    color: var(--primary-6);
  }
}

.select {
  color: black;
  width: 10rem;
  & div {
    padding: 2px 15px !important;
  }
}

.col {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 50px;
  margin-bottom: 24px;
  border-top: 1px solid var(--secondary-2);
}

.col.details {
  flex-direction: column;
  padding-top: 0;
  margin-bottom: 0;
  border-top: none;
}

.detailsRadio {
  width: 100%;
  margin-bottom: 12px;

  & > div > div > div > div {
    flex-direction: row !important;
    justify-content: space-between;
    gap: 12px;
  }
}

.title {
  color: var(--secondary-6) !important;
  font-size: 1.125rem !important;
  font-weight: 600;
  line-height: 1 !important;
  margin-bottom: 0.625rem !important;
}

.text {
  color: var(--secondary-4);
  line-height: 2.2;
}

.radio {
  margin-bottom: 10px;
  padding: 12px;

  & h5 {
    margin-bottom: 0 !important;
  }
}

.radio .radioText {
  color: var(--secondary-4);
  display: inline-block;
  line-height: 1.5;
  margin-top: 6px;
  max-width: 336px;
}

.select {
  width: calc(100% + 16px) !important;
  max-width: 400px;
  margin-top: 20px;

  & > div > span {
    left: 14px !important;
  }

  & > div > div {
    gap: 6px;

    & > div > div {
      -webkit-margin-start: 0px !important;
      margin-inline-start: 0px !important;
    }
  }
}

.option > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tag {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
  background-color: var(--secondary-1);
}

.tag .checkbox {
  display: none;
}

.closeIcon {
  width: 10px;
  cursor: pointer;
}

.error {
  color: var(--red-4) !important;
  background-color: var(--red-1);
  border: 1px solid var(--red-5) !important;
  position: relative;

  & .select > div {
    border: 1px solid var(--red-4) !important;
  }
}
.accepted {
  color: var(--green-10);
}

.pending {
  color: var(--yellow-6);
}

.rejected {
  color: var(--red-5);
}

.btnContainer {
  position: relative;
  right: -42px;
  z-index: 10;
  justify-content: flex-end;
}

.btn {
  justify-content: center;
  padding: 0;
  gap: 0;
}

.okBtn {
  width: 95px;
  height: 36px;
}

.rejectBtn {
  width: 73px;
  height: 36px;
  background: white;
}

.textarea {
  min-height: 130px !important;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: stretch;
  padding: 56px 26px;
  max-width: 1360px;
  margin: 0 auto;
}

.container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.colored {
  background-color: var(--primary-6);
  & span {
    color: var(--white);
  }
}

.transparent {
  border: 2px solid var(--primary-6);
  & span {
    color: var(--primary-6);
  }
}

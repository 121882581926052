.container {
  display: flex;
  align-items: center;
  gap: 3px;
}

.text {
  display: inline-block;
  min-width: max-content;
}

.flag {
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 3px;
  border-radius: 5px;
  border: 1px solid var(--secondary-3);
}

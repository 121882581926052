.title {
  line-height: 1 !important;
  margin-bottom: 0.25rem !important;
}

.title:nth-of-type(2) {
  margin-top: 24px;
}

.text {
  color: var(--secondary-4);
  margin-top: 6px;
  display: inline-block;
  line-height: 1.5;
}

.subtext {
  color: var(--secondary-5);
  margin-bottom: 1rem;
  display: inline-block;
  line-height: 2;
}

.selectContainer {
  margin-top: 1rem;
  width: calc(100% + 14px);

  & input {
    position: relative;
    left: -10px;
  }

  & > div {
    margin-bottom: 0;
  }
}

.selectContainer:first-of-type {
  border-top: var(--border-base);
}

.selectedSelect div {
  border: 1px solid var(--primary-4) !important;
  & span:nth-of-type(2) {
    color: var(--primary-4) !important;
    font-weight: 600;
  }
}

.selectLabel {
  color: var(--secondary-4);
  display: inline-block;
  margin-bottom: 14px;
  line-height: 1.5;
}

.selectTag {
  position: relative;
  top: 0;
}

.subtitle {
  margin-top: 20px;
}

.placement label:not(:last-of-type) {
  margin-bottom: 10px;
}

.sizes {
  align-items: center;
  justify-content: flex-start;
  max-width: 450px;
  flex-wrap: wrap;
  gap: 10px;
}

.tags > div > span,
.tags > div > div > div:last-of-type {
  margin-left: 0.5rem;
}

.secondaryTitle {
  font-size: 13px;
  line-height: 1.55;
  color: var(--secondary-5);
  font-weight: 600;
  margin: 1rem 0 0.5rem;
}

.vastTagContainer {
  width: 100%;
  padding: 10px;
  background: var(--secondary-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 12px;
}

.vastTag {
  color: var(--secondary-6);
  line-height: 1.28;
  word-break: break-all;
  width: fit-content;
}

.copyTag {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: var(--border-base);
  background: var(--white);
  cursor: pointer;
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.warningContainer {
  padding: 12px;
  border-radius: 10px;
  background: var(--secondary-1);
  margin-top: 8px;
}

.warningText {
  font-size: 12px;
  color: var(--secondary-6);
  line-height: 1;
  margin-bottom: 0;
}

.promoteProducts {
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  padding: 12px;
  margin-top: 14px;
  gap: 15px;
}

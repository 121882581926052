.container {
  height: 188px;
  border: 1px solid var(--primary-4);
  border-radius: 6px;
  margin-top: 10px;
}
.header {
  padding: 16px 32px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.graphTitle {
  font-size: 18px;
  color: var(--black) !important;
  margin-bottom: 5px !important;
  font-family: "Poppins", sans-serif !important;
}

.text {
  color: var(--secondary-4);
}

.row {
  flex-flow: nowrap;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
}

.advertiserRow {
  margin-top: 82px;
}

.title {
  color: var(--black) !important;
  margin-top: 10px;
  margin-bottom: 5px !important;
}
.card {
  border: var(--border-base);
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.card:last-of-type {
  min-width: 80px;
  justify-content: center;
  transition: 0.3s ease-in-out;
  & div[class="ant-card-body"] {
    padding: 0;
    width: 24px;
    height: 24px;
  }
}

.card.selectable {
  background-color: var(--primary-2);
  border: 1px solid var(--primary-6);
}

.icon {
  width: inherit;
  height: inherit;
}

.up {
  cursor: pointer;
}

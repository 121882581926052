.justifyContent {
  justify-content: space-between;
}

.title {
  margin-bottom: 0.5rem !important;
  color: var(--primary-4) !important;
  line-height: 16px !important;
}

.text {
  color: var(--secondary-5);
  display: block;
  line-height: 24px;
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 12px;

  & form > div:not(:last-child) {
    margin-bottom: 36px;
  }

  & .link {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-4);
    text-decoration: underline;
    margin-top: 0.5rem;
    display: block;
  }
}

.container + .container {
  margin-top: 1rem;
}

.flex {
  display: flex;
  justify-content: flex-start;
  gap: 4rem;
  align-items: flex-start;
}

.text,
.warning {
  max-width: 400px;
}

.warning {
  margin-top: 1rem;
  white-space: break-spaces;
}

.select {
  width: 300px !important;
}

.dropdown div[class="ant-select-item ant-select-item-group"] {
  margin-top: -60px !important;
}

.dropdown div[class="ant-select-item-option-content"] {
  padding-bottom: 52.5px;
}

.radioGroup label {
  width: 100%;

  &:first-of-type {
    margin-bottom: 10px;
  }

  & h5 {
    margin-bottom: 4px !important;
  }
}

.radioText {
  color: var(--secondary-4);
  line-height: 1.5;
  display: inline-block;
  max-width: 295px;
}

.tabContainer div[class="ant-tabs-nav-list"],
.tabContainer div[class*="ant-tabs-tab"] {
  width: 100%;
  text-align: center;
}

.tab {
  border-radius: 0.875rem;
  border: 1px solid var(--secondary-2);
  margin-top: 0.75rem;
}

.form,
.boxContainer {
  text-align: start;
  padding: 0.875rem;
}

.singleForm {
  padding: 1.25rem 0.875rem 0.5rem;
}

.boxContainer {
  background-color: var(--secondary-1);
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.875rem 0.875rem 0 0;

  & svg path {
    fill: var(--primary-4);
  }

  & .title {
    line-height: 1.4;
    color: var(--secondary-5);
  }

  .popupLink {
    font-size: 0.75rem;
    line-height: 1;
    color: var(--primary-4);
    text-decoration: underline;

    & svg {
      margin-right: 0.375rem;
    }
  }
}

.form input {
  max-width: 100%;
}

.container,
.card,
.link,
.icon {
  display: flex;
}

.container,
.link {
  justify-content: space-between;
}

.container {
  background-color: var(--secondary-1);
  border-radius: 0.875rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
}

.drawerTitle {
  font-size: 0.875rem !important;
  color: var(--secondary-7) !important;
  line-height: 1 !important;
  margin-bottom: 0.875rem !important;
}

.card {
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;

  & span {
    color: var(--secondary-6);
    line-height: 1;
  }
}

.card:last-of-type {
  padding-left: 1.25rem;
  border-left: var(--border-base);
}

.link {
  padding: 0.625rem 0.75rem;
  border-radius: 0.875rem;
  margin-top: 1rem;
  gap: 1rem;
  background-color: var(--secondary-1);
  color: var(--secondary-6);
  line-break: anywhere;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  background: var(--white);
  border: var(--border-base);
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;

  &:active {
    box-shadow: inset 0 0 0 1px var(--primary-4);
  }
}

.advertiserText {
  color: var(--gray-4);
  display: inline-block;
  margin: 0.5rem 0;
}

.dragger {
  height: max-content !important;
}

.uploadTitle {
  color: var(--primary-6);
  font-size: 0.875rem;
  font-weight: 600;
  max-width: 220px;
  margin: 1.125rem 0 0.625rem;
}

.uploadText {
  color: var(--secondary-4);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
}

.advertiserSelect {
  margin-bottom: 0.5rem;
}

.container {
  background: #1f1f1f;
  padding: 50px 25px;
  background-image: url("../../../../../assets/images/landing_page/target-bg-mobile.png");
  background-repeat: no-repeat;
  background-size: contain;

  & h5,
  h6 {
    font-family: "Satoshi", sans-serif !important;
  }

  & h5,
  > h6 {
    text-align: center;
    font-weight: 700;
    line-height: 1.35;
    max-width: 700px;
    margin: 0 auto;
  }

  & h5 {
    color: #ff7a00;
    font-size: var(--size-xs);
    margin-bottom: 1.25rem;
  }

  & > h6 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    background: -webkit-radial-gradient(#ffffff, #9a9a9a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.75rem;
    margin-bottom: 4rem;
  }
}

.container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.75rem;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1228px;
  margin: 0 auto;
}

.card {
  max-width: 369px;
}

.card h6 {
  color: #e7e7e7;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.card p {
  color: #b5b5b5;
  font-size: var(--size-xxs);
  font-family: "Inter", sans-serif !important;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.card img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 5rem 0;
    background-size: cover;
    background-image: url("../../../../../assets/images/landing_page/target-bg.png");
    background-position: center -30vh;
  }

  .container > h6 {
    width: 567px;
    font-size: 2.625rem;
    text-align: center;
    margin-bottom: 5.75rem;
  }

  .card {
    max-width: 664px;
    margin: auto;

    & h6 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    & p {
      font-size: 1rem;
      margin-bottom: 2.75rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .container > h6 {
    margin-bottom: 7.5rem;
  }

  .container > div {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .card {
    & h3 {
      font-size: 1.75rem;
    }

    & p {
      font-size: 1rem;
    }
  }
}

@media screen and (min-width: 1280px) {
  .container {
    padding: 8.5rem 0;
    background-position: center 40%;
  }

  .container h5 {
    font-size: 1.125rem;
  }

  .container h6 {
    width: 760px;
  }

  .container > div {
    gap: 3.5rem;
  }

  .card {
    width: 370px;
    margin: 0;
  }
}

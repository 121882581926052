.pageContainer {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-1);
  display: flex;
  justify-content: center;
  margin-top: var(--size-lg);

  & .container {
    margin: 36px auto 0;
    width: 90%;
    max-width: 820px;
    min-height: calc(100vh - 90px);
  }
}

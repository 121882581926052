.tag {
  border-radius: 8px;
  padding: 0;
}

.unfilledGreen {
  background-color: var(--green-2);
  border: none;
  padding: 4px 8px;
  & span {
    color: var(--green-10);
  }
}

.unfilledYellow,
.unfilledRed,
.unfilledBlue {
  background-color: transparent;
  border: none;
}

.unfilledBlue span {
  color: var(--blue-10);
}

.unfilledYellow span {
  color: var(--yellow-6);
}

.unfilledRed span {
  color: var(--red-5);
}

.filledGreen {
  background-color: var(--green-9);
  border: none;
  & span {
    color: var(--white);
  }
}

.filledBlue {
  background-color: var(--blue-10);
  border: none;
  & span {
    color: var(--white);
  }
}

.filledYellow {
  background-color: var(--yellow-6);
  border: none;
  & span {
    color: var(--white);
  }
}

.filledRed {
  background-color: var(--red-2);
  border: none;
  
  & span {
    color: var(--white);
  }
}

.green {
  background-color: transparent;
  border: none;
  & span {
    color: var(--green-10);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.header {
  width: 100%;
  height: calc(95vh - 5.5rem);
  max-width: 1228px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.headerBg {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../assets/images/landing_page/landing-hero-mobile.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  background-position-y: -80px;
  background-position-x: center;
}

.header article {
  padding: 1.875rem 1.875rem 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 1rem;
  max-width: 580px;
  margin: 0 auto;

  & p {
    color: #838383;
    font-size: var(--size-xxs);
    line-height: 2;
    margin-bottom: 0;
    background-color: var(--white);
    font-family: "Inter", sans-serif !important;
  }
}

.header article h1 {
  font-size: 2.125rem;
  color: #1a1a1a;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0;
  font-family: "Satoshi", sans-serif !important;

  & span {
    font-family: "Satoshi", sans-serif !important;
    color: #0058a9;
  }
}

.cta {
  background-color: #0074de;
  padding: 1rem 1.875rem;
  border-radius: 4.5rem;
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: -24px;
  transition: background-color 0.3s ease-in-out;
  & svg {
    margin-left: var(--size-md);
  }

  &:hover {
    background-color: #0058a9;
    color: var(--white) !important;
  }
}

.divider {
  margin: 0;
  border-color: #e9e9e9;
}

@media screen and (min-width: 768px) {
  .header {
    max-height: 924px;
  }

  .headerBg {
    background-position-y: 66%;
  }

  .header article {
    justify-content: center;
    padding-top: 35%;
    gap: 1.875rem;
  }

  .header article h1 {
    font-size: 3.625rem;
  }

  .header article p {
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 1024px) {
  .headerBg {
    background-position-y: 58%;
  }

  .header article {
    padding-top: 25%;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    display: flex;
    justify-content: space-between;
    height: 584px;
    margin-top: -44px;
    background-color: white;
    z-index: 1;
  }

  .headerBg {
    position: relative;
    order: 1;
    background-position: -110px center;
    background-image: url("../../../../../assets/images/landing_page/landing-hero-desktop.png");
  }

  .header article {
    justify-content: center;
    padding: 0;
    text-align: start;
    align-items: flex-start;
    gap: 2rem;
    padding-top: 44px;

    & h1 {
      font-size: 3.5rem;
    }

    & p {
      font-size: 1rem;
    }

    & .cta {
      margin-bottom: 0;
    }
  }
}

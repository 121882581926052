.category {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px 20px 20px;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  background: linear-gradient(
    179.65deg,
    var(--primary-2) -14.37%,
    var(--white) 48.27%
  );
  border: var(--border-base);
  border-radius: 10px;
  & img {
    width: 100%;
  }
  & a {
    color: var(--primary-4);
    font-size: var(--size-xxxs);
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding: 2px;
    border: 1.5px solid var(--primary-4);
    border-radius: var(--size-radius);
    margin-top: auto;
  }
}

.image {
  height: 140px;
  & svg {
    height: 100%;
  }
}
.title {
  font-size: var(--size-xs) !important;
}
.text {
  color: var(--secondary-4);
  line-height: 1.8;
}

.tab {
  border-radius: var(--size-radius);
  border: var(--border-base);
  margin-top: 20px;
}

.line {
  padding: 1rem;
}

.line:not(:last-of-type) {
  border-bottom: var(--border-base);
}

.spaceBetween {
  justify-content: space-between;
}

.item {
  color: var(--secondary-4);
}

.icon {
  cursor: pointer;
}

.input {
  width: 100% !important;
}

.title {
  color: var(--secondary-7) !important;
  margin-bottom: 1rem !important;
}

.popover > div > div:last-of-type {
  border-radius: 8px;
  border: none;

  & > div {
    padding: 0.15rem !important;
    width: 160px !important;
  }
}

.text {
  gap: 1em;
  padding: 0.7em 1em;
  margin-bottom: 0.1em;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.25s ease-in;
  width: max-content;
  color: var(--primary-6);

  &:hover {
    background: var(--primary-2);
  }
}

.link {
  color: var(--primary-6) !important;
  &:hover {
    color: var(--primary-6) !important;
  }
}

.invoiceNo {
  display: inline-block;
  width: 150px;
}

.amount {
  display: flex;
}

.red {
  color: var(--red-7);
}

.green {
  color: var(--green-7);
}

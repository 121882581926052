.wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & p,
  button {
    margin-bottom: 0;
    line-height: 1;
  }

  & .textContainer,
  .accounts,
  button {
    width: 90%;
    max-width: 450px;
  }

  & h1 {
    color: var(--primary-6);
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1;
  }

  & .textContainer p {
    font-weight: 500;
    font-size: 1rem;
    color: var(--secondary-4);
  }
}

.textContainer {
  align-items: flex-start;
  gap: 1.875rem;
  margin-bottom: 2.5rem;
}

.iconContainer {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-color: var(--secondary-1);
  justify-content: center;

  & svg {
    width: 2rem !important;
  }
}

.accounts {
  max-height: 400px;
  overflow-y: auto;
}

.account {
  padding: 1.25rem;
  gap: 1.25rem;
  border: 1px solid var(--secondary-2);
  border-radius: 1rem;
  box-shadow: 0px 1px 2px 0px rgba(52, 42, 114, 0.08);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & p {
    color: var(--secondary-5);
    font-size: 1.125rem;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    border: 1px solid var(--primary-2);
    background: var(--primary-1);

    & p {
      color: var(--primary-6);
    }

    .userIcon {
      border: 1px solid var(--primary-2);
    }

    & svg path {
      fill: var(--primary-6);
    }
  }
}

.account + .account {
  margin-top: 0.875rem;
}

.userIcon {
  width: 3.25rem;
  height: 3.25rem;
  border: 1px solid var(--secondary-2);
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;

  & svg path {
    transition: all 0.3s ease-in-out;
  }
}

.button {
  min-height: 2.25rem;
  margin-top: 2.5rem;
}

.noAccountContainer {
  flex-direction: column;
  align-items: flex-start;
  max-width: 435px;
  margin: 0 auto;

  & article {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    flex-direction: column;
    gap: 0;
  }
}

.icon,
.noAccountContainer {
  justify-content: center;
}

.icon {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: var(--red-1);
  margin-bottom: 1.875rem;

  & svg {
    width: 1.75rem;
    height: 1.75rem;

    & path {
      fill: var(--red-5);
    }
  }
}

.noAccountTitle {
  margin-bottom: 1rem !important;
  line-height: 1.5 !important;
}

.noAccountText {
  text-align: center;
  line-height: 1.7;
  margin-bottom: 1.875rem;
  color: var(--secondary-4);

  & strong {
    color: var(--secondary-5);
  }
}

.noAccountButton {
  width: 100% !important;
  margin: 0 auto;
}

.form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & .textContainer,
  form {
    width: 90%;
    max-width: 435px;
  }

  & h1 {
    color: var(--primary-6);
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-4);
    margin-bottom: 0;
  }

  & input::placeholder,
  .remember label span,
  a {
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-4);
  }

  & form {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }
}

.textContainer {
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 40px;
}

.iconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--secondary-1);
  justify-content: center;

  & svg {
    width: 2rem;
  }
}

.formItem input:not([id="password"]),
.formItem > div > div > div > span {
  border: var(--border-base);
  border-radius: var(--size-radius);
  padding: 8px 14px;
  height: 48px;
  &:hover {
    border: 1px solid var(--primary-4) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03),
      0px 2px 10px rgba(0, 0, 0, 0.03);
  }
}

.formItem input:focus:not([id="password"]) {
  border: 1px solid var(--primary-4) !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 2px 10px rgba(0, 0, 0, 0.03);
}

.formItem div:last-child {
  margin-bottom: 5px;
}

.btn {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subContainer,
.remember label {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.subContainer + div {
  margin-bottom: 0 !important;
}

.remember label {
  gap: 10px;
}

.signup {
  display: flex;
  gap: 14px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  & a {
    color: var(--primary-4);
    text-decoration: underline;
    border-left: var(--border-base);
    padding-left: 15px;
    line-height: 1;
  }
}

.text {
  color: var(--secondary-4);
  position: relative;
}

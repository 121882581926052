.container {
  display: flex;
  align-items: flex-start;
}

.navigation {
  min-width: 388px;
  padding: 60px 40px 0 100px;
  position: sticky;
  top: 60px;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: right;
}

.listItem {
  color: var(--secondary-3);
  font-size: 18px;
  font-weight: 500;
  line-height: 0.78;
  transition: color 0.3s ease;
}

.listItem.active {
  color: var(--secondary-7);
}

.main {
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--secondary-2);
  padding: 0 100px 60px 0;
  margin-top: 60px;
}

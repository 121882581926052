.row{
  max-width: 1000px;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2.25rem;
  margin-top: 1.875rem;
}
.row:first-of-type{
  margin-top: 4rem;
}
.bg{
  background-color: var(--secondary-1);
  margin: 3em auto;
}
.container {
  width: 90%;
  margin: 6rem auto 0;
  max-width: 900px;
  font-family: "Satoshi", sans-serif !important;
  text-align: center;

  & ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;
    margin: 2rem 0;
    flex-wrap: wrap;
  }

  & h1 {
    margin-bottom: 2rem;
  }

  & h1,
  h2 {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    color: #3824aa;
  }

  & h3,
  h4 {
    color: #f58220;
    font-weight: 600;
    line-height: 1.35;
  }

  & p {
    color: rgb(102, 102, 102);
    font-size: 0.9rem;
    line-height: 1.75rem;
    letter-spacing: 0.00625rem;
    margin-bottom: 1rem;
  }
}

.container img {
  width: 100%;
  object-fit: contain;
  padding: 1.25rem;
  border: 1px solid #ececec;
  border-radius: 2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 2rem;
  background-color: white;
}

.body {
  max-width: 780px;
  margin: 5rem auto;
  background-color: var(--white);
  border-radius: 2rem;
  padding: 3rem;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  gap: 2rem;

  & > div {
    display: flex;
    flex-direction: column;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    display: block;
    top: 0;
    left: 0;
    background-color: #ff6006;
    opacity: 0.2;
    filter: blur(20px);
    width: 100%;
    height: 100%;
    box-shadow: 0px 16px 40px 0px rgba(255, 255, 255, 0.4) inset,
      0px 0px 0px 7px rgba(255, 255, 255, 0.09) inset;
  }
}

.conclusion {
  border-radius: 3rem;
  margin-top: 4rem;

  & strong {
    display: block;
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    max-width: 600px;
    margin: 4rem auto 0;
  }
}

.conclusion h2 {
  color: #0073dd;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.35;
}

.smallCard {
  width: 160px;
  height: 200px;
  padding: 1.25rem;
  background: linear-gradient(-20deg, #0074de 1.21%, #3824aa 67.75%);
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.25rem;
  color: white;
  font-weight: 600;
  text-align: start;
  & svg path {
    fill: white;
  }
}

.btn {
  background-color: #f58220;
  width: 12rem;
  height: 3rem;
  border-radius: 2rem;
  display: inline-block;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: white !important;
  }
}

.cardContainer {
  background-color: var(--yellow-1);
  border: 1px solid var(--yellow-2);
  border-radius: 9px;
  width: 100%;
}

.container,
.textContainer {
  display: flex;
}

.container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}

.textContainer {
  flex-direction: column;
  margin-right: auto;
  justify-content: space-between;
}

.button {
  border-radius: 6px;
  padding: 0.3em 0.6em;
}

.title {
  color: var(--black-1) !important;
}

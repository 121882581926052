.drawer > div:last-of-type div {
  background-color: #0f0f0f;
}

.drawer div[class="ant-drawer-header"] {
  border-bottom: 1px solid #2c2c2c;
}

.logTypeWrapper {
  position: sticky;
  top: -24px;
  width: calc(100% + 48px);
  background-color: #0f0f0f;
  display: flex;
  margin: -24px 0 0 -24px;
  border-bottom: 1px solid #2c2c2c;
  z-index: 12;
}

.logTypeContainer,
.prev,
.next {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px;
  border-right: 1px solid #2c2c2c;
  background-color: #1b1b1b;
  cursor: pointer;
}

.prev,
.next {
  & svg {
    width: 10px;
  }

  & svg path {
    fill: var(--secondary-1);
  }
}

.logTypeContainer {
  width: 150px;
  transition: background-color 0.25s ease-in-out;
}

.active {
  background-color: #2c2c2c;
}

.logTypeContainer:first-child,
.prev {
  border-left: 1px solid #2c2c2c;
}

.tabText {
  color: var(--white);
  line-height: 1;
}

.prev {
  margin-left: auto;
}

.logsWrapperEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 32px);
  width: 100%;
}

.empty {
  width: 100%;
  max-width: 400px;
  padding: 0;

  & > div > div:first-of-type {
    box-shadow: 0px 0px 0px 11.744px rgba(255, 255, 255, 0.05),
      0px 21.14px 5.872px 0px rgba(255, 255, 255, 0),
      0px 14.093px 5.872px 0px rgba(255, 255, 255, 0),
      0px 8.221px 4.698px 0px rgba(255, 255, 255, 0.01),
      0px 3.523px 3.523px 0px rgba(255, 255, 255, 0.02),
      0px 1.174px 2.349px 0px rgba(255, 255, 255, 0.02),
      0px 0px 0px 0px rgba(255, 255, 255, 0.02);
  }

  & > div > div:not(:first-of-type) {
    background-color: transparent;

    & h5 {
      margin-top: 40px !important;
      color: var(--secondary-1) !important;
    }

    & span {
      margin-top: 24px;
      color: #7d7d7d;
    }
  }

  & svg path {
    fill: var(--white);
  }
}

.log {
  margin-top: 24px;
}

.disabled {
  cursor: not-allowed;
  background-color: #0f0f0f;

  & span {
    color: #494949;
  }

  & svg path {
    fill: #494949;
  }
}

.container {
  justify-content: center;
  flex-direction: column;
  width: 50%;
  border-radius: 16px;
  border: 1px solid var(--secondary-1);
  background-color: var(--white);
  box-shadow: 0px 82px 23px 0px rgba(0, 0, 0, 0),
    0px 53px 21px 0px rgba(0, 0, 0, 0), 0px 30px 18px 0px rgba(0, 0, 0, 0.01),
    0px 13px 13px 0px rgba(0, 0, 0, 0.02), 0px 3px 7px 0px rgba(0, 0, 0, 0.02);
}

.side {
  width: 90%;
  max-width: 400px;
  margin: auto;
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding: 6px;
  border-radius: 12px;
  background-color: var(--secondary-1);
  margin-bottom: 30px;
  & img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}

.infoTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: auto;
}

.userName,
.userEmail {
  line-height: 1;
}

.userName {
  color: var(--secondary-5);
}

.userEmail {
  color: var(--secondary-4);
}

.changeAccountBtn {
  font-size: 12px;
  min-height: 28px;
  padding: 0 14px;
}

.title {
  line-height: 1.25 !important;
  margin-bottom: 18px !important;
}

.subtitle {
  color: var(--secondary-4);
  line-height: 1.42;
}

.form {
  margin-top: 40px;
}

.inputItem {
  margin-bottom: 14px !important;
}

.passwordInput > div > div > div > span {
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  padding: 8px 14px;
  height: 48px;
  &:hover {
    border: 1px solid var(--primary-4) !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03),
      0px 2px 10px rgba(0, 0, 0, 0.03);
  }
}

.passwordInput input::placeholder {
  font-family: "Poppins", sans-serif;
  color: var(--secondary-4);
  font-weight: 500;
}

.inputItem,
.selectItem {
  & div:last-of-type div:last-of-type {
    color: var(--secondary-4);
  }
}

.inputItem:not(.passwordInput) input,
.select > div {
  width: 100% !important;
  border: 1px solid var(--secondary-2) !important;
  border-radius: 10px !important;
}

.submitBtn {
  width: 100%;
  margin-top: 40px;
}

.linkedinIcon {
  width: 14px;
  height: 14px;
  margin-bottom: -1px;

  & path {
    fill: var(--secondary-6);
  }
}

@media screen and (min-width: 1280px) {
  .changeAccountBtn {
    margin-left: auto;
  }
}

.balanceContainer {
  border: var(--header-border);
  border-radius: 10px;
  gap: 6px;
  height: 36px;
  justify-content: space-between;
  padding: 12px;

  & .balanceTitle {
    color: var(--secondary-4) !important;
  }

  & svg {
    width: 6px;
    height: 6px;
  }

  & > div {
    display: flex;
    gap: 6px;
  }
}

.balanceContainer.light {
  & .balanceText span {
    color: var(--secondary-6);
  }

  & svg circle {
    fill: var(--secondary-3);
  }
}

.balanceContainer.dark {
  & .balanceText span {
    color: var(--secondary-2) !important;
  }

  & svg circle {
    fill: var(--secondary-6);
  }
}

.cardContainer {
  border: var(--border-base);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12.5rem;
  & > div {
    padding: 20px;
  }
}

.cardContainer ul {
  background-color: var(--gray-10);
  & li {
    margin: 4px 0;
  }
}

.title {
  margin-bottom: 10px;
  line-height: 1;
  display: inline-block;
  color: var(--gray-9);
  text-transform: capitalize;
}

.date {
  color: var(--pink-1);
  display: block;
}

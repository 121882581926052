.radio + .radio {
  margin-top: 10px;
}

.title {
  display: flex;
  align-items: center !important;
  gap: 6px;
}

.text {
  color: var(--secondary-4);
  display: inline-block;
  line-height: 1.5;
  margin-top: 6px;
}

.spendingInput {
  width: 350px;
  margin: 20px 0 0;

  & div > span {
    width: 350px !important;
  }
}

.hidePrefix span[class="ant-input-prefix"] {
  display: none;
}

.hidePrefix,
.showPrefix {
  & span:not(span[class="ant-input-prefix"]) {
    width: 23rem;
  }
}

.error {
  background-color: var(--red-1);
  border-color: var(--red-5) !important;
  position: relative;
}

.row {
  background-color: var(--secondary-1);
  padding: var(--size-xs) var(--size-lg) var(--size-lg) var(--size-lg);
  border-radius: 0px 0px 8px 8px;
  margin-top: -1.5rem;
  margin-bottom: 15px;
}

.title {
  color: var(--secondary-6) !important;
  margin-bottom: 5px !important;
  letter-spacing: 0.44px;
}

.text {
  color: var(--gray-4);
  letter-spacing: 0.25px;
}

.editPopup {
  position: fixed;
  z-index: 1000;
  transform: translate(-40%, -40%);
  border: 1px solid var(--secondary-2);
  background-color: var(--primary-2);
  border-radius: var(--size-radius);
  transition: 0.3s ease;
  padding: 0 8px;
  &:focus-within,
  &:hover {
    box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
      0px 1px 2px rgba(52, 42, 114, 0.08);
  }
}
.editPopup.error {
  border: 1px solid var(--red-5);
  background-color: var(--white);
}
.iconContainer {
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
}

.editContainer {
  background-color: var(--primary-2);
  width: 20px;
  height: 20px;
  margin-left: -0.25rem;
}

.okContainer {
  background-color: var(--primary-4);
  right: 2rem;
}

.okContainer.error {
  pointer-events: none;
  background-color: var(--secondary-2);
  & svg path {
    fill: var(--secondary-4);
  }
}

.closeContainer {
  background-color: var(--white);
  right: 0.5rem;
}

.closeContainer,
.okContainer {
  position: absolute;
  top: 6px;
  width: 1rem;
  height: 1rem;
  svg {
    width: 10px;
    height: 10px;
  }
}

.input {
  width: 6rem;
  height: 1.875rem;
  padding-right: 0;
  background-color: var(--primary-2);
  border-radius: var(--size-radius);
  border: none;
  &:focus,
  &:hover {
    box-shadow: none;
  }
}

.input.error {
  padding: 0;
  background-color: var(--white);
}

.active {
  pointer-events: all;
}

.cols {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  right: -42px;
  padding-right: 12px;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;

  & div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.logsText {
  color: var(--primary-4);
}

.uploadBanner {
  max-height: 88px !important;
  border: 1px solid var(--secondary-2) !important;
  border-radius: 12px !important;
  box-shadow: 0px 21px 6px 0px rgba(0, 0, 0, 0),
    0px 13px 5px 0px rgba(0, 0, 0, 0.01), 0px 8px 5px 0px rgba(0, 0, 0, 0.03),
    0px 3px 3px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease !important;

  & span {
    padding: 0 !important;
  }

  & > span > div {
    padding: 16px !important;
    max-width: 100%;
    align-items: flex-start !important;
    min-height: unset !important;
    height: 88px !important;
  }

  & article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  & svg path {
    transition: fill 0.3s ease;
  }

  &:hover {
    background-color: var(--primary-1) !important;
    border-color: var(--primary-3) !important;
    box-shadow: 0px 21px 6px 0px rgba(0, 0, 0, 0),
      0px 13px 5px 0px rgba(0, 0, 0, 0.01), 0px 8px 5px 0px rgba(0, 0, 0, 0.03),
      0px 3px 3px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    & svg path:first-of-type {
      fill: var(--primary-2);
    }

    & svg path:nth-of-type(2) {
      fill: var(--primary-3);
    }

    & svg path:last-of-type {
      fill: var(--primary-4);
    }
  }
}

.innerUpload {
  gap: 18px;
}

.uploadInnerTitle {
  color: var(--primary-6);
  line-height: 1.28;
  text-align: start;
}

.uploadInnerText {
  color: var(--secondary-4);
  line-height: 1;
}

.dragging {
  & > div > span > div {
    background-color: var(--primary-1) !important;
    border-color: var(--primary-4) !important;
  }

  & svg path:first-of-type {
    fill: var(--primary-2);
  }

  & svg path:nth-of-type(2) {
    fill: var(--primary-3);
  }

  & svg path:last-of-type {
    fill: var(--primary-4);
  }
}

.loading {
  position: relative;
  z-index: 1;
}

@property --angle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

.loading::before,
.loading::after {
  content: "";
  position: absolute;
  inset: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-color: white;
  background: conic-gradient(
    from var(--angle),
    transparent 80%,
    var(--primary-4)
  );
  border-radius: 14px;
  z-index: -1;
  animation: 3s spin linear infinite;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

.percent {
  font-size: 11px;
  color: var(--primary-6);
}

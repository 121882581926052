.emptyContainer {
  gap: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--secondary-1);
  padding: 10px;
  height: 100%;
  border-radius: 20px;
  & .emptyTitle {
    margin-bottom: 0 !important;
    color: var(--secondary-6) !important;
    line-height: 16px;
    margin-top: 1.875rem;
  }
  & .emptyText {
    color: var(--secondary-4);
    max-width: 310px;
    display: inline-block;
    margin-top: 1.25rem;
  }
}

.emptyCol {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4.25rem 0;
  border-radius: 1rem;
  box-shadow: 0px 7px 4px rgba(32, 43, 54, 0.01),
    0px 3px 3px rgba(32, 43, 54, 0.02), 0px 1px 2px rgba(32, 43, 54, 0.02),
    0px 0px 0px rgba(32, 43, 54, 0.02);
  height: 100%;
}
.boxContainer {
  width: 86px;
  height: 86px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.03),
    0px 7px 4px rgba(32, 43, 54, 0.01), 0px 3px 3px rgba(32, 43, 54, 0.02),
    0px 1px 2px rgba(32, 43, 54, 0.02), 0px 0px 0px rgba(32, 43, 54, 0.02);
}

.emptyForTable {
  margin-bottom: 1rem;
  width: calc(100% + 28px) !important;
  margin-left: -14px;
}

.error {
  border: 1px solid var(--red-4);
  background-color: var(--red-2);

  & .emptyTitle {
    color: var(--red-5) !important;
  }

  & svg path {
    fill: var(--red-5);
  }
}

.uploadedContainer {
  background-color: white;
  padding: 22px 16px;
  border-radius: 12px;
  border: 1px solid var(--secondary-2);
  box-shadow: 0px 31px 9px 0px rgba(0, 0, 0, 0),
    0px 20px 8px 0px rgba(0, 0, 0, 0), 0px 11px 7px 0px rgba(0, 0, 0, 0.01),
    0px 5px 5px 0px rgba(0, 0, 0, 0.02), 0px 1px 3px 0px rgba(0, 0, 0, 0.02);
  gap: 18px;
  justify-content: space-between;

  & article {
    max-width: 240px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-right: auto;
  }
}

.imgName {
  color: var(--primary-6);
  line-height: 1.28;
}

.imgSize {
  color: var(--secondary-4);
  line-height: 1;
}

.deleteContainer {
  width: 28px;
  height: 28px;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;

  & svg {
    width: 14px;
    height: 14px;
    color: var(--red-5);
  }
}

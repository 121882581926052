.container {
  position: fixed;
  width: 100%;
  padding: var(--size-xxxs) var(--size-lg);
  background-color: var(--admin-bar-bg);
  justify-content: space-between;
  z-index: 1000;
  & span,
  svg,
  a {
    color: var(--white);
    line-height: 1;
  }
}

.advertiser {
  padding: 6px 10px;
  background-color: var(--white);
  border-radius: 8px;
  & svg,
  span {
    color: var(--primary-6);
  }
}

.btn {
  background-color: var(--admin-bar-btn);
  border-radius: var(--size-radius);
  min-height: 2.625rem;
  min-width: 14.125rem;
  justify-content: center;
}

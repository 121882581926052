.container {
  padding: 0 26px;
  margin-top: -40px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26.5px 27.5px;
  background-color: var(--white);
  border: var(--border-base);
  box-shadow: 0px 18px 56px rgba(0, 0, 0, 0.04);
  border-radius: 13px;
  z-index: 10;
  position: relative;
  margin-bottom: 20px;
}
.text {
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-6);
  font-weight: 500;
}

.container,
.wrapper {
  padding-left: 25px;
  & h3,
  h4,
  h6 {
    font-family: "Satoshi", sans-serif !important;
    font-weight: 700;
    line-height: 1.4;
  }
}

.wrapper {
  border-top: 1px solid #f5e6de;
  background: linear-gradient(180deg, #fff1ea 0%, #fff 62.83%);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.5) inset;
  padding-top: 2.5rem;
  overflow: hidden;

  & > div > article {
    max-width: 550px;
  }
}

.wrapper h3,
.container h4 {
  color: #1a1a1a;
  font-size: 1.75rem;
  padding-right: 25px;

  & span {
    text-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
    -webkit-text-fill-color: var(--white);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--white);
    background: -webkit-radial-gradient(#ff6006, #0085ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.wrapper > div > article > p {
  color: #838383;
  font-size: var(--size-xxs);
  font-family: "Inter", sans-serif !important;
  line-height: 1.82;
  margin-bottom: 1rem;
  width: 320px;
}

.iconCardWrapper {
  display: flex;
  margin-bottom: 1rem;
  gap: 1.25rem;
  overflow: hidden;
  padding-right: 65px;
  width: 375px;
}

.iconCard {
  border-radius: 0.875rem;
  border: 0.55px solid #e1e1e1;
  background: linear-gradient(180deg, #f8f8f8 0%, #fff 100%);
  box-shadow: 0px 9.5625px 16.875px 0px rgba(0, 0, 0, 0.05),
    0px 1.125px 0px 0px #fff inset;
  padding: 1rem;
  display: flex;
  gap: 1.25rem;
  width: 200px;
  align-items: flex-start;

  & .icon {
    border-radius: 0.45rem;
    border: 0.5px solid #e1e1e1;
    background: var(--white);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  & p {
    margin-bottom: 0;
    font-size: var(--size-xxs);
    font-family: "Satoshi", sans-serif !important;
    font-weight: 700;
    line-height: 1.35;
    width: 118px;
  }
}

.iconCard:nth-of-type(1) {
  color: #654fe4;
}

.iconCard:nth-of-type(2) {
  color: #3ab078;
}

.iconCard:nth-of-type(3) {
  color: #ff6006;
}

.iconCard:nth-of-type(4) {
  color: #0784f9;
}

.container {
  & h3,
  h6 {
    font-size: var(--size-xs);
  }

  & h3 {
    color: #3ab078;
    margin-bottom: 0.625rem;
  }

  & h4 {
    margin-bottom: 2.25rem;
  }
}

.container > section {
  display: flex;
  gap: 1.25rem;
  width: 100%;
  overflow: hidden;
}

.card,
.card img {
  width: 100%;
}

.card {
  transition: transform 0.5s ease-in-out;
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.375rem;
  align-self: stretch;
  border-radius: 0.875rem;
  border: 0.55px solid #e4e4e4;
  background: var(--white);
  width: 250px;
}

.card h6 {
  color: #656565;
  margin-bottom: 0;
}

.card p {
  color: #838383;
  font-size: 0.65rem;
  font-family: "Inter", sans-serif !important;
  line-height: 1.82;
  width: 202px;
  margin-bottom: 0;
}

.card-0.inactive {
  transform: translateX(270px);
}
.card-1.active {
  transform: translateX(-270px);
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  gap: 0.15rem;

  & svg circle {
    fill: #e7e7e7;
  }
}

.dot {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.dot.active circle {
  fill: #a8a8a8;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 3.375rem 0 0 0;
    background: radial-gradient(
        75.63% 75.63% at 50% -0%,
        #fff1ea 0%,
        #fff 62.83%
      ),
      radial-gradient(75.63% 75.63% at 50% -0%, #fff1ea 0%, #fff 62.83%);
  }

  .wrapper h3 {
    font-size: 3rem;
    padding-right: 0;
    width: 476px;
    margin: 0 auto 3.45rem;
    text-align: center;

    & span {
      background: -webkit-radial-gradient(#0085ff, #8f00ff);
      background-clip: text;
    }
  }

  .wrapper > div {
    display: flex;
    flex-direction: column;
    max-width: 1228px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .wrapper > div > article > p {
    width: 538px;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 5.625rem;
  }

  .iconCardWrapper {
    flex-wrap: wrap;
    padding-right: 0;
    width: max-content;
    align-items: flex-start;
    justify-content: center;
    max-width: 770px;
    margin-bottom: 1.5rem;
  }

  .iconCard {
    padding: 1.875rem;
    flex-direction: column;
    width: 16.875rem;

    & p {
      font-size: 1.5rem;
      width: 222px;
    }
  }

  .iconCard .icon {
    width: 4rem;
    height: 4rem;
    border-radius: 0.875rem;

    & svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .iconContainer {
    display: none;
  }

  .iconCard:nth-of-type(odd) {
    margin-right: 1.25rem;
  }

  .iconCard:nth-of-type(even) {
    margin-top: 1.25rem;
  }

  .iconCard:nth-of-type(3) {
    margin-left: -1.25rem;
  }

  .container {
    padding: 7.5rem 0;
  }

  .container h3,
  .container h4 {
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    padding: 0;
  }

  .container h3 {
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
  }

  .container h4 {
    font-size: 3rem;
    background: radial-gradient(
      100% 100% at 51.13% -0%,
      #717171 0%,
      #1a1a1a 100%
    );
    background: -webkit-radial-gradient(#717171, #1a1a1a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }

  .container section {
    flex-direction: column;
    gap: 2.5rem;
    max-width: max-content;
    margin: 3.125rem auto 0;
  }

  .card {
    width: 668px;
    padding: 3.75rem;
    gap: 1.625rem;

    & img {
      margin-bottom: 0.875rem;
    }

    & h6 {
      font-size: 1.625rem;
    }

    & p {
      font-size: 1.125rem;
      width: 100%;
    }
  }
}

@media screen and (min-width: 1280px) {
  .wrapper {
    padding: 6.25rem 0;

    & > div {
      flex-direction: row;
      gap: 5.5rem;
    }

    & h3 {
      margin: 0 0 2.375rem;
    }

    & h3,
    > div > article > p {
      text-align: start;
    }

    & > div > article > p {
      font-size: 1.25rem;
      width: 565px;
      margin-bottom: 0;
    }
  }

  .iconCardWrapper {
    justify-content: flex-end;
  }

  .container {
    padding: 10rem 0;
  }

  .container section {
    margin: 6.25rem auto 0;
    max-width: 1086px;
    flex-direction: row;
  }

  .card {
    width: 524px;
  }
}

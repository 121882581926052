.wrapper {
  width: 50%;
  padding-top: 3%;
  overflow-x: hidden;
  max-height: calc(100vh - 28px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.topInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.helpBtn {
  justify-content: center;
  padding: 0 24px;
  height: 52px;
  color: var(--green-10);
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--white);
  border-radius: 14px;
  border: 2px solid var(--green-9);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  cursor: pointer;

  &:hover {
    color: var(--green-10) !important;
  }
}

.helpBtn svg path {
  fill: var(--green-9);
}

.text {
  display: inline-block;
  margin: auto;
  color: var(--secondary-5);
  line-height: 2;
}

.topInfo,
.text {
  width: 90%;
  max-width: 618px;
  flex-shrink: 0;
  padding: 0;
}

.fourthStepText {
margin-bottom: -40px;
}

.img {
  position: relative;
  object-fit: contain;
  display: block;
  margin: 0 0 0 auto;
  width: 95%;
  max-width: 685px;
}

.firstStepImg,
.secondStepImg {
  max-width: 685px;
}

.thirdStepImg {
  max-width: 660px;
}

.wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & .textContainer,
  .container {
    width: 90%;
    max-width: 435px;
  }

  & .container {
    border: 1px solid var(--secondary-2);
    padding: 40px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }

  & h1 {
    color: var(--primary-6);
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 1;
    margin-bottom: 0;
  }
}

.textContainer {
  gap: 30px;
  margin-bottom: 40px;
}

.iconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--secondary-1);
  justify-content: center;

  & svg {
    width: 2rem;
  }
}

.text {
  color: var(--secondary-5);
  display: block;
  line-height: 1.7;
}

.loginBtn {
  width: 100%;
  margin-top: 1.5rem;
}

.divider {
  width: calc(100% + 5rem);
  color: var(--secondary-4);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 100%;
  margin: 2.5rem 0 2.5rem -2.5rem;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    width: 40%;
    height: 1px;
    background-color: var(--secondary-2);
    vertical-align: middle;
  }

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }
}

.signupBtn {
  color: var(--primary-4);

  &:hover {
    border: 1.5px solid var(--primary-6);
    color: var(--primary-6) !important;
  }
}

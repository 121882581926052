.modalBox,
.drawerBox {
  margin-top: 0.75rem;
  display: flex;

  & article {
    display: flex;
    flex-direction: column;
  }
}

.modalBox {
  padding: 0.75rem;
  border-radius: 0.625rem;
  border: 1px solid var(--secondary-2);
  width: calc(100% + 18px);
  justify-content: space-between;

  & .restrictionTitle {
    font-size: 0.75rem;
  }

  & .restrictionText {
    font-size: 0.6875rem;
  }

  & article {
    gap: 0.25rem;
  }
}

.drawerBox {
  justify-content: space-between;

  & .restrictionTitle {
    font-size: 0.875rem;
  }

  & .restrictionText {
    font-size: 0.75rem;
  }

  & article {
    max-width: 338px;
    gap: 0.5rem;
  }
}

.restrictionTitle {
  color: var(--secondary-5);
  line-height: 1;
}

.restrictionText {
  color: var(--secondary-4);
  line-height: 1.5;
  max-width: 246px;
}

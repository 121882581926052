.fieldItemContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: var(--white);

  & p {
    display: flex;
    gap: 6px;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -0.1px;
    margin: 0;
  }
}

.drawer {
  padding: 10px 0;
}

.form {
  padding: 10px;
}

.fieldItemContainer + .fieldItemContainer {
  border-top: 1px solid var(--secondary-2);
}

.field {
  color: var(--secondary-6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.type {
  color: var(--secondary-5);
  font-size: 11px;
}

.required {
  color: var(--red-5);
  font-size: 11px;
}

.description {
  color: var(--secondary-4);
  font-size: 10px;
  line-height: 1.5;
}

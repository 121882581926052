.tag {
  border: none;
  margin-right: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  & span:last-child {
    height: 10px;
    display: inline-flex;
    align-items: center;
  }
}

.filter {
  color: var(--blue-6);
  background-color: var(--blue-1);
  border-radius: var(--size-radius);
  padding: 6px;
  & span:first-of-type {
    line-height: 12px;
  }
}

.settings {
  color: var(--blue-10);
  background-color: var(--blue-1);
  border-radius: 10px;
  padding: 8px 12px;
  line-height: 18px;
}

.tag,
.tag span:first-of-type {
  font-weight: 500;
}

.filter,
.filter span:first-of-type,
.filter_report,
.filter_report span:first-of-type {
  font-family: "Poppins", sans-serif !important;
}

.filter_report span:first-of-type {
  font-weight: 400;
  line-height: 1;
}

.filter_report {
  border-radius: var(--size-s);
  background-color: var(--secondary-1);
  font-size: var(--size-xxs);
  color: var(--primary-6);
  gap: 0.5rem !important;
  justify-content: space-between;
  align-items: baseline;
  padding: 8px;
  & span {
    margin-left: 0;
  }
  & > span > svg {
    width: 10px;
    & path {
      fill: var(--primary-6);
    }
  }
}

.advertiser {
  border-radius: var(--size-radius);
  background: var(--secondary-1);
  padding: 6px;
  margin: 0 6px 6px 0;
  width: max-content;
  max-width: 100%;

  & span {
    font-family: "Poppins", sans-serif !important;
    height: fit-content !important;
    white-space: initial;
  }
}

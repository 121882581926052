.openBtn {
  width: 24px;
  height: 24px;
  border: var(--border-base);
  border-radius: var(--size-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;

  & svg {
    width: 12px;
    & path {
      fill: black;
    }
  }
}

.img {
  max-height: 60px;
  max-width: 200px;
}

.keyTable {
  margin-top: 1em;
  & div[class="ant-table"],
  table {
    border-radius: 8px;
    background-color: transparent !important;
  }
  & tr {
    background: none !important;
    & td {
      border-top: var(--border-base);
      border-bottom: var(--border-base);
      text-align: justify;
      background-color: var(--white);
    }
    & td:first-of-type {
      border-left: var(--border-base);
    }
    & td:last-of-type {
      border-right: var(--border-base);
    }
    & td:only-child {
      border-radius: 8px;
    }
  }
  & tr:nth-child(even) {
    & td {
      border-top: none;
    }
    & td:first-of-type {
      border-radius: 0 0 0 8px;
    }
    & td:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }
  & tr:nth-child(even):not(:last-of-type) {
    & td {
      border-bottom: none;
    }
    & td:first-of-type,
    td:last-of-type {
      border-radius: 0;
    }
  }
}

.more {
  & tr:nth-child(odd):not(:only-child) {
    & td:first-of-type,
    td:last-of-type {
      border-radius: 0;
    }
  }
  & tr:first-of-type:not(:only-child) {
    & td:first-of-type {
      border-radius: 8px 0 0 0;
    }
    & td:last-of-type {
      border-radius: 0 8px 0 0;
    }
  }
  & tr:nth-of-type(odd):last-of-type:not(:only-child) {
    & td:first-of-type {
      border-radius: 0 0 0 8px;
    }
    & td:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }
}

.borderContainer {
  position: relative;
}

.suggestedContainer {
  padding: 2.25rem;
  margin-top: 2.5rem;
  background: var(--white);
  border: var(--border-base);
  border-radius: var(--size-radius);
  z-index: 2;
  position: relative;
}

.firstBorder,
.secondBorder {
  transform: translateX(-50%);
  left: 50%;
  height: 30px;
  background: var(--white);
  border: var(--border-base);
  border-radius: 7px;
  position: absolute;
}

.firstBorder {
  width: 95%;
  z-index: 1;
  top: 35px;
}

.secondBorder {
  width: 90%;
  z-index: 0;
  top: 30px;
}

.fullWidth {
  width: 100%;
}

.container {
  position: relative;
}

.btn {
  padding: 4px 12px;
  color: var(--primary-4);
  border: 1px solid var(--primary-4);
  float: right;
  &:hover {
    border-color: var(--primary-6);
    color: var(--primary-6);
  }
}

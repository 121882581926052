.start > div {
  width: 50%;
}

.justify {
  justify-content: space-between;
}

.title {
  margin-bottom: 0.5rem !important;
}

.text {
  color: var(--secondary-5);
}

.title,
.download {
  color: var(--primary-4) !important;
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 12px;

  & > div:first-of-type {
    max-width: 400px;
  }

  & .link {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-4);
    text-decoration: underline;
    margin-top: 0.5rem;
    display: block;
  }
}

.container + .container {
  margin-top: 1rem;
}

.container:last-of-type {
  margin-bottom: 36px;
}

.flex {
  display: flex;
  gap: 4rem;
  align-items: flex-start;
}

.api {
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: var(--border-base);
  &:hover,
  &:focus {
    box-shadow: 0px 4px 8px rgba(52, 42, 114, 0.08),
      0px 1px 2px rgba(52, 42, 114, 0.08);
    border: 1px solid var(--primary-4) !important;
  }
}

.icon {
  cursor: pointer;
}

.apiText {
  color: var(--secondary-7);
}

.baseline {
  align-items: baseline;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.top {
  position: absolute;
  width: 65%;
  height: 6px;
  background-color: var(--primary-4);
  top: 0;
  right: 0;
}
